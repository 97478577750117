.events-list-panel {
  padding-left: 38px;
}

.close-button {
  display: inline-flex;
  justify-content: space-between;
  height: 44px;
  width: 92px;
  padding-left: 14px;
  padding-right: 14px;
}

.devices-row {
  --bs-gutter-x: 0.5rem;
}

.wrapper-app
  .stream-timeline-wrapper.timeline-controls-page.multiview-timeline {
  margin-top: 0;
}

// .multiviewcontrols {
//   .stream-timeline-wrapper {
//     aspect-ratio: auto;
//     .remote-view-wrapper {
//       aspect-ratio: 16 / 9;
//     }
//   }
// }

.grid-container .muuri,
.multi-live-camera-wrapper-image {
  // display: flex;
  // justify-content: center;
  // flex-basis: 50%;
  // flex-wrap: wrap;
  canvas {
    position: absolute;
  }
}

.grid-item {
  border: 1px solid rgba(var(--greyscale-custom1), 0.8);
  font-size: 30px;
  text-align: center;
  aspect-ratio: 16/9;
  width: 100%;
  position: relative;
  background: var(--greyscale_08);
  border-radius: 12px;
  overflow: hidden;
  flex-basis: 50%;
  max-width: 50%;
  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    //width: auto;
  }
}
