@import '../../assets/css/base.scss';

.view-details-link {
  color: var(--primary_40);
  font-size: toRem(14px);
  font-weight: 600;

  &.disabled {
    pointer-events: none;
  }
}

.scroll-container {
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none !important;
  }

  .card-layout {
    margin: 2px;
    width: 100%;
    border: none;
    height: 220px;
    background: var(--brand_white);
    border-radius: 12px;

    .first-card-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 100%;

      .que-header {
        margin: 20px 27px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .que-count {
          font-family: 'Noto Sans';
          font-style: normal;
          font-weight: 600;
          font-size: toRem(18px);
          line-height: 24px;
          display: flex;
          align-items: center;
          color: var(--brand_black);

          @media (min-width: 767px) {
            font-size: toRem(12px);
          }

          @media (min-width: 1000px) {
            font-size: toRem(18px);
          }
        }

        .compare-btn {
          margin-left: 20px;
          font-style: normal;
          font-weight: 600;
          font-size: toRem(12px);
          line-height: 16px;
          color: var(--primary_40);
          border: 1px solid var(--primary_40);
          padding: 3px 12px 3px 12px;
          align-items: center;
          text-align: center;
          border-radius: 10px;

          @media (min-width: 767px) {
            margin-left: 5px;
            font-size: toRem(10px);
          }

          @media (min-width: 1000px) {
            margin-left: 20px;
            font-size: toRem(12px);
          }
        }
      }

      .count-graph-container {
        margin: auto 27px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .left-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .que-count {
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: 700;
            font-size: toRem(60px);
            line-height: 35px;
            display: flex;
            align-items: center;
            color: var(--brand_black);
            text-align: left;

            @media (min-width: 767px) {
              font-size: toRem(20px);
            }

            @media (min-width: 1000px) {
              font-size: toRem(40px);
            }
          }

          .capacity {
            margin-left: 5px;
            margin-top: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: toRem(12px);
            line-height: 16px;
            display: flex;
            text-align: left;
          }
        }

        .right-container {
          margin-left: 20px;
          margin-top: -40px;
        }
      }
    }

    .second-card-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      padding: 0px 27px;

      .nearing-capacity-label {
        font-style: normal;
        font-weight: 600;
        font-size: toRem(18px);
        line-height: 24px;
        display: flex;
        align-items: center;
        color: var(--brand_black);
        margin-top: 20px;
        text-align: start;

        @media (min-width: 767px) {
          font-size: toRem(12px);
        }

        @media (min-width: 1000px) {
          font-size: toRem(18px);
        }
      }

      .container-que-labels {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding-left: 12px;
        padding-right: 10px;

        .label-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 80%;

          .que-left-label {
            font-style: normal;
            font-weight: 600;
            font-size: toRem(12px);
            line-height: 16px;
            display: flex;
            align-items: center;
            color: var(--greyscale_08);
          }

          .location-text {
            width: 200px;
            font-style: normal;
            font-weight: 400;
            font-size: toRem(12px);
            line-height: 16px;
            display: flex;
            align-items: center;
            color: var(--greyscale_40);
            text-align: left;
            @media (min-width: 767px) {
              font-size: toRem(10px);
            }
            @media (min-width: 1000px) {
              font-size: toRem(12px);
            }
          }
        }

        .percentage-container {
          width: 20%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          padding: 6px 10px;
          gap: 6px;
          background: var(--diff_positive);
          border-radius: 8px;
          color: var(--diff_text_positive);

          &.gain {
            background: var(--error_96);
            color: var(--error_24);
          }
        }
      }
    }
  }
}

.no-queue-data {
  color: var(--greyscale_56);
  font-size: toRem(14px);
  font-weight: 500;
  text-align: left;
  margin-top: 20px;
}

.no-data-available {
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: toRem(16px);
  line-height: 20px;
  text-align: center;
  color: var(--greyscale_56);
}
