@import '../../assets/css/base';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes outline-animation {
  0% {
    outline-width: 1px;
  }
  50% {
    outline-width: 4px;
  }
  100% {
    outline-width: 1px;
  }
}

.video-detail-wrapper {
  + .justify-content-start.row {
    .item {
      margin: 5px 12px 32px 0;
    }
  }
}

.item {
  cursor: move;
  display: flex;
  flex-direction: column;
  margin: 0px 24px 24px 0;
  z-index: 1;
  &.detail-item {
    --bs-gutter-x: 0.5rem;
    position: relative;
  }
  /*
   To prevent user selecting inside the drag source
  */
  //   user-select: none;
  //   -moz-user-select: none;
  //   -webkit-user-select: none;
  //   -ms-user-select: none;

  &-1x {
    height: 113px;
    width: 200px;
  }

  &-2x {
    height: 250px;
    width: 424px;
  }

  &-3x {
    height: 388px;
    width: 648px;
  }

  &-content {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &-name {
    width: 100%;
    color: var(--brand_white);
    font-size: toRem(14px);
    text-align: left;
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }

  &-location {
    text-align: left;
    overflow: hidden;
    color: var(--brand_white);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: toRem(10px);
    font-weight: 500;
    line-height: toRem(10px);
    position: absolute;
    z-index: 100;
    bottom: 10px;
    left: 10px;
    width: 180px;

    &-1x {
      width: 180px;
    }

    &-2x {
      width: 400px;
    }

    &-3x {
      width: 620px;
    }
  }

  &-snapshot {
    border-radius: 4px;
    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #000;

    &-1x {
    }

    &-2x {
      height: 250px;
      width: 424px;
    }

    &-3x {
      height: 388px;
      width: 648px;
    }

    &-image {
      border-radius: 4px;
      aspect-ratio: 16 / 9;
      object-fit: scale-down;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;

      &-1x {
      }

      &-2x {
        height: 250px;
        width: 424px;
      }

      &-3x {
        height: 388px;
        width: 648px;
      }

      &-highlighted {
        outline: 2px solid var(--warning_64);
        animation: outline-animation 1.5s ease;
      }
    }

    &-wrapper {
      width: 100%;
      position: absolute;
      z-index: 5;
      top: 0;

      &-no-zoom {
        height: 96px;
        width: 165px;
      }
    }
  }

  &.cameras-item {
    --bs-gutter-y: 1rem;

    &-dragging {
      z-index: 3;
    }

    &-releasing {
      z-index: 2;
    }

    &-hidden {
      z-index: 0;
    }
  }

  &-offline {
    background-color: var(--grayscale_32);
    border-radius: 4px;
    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
    height: 100%;
    width: 100%;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      background-color: var(--grayscale_32);
      box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
      border-radius: 4px;
      position: absolute;
      z-index: 200;

      .camera-offline {
        &-icon {
          margin-top: 15%;
          svg {
            cursor: pointer;
          }
        }

        &-label {
          font-size: toRem(12px);
          color: var(--greyscale_80);
          margin-top: 4px;
        }
      }
    }
  }

  &-overlay {
    background: rgba(0, 16, 41, 0.6);
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;

    &-1x {
    }

    &-2x {
      height: 250px;
      width: 424px;
    }

    &-3x {
      height: 388px;
      width: 648px;
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
      animation: fadeIn 0.15s ease;

      + .item-location {
        display: none;
      }
    }

    &-content {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .zoom-options {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 15;

        &.hidden {
          display: none;
        }
      }

      .play-location-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        min-height: 74px;

        .play-button {
          color: var(--brand_white);
          height: 22px;
          width: 22px;
          display: block;
          object-position: center;

          svg {
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
          }
        }

        .device-location-wrapper {
          .item-overlay {
            &-name {
              color: var(--brand_white);
              text-align: center;
              font-size: toRem(12px);
              font-style: normal;
              font-weight: 600;
              line-height: toRem(16px);
              max-width: 140px;
            }

            &-location {
              color: var(--greyscale_80);
              text-align: center;
              font-size: toRem(10px);
              font-style: normal;
              font-weight: 500;
              line-height: toRem(16px);
            }
          }
        }
      }
    }
  }
}
