@import '../../assets/css/utilities';

.customer-list {
  &-toast {
    margin-top: 82px !important;
    margin-right: 25px !important;
    width: 540px !important;

    .toast {
      &-header {
        .btn-close {
          margin-right: 0.25rem;
          height: 0.405rem;
          width: 0.405rem;
        }
      }

      &-body {
        background-color: var(--brand_white);
      }
    }

    &-wrapper {
      position: absolute;
      width: 540px !important;
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: end;
      margin-top: -15px !important;
      right: 20px;
    }
  }
}

.alert.inactive-user-alert {
  padding: 12px;
  border-radius: 12px;
  background: var(--warning_96);

  .info-icon {
    margin-right: 10px;
  }

  .alert-heading {
    font-weight: 600;
    font-size: toRem(16px);
    line-height: 20px;
    margin-bottom: 4px;
  }

  p {
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 20px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.detail-page-location-block {
  .list-block-item.card {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (max-width: 767px) {
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    & > .card-body {
      margin-top: 0;
    }

    .location-map {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;

      @media screen and (max-width: 767px) {
        border-radius: 12px;
        margin-bottom: 24px;
      }

      a[href^="https://maps.google.com/maps"]
      {
        display: none !important;
      }

      .gmnoprint a,
      .gmnoprint span,
      .gm-style-cc {
        display: none;
      }
    }
  }
}

.org-detail-partner-list {
  margin-bottom: 8px;
  font-weight: 500;

  .org-detail-partner-title {
    font-size: toRem(12px);
    line-height: 16px;
    color: var(--greyscale_40);
  }

  .org-detail-partner-content {
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--brand_black);
  }
}

.org-detail-partner-tech-title {
  font-weight: 600;
  font-size: toRem(16px);
  line-height: 20px;
  color: var(--greyscale_08);
  margin-bottom: 16px;
}

.device-offline-count-icon {
  margin-left: 10px;
}

.detail-page-devices-block {
  .list-block-item.card > .card-body {
    margin-top: 0;
  }

  .trouble-block {
    .row {
      height: 100%;

      .trouble {
        align-self: center;
      }
    }
  }

  .unclaimed-lock {
    .list-block-item.card {
      margin-top: 20px;
    }
  }
}

.org-detail-page {
  .list-block-item {
    cursor: pointer;
  }
}

.detail-page-licenses-block {
  .licences-title {
    margin-bottom: 0;
  }
}

.noUser-block {
  .list-block-item.card {
    padding: 0;
  }
}

.licenses {
  &-count {
    font-size: 1rem;
    margin-right: 6.5px;
  }
}
.expired-licenses {
  .list-block-nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .licenses {
      &-count {
        font-size: 1rem;
        margin-right: 4px;
      }
      &-count,
      &-icon {
        color: var(--error_64);
      }
      &-icon {
        padding: 0;
        margin: 0;
        line-height: 18px;
      }
    }
  }
}

.org-short-name-text{
  
  .org-text-hint{
    color: var(--greyscale_40);
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    padding-right: 20px;
    display: flex;
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: 5px;
  }

  
  .m-t-15-m{
    margin-top: -15px;
  }
 
  .p-l-5{
    padding-left: 5px;
  }
  .max-width-text{
    width: 102%;
  }

  .display-f{
    display: flex;
  }
  .error-parent{
    margin-top: 0px;
    margin-left: 0px;
  }
  .m-t-15-m{
    margin-top: -15px;
  }
  .m-l-15{
    margin-left: 15px;
  }
  .m-l-30{
    margin-left: 30px;
  }
  .w-93{
    width: 93%;
  }
  .short-text-error{
    margin-top: -2px;
    margin-left: 3px;
  }
  .m-t-3{
    margin-top: 3px;
  }
  .p-l-5{
    padding-left: 5px;
  }
  .max-width-text{
    width: 102%;
  }

  .display-f{
    display: flex;
  }
  .error-parent{
    margin-top: 0px;
    margin-left: -15px;
  }
}
