@import '../../../assets/css/base.scss';

.contributors {
  &-title {
    color: #000;
    font-size: toRem(16px);
    font-weight: 600;
    line-height: toRem(20px);
  }

  &-content {
    &-wrapper {
    }

    &-item {
      display: flex;
      padding: 14px 10px 14px 20px;
      align-items: flex-start;
      gap: 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      background-color: var(--grayscale_96);

      &-details {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .avatar {
          position: relative;
          display: flex;
          width: 28px;
          height: 28px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 204px;
          border: 1px solid var(--grayscale_24);
          color: var(--White, #fff);
          font-size: toRem(12px);
          font-weight: 500;
          line-height: toRem(16px);

          &-wrapper {
            position: relative;
            width: 30px;
            margin-right: 12px;
          }

          &.avatar-1 {
            left: 8px;
            border-radius: 204px;
            background: var(--avatar_dark_brand);
          }

          &.avatar-2 {
            left: 26px;
            background: var(--avatar_dark_lime);
          }

          &.avatar-3 {
            left: 44px;
            background: var(--avatar_dark_purple);
          }

          &.avatar-4 {
            left: 62px;
            background: var(--avatar_dark_pink);
          }

          &.avatar-rest {
            left: 62px;
            background: var(--dark_general, #484c60);
          }

          &.avatar-no-offset {
            left: 0;
          }
        }
      }

      &-info {
        margin-left: 8px;
        color: var(--greyscale_08);
        font-size: toRem(16px);
        font-weight: 500;
        line-height: toRem(20px);
      }
    }
  }
}
