@import '../../assets/css/base.scss';

.tags {
  &-wrapper {
    text-align: left;

    &.no-padding {
      padding: 0;
    }

    &.full-width {
      width: 100%;
    }

    .tag {
      &-filter {
        &-input {
          display: flex;
          padding: 12px 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          border-radius: 12px;
          border: 1.5px solid var(--greyscale_88);
          background: var(--brand_white);
          width: 100%;
          height: 44px;

          &.error {
            border: 1.5px solid var(--error_48);
          }

          &:active,
          &:hover,
          &:focus,
          &:focus-visible,
          &:focus-within {
            border-color: var(--primary_40);
            outline: 0;
          }

          &.dark-theme {
            color: var(--brand_white);
            background-color: var(--grayscale_24);
            border: 0;
            &::placeholder {
              color: var(--brand_white);
            }

            &:active,
            &:hover {
              outline: 1px solid var(--brand_white);
            }

            &:focus,
            &:focus-visible,
            &:focus-within {
              outline: 1px solid var(--brand_white);
            }
          }

          &.disabled {
            pointer-events: none;
            background-color: rgba(0, 0, 0, 0.12);
          }
        }

        &-input-error {
          font-size: toRem(12px);
          line-height: toRem(20px);
          color: var(--error_48);
        }
      }
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 10px;
      gap: 6px;
      border-radius: 10px;
      background: var(--grayscale_96);
      color: var(--grayscale_32);
      font-size: toRem(12px);
      font-weight: 600;
      line-height: toRem(16px);
      margin-right: 8px;
    }

    &-label {
      white-space: nowrap;
    }

    &-add-icon,
    &-delete-icon {
      cursor: pointer;
    }
  }

  &-list-wrapper,
  &-selected-wrapper {
    display: inline-block;
    white-space: none;

    .no-padding {
      padding: 0;
    }

    .tag-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 10px;
      border-radius: 10px;
      background: var(--grayscale_96);
      color: var(--grayscale_88);
      font-size: toRem(12px);
      font-weight: 600;
      line-height: toRem(16px);
      margin-top: 8px;
      margin-right: 8px;
      height: 30px;
      float: left;

      &.dark-theme {
        color: var(--greyscale_88);
        background-color: var(--grayscale_24);
      }

      &.system-tag {
        border-radius: 10px;
        background-color: var(--primary_96);
        color: var(--primary_32);
        font-size: toRem(12px);
        font-weight: 600;
        line-height: toRem(16px);
      }
    }

    .tag-added-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 10px;
      border-radius: 10px;
      background: var(--primary_96);
      color: var(--primary_32);
      font-size: toRem(12px);
      font-weight: 600;
      line-height: toRem(16px);
      margin-right: 8px;
      margin-bottom: 2px;
      height: 28px;
      float: left;
    }

    .tag-label {
      white-space: nowrap;
      margin-right: 6px;
    }

    .tag-add-icon,
    .tag-delete-icon {
      cursor: pointer;
      float: left;
    }

    .tag-load-more,
    .tag-add-new {
      cursor: pointer;
      float: left;
      margin-top: 10px;

      &-disabled {
        cursor: auto;
        pointer-events: none;
      }

      &-icon {
        color: var(--brand_primary);
        margin-right: 4px;
      }

      &-label {
        color: var(--brand_primary);
        font-size: toRem(12px);
        font-weight: 600;
        line-height: toRem(16px);
        margin-right: 4px;
      }
    }
  }
}
