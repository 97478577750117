@import 'colors';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.duclo_logo img {
  max-height: 38px;
  pointer-events: none;
}

.navbar-light .navbar-brand.duclo_logo {
  color: var(--custom_color_1);
  font-size: 26px;
  text-transform: uppercase;
}

.navbar-light .navbar-brand.duclo_logo:hover {
  color: var(--custom_color_1);
  text-transform: uppercase;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: var(--custom_color_2);
}

.form .error {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--custom_color_3);
}

.error-select-timezone{
  margin-bottom:8px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-nav {
  flex-direction: row;
}
.navbar-nav .dropdown-menu {
  position: absolute;
}
.sign_up form {
  text-align: left;
}

#duclo-top-bar {
  height: 100px;
  background-color: var(--custom_color_4);
}

.sidebar {
  background-color: var(--custom_color_5);
}

.sidebar-content {
  height: 100%;
  /* min-height: 100vh; */
}

.sidebar-content .navbar-nav {
  align-items: start;
  justify-content: start;
}

.sidebar-content a {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  height: 54px;
  color: #000000;
}

.sidebar-content .navbar-light .navbar-nav .nav-link:hover,
.sidebar-content .navbar-light .navbar-nav .nav-link:focus {
  color: var(--custom_color_6);
  font-weight: 700;
}

.sidebar-content a svg {
  margin-right: 10px;
}

#duclo-top-bar .top-bar-menu {
  justify-content: end;
}

.top-bar-menu a {
  color: #000000;
}

.top-bar-menu a:hover,
.top-bar-menu a:focus {
  color: var(--custom_color_6);
}

p {
  font-size: 16px;
}

.sign_in_btn {
  border-radius: 2px;
  background-color: var(--custom_color_7) !important;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-style: none;
  height: 40px;
  min-width: 130px;
}

.gray-button,
.page-header .gray-button {
  background: var(--custom_color_8) !important;
  border: 1px solid #000000 !important;
  border-radius: 0;
  margin-right: 10px;
  color: #000 !important;
  width: 100%;
}

.gray-button:hover,
.gray-button:focus,
.gray-button:active,
.page-header .gray-button:hover,
.page-header .gray-button:focus,
.page-header .gray-button:active {
  background: var(--custom_color_9) !important;
  color: #000;
  border: 1px solid #000000 !important;
  box-shadow: unset;
}

.btn-link {
  color: var(--custom_color_10);
  text-decoration: none;
  box-shadow: unset;
}
.btn-link:hover,
.btn-link:focus {
  color: var(--custom_color_11);
  text-decoration: none;
  box-shadow: unset;
}

.duclo-table thead tr {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--custom_color_14);
  border-bottom: solid 1px var(--custom_color_5);
}

.duclo-table tbody tr {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: var(--custom_color_14);
  border-bottom: solid 1px var(--custom_color_5);
}

.duclo-table tr th,
.duclo-table tr td {
  padding: 10px;
}

.btn-action {
  color: var(--custom_color_10) !important;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.btn-action:hover,
.btn-action:focus,
.btn-action:active {
  box-shadow: unset;
}

#duclo-sidebar .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#duclo-sidebar .pro-sidebar,
#duclo-sidebar .pro-sidebar .pro-menu a {
  color: rgba(var(--greyscale-custom2), 0.55);
}

#duclo-sidebar .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover,
#duclo-sidebar .pro-sidebar .pro-menu a:hover,
#duclo-sidebar .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  color: var(--custom_color_6) !important;
}

#duclo-sidebar .pro-sidebar > .pro-sidebar-inner,
#duclo-sidebar
  .pro-sidebar
  .pro-menu
  > ul
  > .pro-sub-menu
  > .pro-inner-list-item {
  background-color: transparent !important;
}

#duclo-sidebar
  .pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: transparent !important;
}

.green-btn {
  background-color: var(--custom_color_12) !important;
  color: #fff !important;
  border-color: var(--custom_color_12) !important;
  border-radius: 10px !important;
  padding: 0.5em 2.004em !important;
  font-family: 'adobe-garamond-pro';
  font-size: 1rem;
}

nav a.green-btn {
  padding: 10px 20px !important;
}

.green-btn:hover,
.green-btn:active,
.green-btn:focus {
  opacity: 0.8;
  outline: none;
  box-shadow: unset !important;
}

.no-style-button {
  background-color: transparent !important;
  border-color: transparent !important;
  color: var(--custom_color_10) !important;
}

#duclo-sidebar .pro-sidebar .pro-menu .pe-none,
#duclo-sidebar .pro-sidebar .pro-menu .pro-menu-item.pe-none a {
  color: var(--custom_color_13);
}

#duclo-sidebar .sidebar-org-name {
  font-size: 24px;
  color: var(--custom_color_14);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
}

#duclo-sidebar
  .pro-sidebar
  .pro-menu
  .pro-menu-item.sidebar-org-name
  > .pro-inner-item:hover,
#duclo-sidebar
  .pro-sidebar
  .pro-menu
  .pro-menu-item.sidebar-org-name
  > .pro-inner-item:active,
#duclo-sidebar
  .pro-sidebar
  .pro-menu
  .pro-menu-item.sidebar-org-name
  > .pro-inner-item:foucs {
  font-size: 24px;
  color: var(--custom_color_14) !important;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
}

.invalid-feedback {
  display: block;
}

.invitationCodeOtp input.form-control.is-invalid {
  background: none;
}

.footer {
  background-color: var(--custom_color_8);
}

.footer a {
  text-decoration: underline;
  font-size: 12px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}