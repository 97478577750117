@import '../../assets//css/base.scss';

.sortable-list-table {
  thead {
    border-spacing: 0;

    th {
      border: 0;
      color: var(--greyscale_56);
      max-width: 120px;

      .header-sort {
        cursor: pointer;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .column-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          margin-right: 8px;

          .filter-action {
            height: toRem(24px);
            width: toRem(24px);
            flex-shrink: 0;
          }
        }

        .sort-icon {
          font-size: toRem(24px);
        }
      }
    }
  }

  tbody {
    tr {
      &:hover {
        cursor: pointer;
        background-color: var(--greyscale_88);
      }

      &.row-item-disabled {
        &:hover {
          cursor: default;
          background-color: var(--grayscale_96);
        }

        td {
          .no-data-container {
            background-color: var(--grayscale_96);
            margin: 54px auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            height: 192px;
            padding: 44px 0;
            gap: 8px;

            .no-data-icon {
              color: var(--greyscale_08);
              font-weight: 400;
              flex-shrink: 0;
              margin-top: 14px;
              height: 32px;
              width: 32px;
            }

            .no-data-alert {
              color: var(--greyscale_56);
              margin-top: 10px;
              font-size: toRem(16px);
              font-weight: 500;
            }
          }
        }
      }

      td {
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-left: 0;
        border-right: 0;

        .date-field {
          display: inline-flex;
          align-items: center;

          .calendar-icon {
            margin-right: 0.406rem;
          }

          .exclamation-icon {
            margin-right: 0.406rem;
            color: var(--warning_48);
          }

          .warning-date {
            color: var(--warning_48);
          }
        }

        .action {
          &-field {
            margin-left: -10px;
          }
        }
      }

      &.load-more-row {
        background-color: transparent;
        height: 20px;
        cursor: auto;

        &:hover {
          background-color: transparent;
        }

        & .load-more-col {
          text-align: center;
          height: 20px;
          border: none;

          & .load-more-text {
            color: var(--slider-red);
            font-size: toRem(12px);
            font-style: normal;
            font-weight: 600;
            line-height: toRem(16px);
            padding: 8px 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
