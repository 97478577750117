@import '../../assets/css/base.scss';

.more-icon-container {
  position: relative;
}

.context-menu {
  position: relative;

  .MuiPaper-root {
    border-radius: 12px;
    box-shadow:
      0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05),
      0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1);

    .context-menu-list {
      padding: 0;

      li {
        padding: 8px 12px;

        &:first-child {
          padding-top: 18px;
        }

        &:last-child {
          padding-bottom: 18px;
        }

        .menu-item {
          &-icon {
            height: toRem(20px);
            width: toRem(20px);
            min-width: toRem(20px);
            margin-right: 8px;
            display: flex;
            justify-content: space-around;
            align-items: center;
          }

          &-text span {
            color: var(--greyscale_40);
            font-size: toRem(14px);
            font-weight: 500;
            line-height: 20px;
            overflow-wrap: break-word;
            text-wrap: wrap;
          }

          &-text.notification-text span {
            color: var(--custom_color_19);
            font-size: toRem(14px);
            font-weight: 500;
            line-height: tpRem(20px);
            overflow-wrap: break-word;
            text-wrap: wrap;
          }

          &-expandable {
            display: flex;
            flex-direction: column;
            width: 100%;

            &-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0;
              // width: 100%;

              .MuiListItemText-root span {
                color: var(--greyscale_40);
                font-size: toRem(14px);
                font-weight: 500;
                line-height: 20px;
                overflow-wrap: break-word;
                text-wrap: wrap;
              }
            }
          }
        }
      }
    }
  }
}

#more-button-clip-menu {
  border-radius: 8px;
  backdrop-filter: blur(2px);
  padding: 4px;
}
