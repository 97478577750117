@import '../../assets/css/base';

.zoom-container {
  aspect-ratio: 16 / 9;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;

  .zoom-cell {
    width: 36%;
    height: 100%;
    pointer-events: none;

    &-1 {
      border-radius: 2px 0 0 2px;
      pointer-events: auto;
      border-top: 1px solid rgba(var(--greyscale-custom3), 0.6);
      border-left: 1px solid rgba(var(--greyscale-custom3), 0.6);
      border-bottom: 1px solid rgba(var(--greyscale-custom3), 0.6);
      &:hover,
      &.active,
      &.selected {
        border: 1px solid rgba(var(--greyscale-custom3), 1);
      }
    }

    &-2 {
      pointer-events: auto;
      border-top: 1px solid rgba(var(--greyscale-custom3), 0.6);
      border-left: 1px solid rgba(var(--greyscale-custom3), 0.6);
      border-bottom: 1px solid rgba(var(--greyscale-custom3), 0.6);
      &:hover,
      &.active,
      &.selected {
        border-top: 1px solid rgba(var(--greyscale-custom3), 1);
        border-right: 1px solid rgba(var(--greyscale-custom3), 1);
        border-bottom: 1px solid rgba(var(--greyscale-custom3), 1);
        border-left: 0;

        & + .zoom-cell-1,
        & + .zoom-cell-1.selected {
          border-top: 1px solid rgba(var(--greyscale-custom3), 1);
          border-left: 1px solid rgba(var(--greyscale-custom3), 1);
          border-bottom: 1px solid rgba(var(--greyscale-custom3), 1);
        }
      }
    }

    &-3 {
      border-radius: 0 2px 2px 0;
      pointer-events: auto;
      border: 1px solid rgba(var(--greyscale-custom3), 0.6);

      &:hover,
      &.active,
      &.selected {
        border-top: 1px solid rgba(var(--greyscale-custom3), 1);
        border-right: 1px solid rgba(var(--greyscale-custom3), 1);
        border-bottom: 1px solid rgba(var(--greyscale-custom3), 1);
        border-left: 0;

        & + .zoom-cell-2,
        & + .zoom-cell-2.selected {
          border-top: 1px solid rgba(var(--greyscale-custom3), 1);
          border-left: 0;
          border-bottom: 1px solid rgba(var(--greyscale-custom3), 1);

          & + .zoom-cell-1,
          & + .zoom-cell-1.selected {
            border-top: 1px solid rgba(var(--greyscale-custom3), 1);
            border-left: 1px solid rgba(var(--greyscale-custom3), 1);
            border-bottom: 1px solid rgba(var(--greyscale-custom3), 1);
          }
        }
      }
    }
  }
}
