.apexcharts-canvas {
  background: linear-gradient(180deg, #ebf3ff 0%, #ebf3ff00 100%);
  .apexcharts-grid {
    rect {
      fill: none;
    }
  }
}
.scrollable-chart-container {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  scrollbar-width: thin;
}

.lg-data {
margin-left: 2.6%; 
}

.no-content {
  height: 200px;
  align-items: center;
}

.filter-group {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  min-width: 350px;
}
