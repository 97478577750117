@import '../../../../assets/css/_utilities.scss';

.icon-warning-color {
  fill: var(--warning_80);
}

.device-settings-reclaim-modal {
  .modal-header {
    padding-bottom: 4px;
  }
}

.firmware-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--grayscale_96);
  border-radius: 12px;

  &.editable-off-offline {
    pointer-events: none;
  }

  .firmware-update {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--grayscale_96);
    border-radius: 12px;
  }

  .week-header {
    margin-bottom: 15px;
    padding: 0 20px 0 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    &.choose-time {
      margin-bottom: 1px;
    }

    .choose-day {
      font-style: normal;
      font-weight: 600;
      font-size: toRem(16px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--brand_black);
    }

    .week-day {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);
    }
  }

  .select-day-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    .day-blocks {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 50px;
      width: 55px;
      background: var(--greyscale_88);
      border-radius: 8px;
      color: var(--greyscale_40);
      font-size: toRem(14px);

      &.selected {
        background: var(--primary_40);
        color: var(--brand_white);
      }

      @media (min-width: 1000px) and (max-width: 1200px) {
        height: 45px;
        width: 45px;
      }

      @media (min-width: 400px) and (max-width: 1000px) {
        height: 35px;
        width: 35px;
      }

      .label-value {
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .time-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;

    .timer-start-end {
      width: 50%;
      display: flex;
      justify-content: center;
      gap: 8px;
      // background: var(--brand_white);
      // border: 1.5px solid var(--greyscale_88);
      border-radius: 12px;
      font-size: toRem(16px);
      align-items: center;

      .time-label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: var(--text_color);
      }
    }

    .seprator {
      color: var(--greyscale_08);
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .hour-diffrence {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    font-size: toRem(14px);
    line-height: 20px;
    font-style: normal;
    font-weight: 500;
    color: var(--greyscale_56);
  }
}

.firmware-container-storage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--grayscale_96);
  border-radius: 12px;

  &.editable-off-offline {
    pointer-events: none;
  }

  .firmware-update {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--grayscale_96);
    border-radius: 12px;
  }

  .week-header {
    margin-bottom: 15px;
    padding: 0 20px 0 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    &.choose-time {
      margin-bottom: 1px;
    }

    .choose-day {
      font-style: normal;
      font-weight: 600;
      font-size: toRem(16px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--brand_black);
    }

    .week-day {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);
    }
  }

  .select-day-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;

    .day-blocks {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 50px;
      width: 55px;
      background: var(--greyscale_88);
      border-radius: 8px;
      color: var(--greyscale_40);
      font-size: toRem(14px);

      &.selected {
        background: var(--primary_40);
        color: var(--brand_white);
      }

      @media (min-width: 1000px) and (max-width: 1200px) {
        height: 45px;
        width: 45px;
      }

      @media (min-width: 400px) and (max-width: 1000px) {
        height: 35px;
        width: 35px;
      }

      .label-value {
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .time-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;

    .timer-start-end {
      width: 50%;
      display: flex;
      justify-content: center;
      gap: 8px;
      // background: var(--brand_white);
      // border: 1.5px solid var(--greyscale_88);
      border-radius: 12px;
      font-size: toRem(16px);
      align-items: center;

      .time-label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: var(--text_color);
      }
    }

    .seprator {
      color: var(--greyscale_08);
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .hour-diffrence {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    font-size: toRem(14px);
    line-height: 20px;
    font-style: normal;
    font-weight: 500;
    color: var(--greyscale_56);
  }
}

.css-17xn12o-MuiFormControl-root-MuiTextField-root {
  background-color: var(--brand_white);
}
.css-qbrnxi-MuiFormControl-root-MuiTextField-root {
  background-color: var(--brand_white);
}
