.events-list-panel {
  padding-left: 24px;
}

.close-button {
  display: inline-flex;
  justify-content: space-between;
  height: 44px;
  width: 92px;
  padding-left: 14px;
  padding-right: 14px;
  background-color: var(--grayscale_24) !important;
  border-color: var(--brand_white) !important;
  &:focus {
    background-color: var(--grayscale_24) !important;
    border-color: var(--brand_white) !important;
  }
}

.devices-row {
  --bs-gutter-x: 0.5rem;
}

.filtered-title {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}
