@import '../../assets/css/base';

html:has(.video-wall) {
  background-color: var(--grayscale_16);
}

.video-wall {
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;

  &-container.container {
    max-width: 1440px;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--grayscale_16);
    height: calc(100vh - 72px);

    .video-wall-background {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;

      .justify-content-md-center.row {
        width: 100vw;

        .col-lg-11.col,
        .video-wall-wrapper {
          background-color: var(--grayscale_16);
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }

      .custom-container-fluid {
        width: 100%;
        padding: 0 24px;
      }

      .toast-wrapper {
        display: inline-flex;
        justify-content: space-between;
        text-align: left;
        width: 100vw;
      }

      .page-title {
        color: var(--brand_white);
        font-size: toRem(24px);
      }

      .device-title {
        color: var(--brand_white);
        font-size: toRem(24px);
        font-weight: 700;
        line-height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }

      .device-sub-title {
        color: var(--greyscale_80);
        font-size: toRem(12px);
        font-weight: 500;
        line-height: 16px;
      }

      .button-nav {
        display: inline-flex;
        justify-content: flex-end;

        .button-nav-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--brand_white);
          font-size: toRem(14px);
        }

        a.button-nav-item {
          display: flex;
          height: 44px;
          border-radius: 12px;
          align-items: center;
          padding: 6px 14px;
          text-decoration: none;

          svg {
            margin-left: 12px;
          }
        }

        .dropdown {
          &-menu {
            margin-top: 6px;
            min-width: 234px;
          }

          &-item {
            color: var(--greyscale_08);
            font-size: toRem(14px);

            &-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }

          &-item-text {
            color: var(--greyscale_40);
            font-size: toRem(12px);
          }
        }

        .filter {
          &-button {
            margin-right: 14px;
          }
          &-icon {
            margin-right: 4.2px;
          }
        }
      }

      .cameras-container {
        margin-top: 32px;

        &.video-detail-wrapper {
          margin-top: 0;

          .wrapper-app,
          .events-list-wrapper {
            display: flex;
            justify-content: flex-start;
            color: var(--brand_white);
            position: relative;
            height: calc(100% - 16px);
            margin-top: 16px;
            // padding-bottom: 32px;

            .event-list-container {
              margin-top: 4px;
              width: 100%;

              .fixed-icons-wrapper {
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                display: flex;
                .grid-image,
                .filter-image,
                .filterDate-image {
                  cursor: pointer;
                }
                .grid-image,
                .filterDate-image {
                  margin-right: 8px;
                }
              }
              .events-all-category-wrapper {
                background: var(--grayscale_24);
                // border-radius: 12px;
                text-align: left;
                margin-bottom: 16px;
                .event-wrapper-list {
                  display: flex;
                  padding: 8px 16px;
                  cursor: pointer;
                  &:first-child {
                    padding-top: 12px;
                  }
                  &.selectedEvent {
                    background-color: var(--grayscale_32);
                  }
                  .event-list-image {
                    max-width: 96px;
                    min-width: 96px;
                    max-height: 56px;
                    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
                    border-radius: 8px;
                    background: var(--greyscale_08);
                    overflow: hidden;
                    img {
                      max-width: 100%;
                      width: auto;
                    }
                  }
                  .event-list-content {
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;
                    gap: 4px;
                    margin-left: 12px;

                    &-details {
                      .event-timestamp {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        color: var(--greyscale_56);
                      }
                      .event-name {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        text-transform: capitalize;
                      }
                      .event-image {
                        padding: 0;
                        display: flex;
                      }
                    }
                  }
                }
              }
              .sticky-header {
                .header {
                  text-align: left;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;
                  padding-bottom: 16px;
                  background-color: var(--grayscale_16);
                }
              }
              .scroll-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                overflow: auto;
                &::-webkit-scrollbar {
                  display: block !important;
                }
              }
            }
          }
        }
      }
    }

    .action-buttons {
      display: flex;
      position: sticky;
      z-index: 10;
      // bottom: 32px;
      bottom: 100px;
      align-items: center;
      justify-content: center;
      width: 100%;

      .action-button {
        font-family: 'Noto Sans', Arial, Helvetica, sans-serif;
        font-size: toRem(14px);
        font-weight: 600;
        height: 40px;
        padding: 8px 20px 10px;

        &:hover {
          background-color: var(--primary_24);
          border-color: var(--primary_24);
        }
      }
    }

    .hidden {
      display: none;
    }
  }
}

.no-cameras {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 232px);
  }

  &-notice {
    color: var(--greyscale_56);
    font-size: toRem(16px);
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 20px;
  }

  &-cta {
    .no-cameras-add-device-btn {
      color: var(--greyscale_56);
      border-color: var(--greyscale_56) !important;
      border-radius: 8px;
      font-size: toRem(12px);
      font-weight: 600;
      line-height: 16px;
      height: 32px;
      width: 100px;
    }
  }
}

.category-wrapper-input {
  margin-bottom: 10px;
  label {
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    text-transform: capitalize;
    input {
      margin-right: 10px;
    }
  }
}

.device-title-tooltip {
  border-radius: 8px;
  .tooltip-inner {
    background-color: var(--brand_white) !important;
    color: var(--text_color) !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    height: 36px;
    max-width: 100%;
  }

  .tooltip.show {
    opacity: 1 !important;
  }

  .tooltip-arrow::before {
    border-bottom: 5px solid var(--brand_white) !important;
  }
}
