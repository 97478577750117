.right-section {
  height: 100%;
  .events-list {
    position: relative;

    .events-all-category-wrapper {
      margin-top: 2px;
      background: var(--primary_04) !important;
    }
  }

  .loader-wrapper {
    background-color: var(--primary_04);
    margin-top: 2px;
    height: -webkit-fill-available !important;
    position: absolute;
  }

  .scroll-container {
    margin-top: -28px;
    height: -webkit-fill-available !important;
    position: absolute;

    .sticky-area {
      .sticky-header .header {
        background: var(--primary_04) !important;
        padding: 12px 16px !important;
        line-height: 24px !important;
      }
    }
  }

  .icons-container {
    display: flex;
    .custom-calendar.rmdp-wrapper {
      width: 100%;
      border: none;
      .rmdp-calendar {
        width: 100%;
        padding: 0px;
        .rmdp-day-picker {
          width: 100%;
          padding: 0px 9px;
          > div {
            width: 100%;
            .rmdp-week {
              width: 100%;
              .rmdp-week-day {
                color: var(--greyscale_40);
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px; /* 133.333% */
                text-transform: uppercase;
                display: flex;
                width: 42px;
                height: 18px;
                padding: 0px 5px 2px 6px;
                justify-content: center;
                align-items: center;
              }
              .rmdp-day {
                width: 32px;
                height: 32px;
                padding: 5px 0px 7px 0px;
              }
              .rmdp-selected {
                > span {
                  &:not(.highlight) {
                    background-color: var(--brand_primary);
                  }
                }
              }
            }
          }
        }
        .rmdp-header {
          width: 100%;
          color: var(--brand_black);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          padding: 15px;
          .rmdp-header-values {
            margin-left: 0px;
            > span {
              padding: 0px;
            }
          }
          .rmdp-arrow-container {
            .rmdp-arrow {
              width: 9.971px;
              height: 11.324px;
              flex-shrink: 0;
              border-color: var(--brand_primary);
            }
            &:hover {
              box-shadow: none;
              background-color: var(--brand_primary);
              .rmdp-arrow {
                border-color: var(--brand_white);
              }
            }
          }
          .disabled{
            .rmdp-arrow {
              border-color: var(--primary_80);
            }
            &:hover {
              border-color: var(--brand_white) !important;
              background-color: var(--brand_white) !important;
            }
          }
        }
      }
    }
  }

  .fixed-icons-wrapper {
    position: relative !important;
    justify-content: space-between;
    top: 8px !important;
    padding-right: 16px;
  }
}

.right-section.no-data {
  .label-nodata {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 2px;
  }

  .fixed-icons-wrapper {
    background: var(--primary_04);
    top: 0px !important;
    padding: 8px 12px;
  }
  .scroll-container {
    margin-top: 2px;
    background: var(--primary_04);
    justify-content: center;

    .no-data-container {
      position: absolute;
      // top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: var(--greyscale_56);

      .no-data-found {
        margin-bottom: 16px;
      }

      .refresh-button {
        padding: 8px;
        border-radius: 8px;
        border: 1.5px solid var(--greyscale_56);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        width: max-content;
        margin: auto;
        cursor: pointer;
      }
    }
  }
}

.allTags-modal {
  .modal-header {
    padding-top: 0px;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }

  .site-modal-close {
    padding: 6px;
    height: 24px;
    width: 24px;
    color: var(--greyscale_56);
  }

  .tag-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    background: var(--grayscale_96);
    border-radius: 12px;

    .left-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;

      .icon {
        margin-left: 25px;
        svg {
          height: 20px;
          width: 20px;

          * {
            stroke: var(--primary_32);
          }
        }
      }

      .container-text {
        margin-left: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        display: flex;
        align-items: center;
      }
    }

    .right-container {
      align-items: center;
      display: flex;
      justify-content: center;

      .toggle-field-wrap {
        .toggle-switch {
          margin-top: 0px;
        }
      }
    }
  }
}

.filter-image {
  .red-circle {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: var(--error_64);
    border-radius: 50%;
    display: inline-block;
  }
}

.grid-image {
  .red-circle {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: var(--error_112);
    border-radius: 50%;
    display: inline-block;
  }
}

.tag-list-container {
  padding: 12px;
  background: var(--primary_04);
  text-align: left;
  margin-top: 2px;
  display: flex;
  flex-direction: column;

  .tag-details {
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    background: var(--primary_96);
    color: var(--primary_32);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    margin-top: 3px;
    display: inline-block;
    width: fit-content;
    max-width: -webkit-fill-available;

    .tag-icon {
      svg {
        height: 16px;
        width: 16px;
        * {
          stroke: var(--primary_32);
        }
      }
    }

    .tag-label {
      margin-left: 4px;
      text-transform: capitalize;
    }

    .tag-item {
      background: var(--brand_white);
      padding: 2px 6px 2px 6px;
      border-radius: 6px;
      margin-left: 4px;
      white-space: nowrap;
      text-transform: capitalize;

      .cross-icon {
        cursor: pointer;
        font-size: 16px;
        margin: 4px 0;
      }
    }

    .cross-icon {
      cursor: pointer;
      font-size: 22px;
    }
  }

  .tag-details:first-child {
    margin-top: 0px;
  }
}
