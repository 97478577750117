@import '../../../../assets/css/_utilities.scss';
@import '../../../../assets/css/colors';

.device-infodate-time-title {
  font-style: normal;
  font-weight: 600;
  font-size: toRem(20px);
  line-height: 28px;
  color: var(--brand_black);
  margin-bottom: 18px;

  &.step-two {
    margin-bottom: 8px;
  }

  &.duclo-firmware {
    margin-top: 20px;
  }
}

.device-info-timezone-dropdown {
  margin-top: 16px;
}

.hub-info-block-heading {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(12px);
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--body_color);
  // margin-bottom: 16px;
}

.hub-info-block-content {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--greyscale_08);

  &.time-zone-select {
    margin-bottom: 10px;
  }

  &.device {
    pointer-events: none;
  }
}

.label-timezone {
  margin-top: -8px;
  margin-left: 18px;
  color: var(--greyscale_56);
  font-size: toRem(12px);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  &.device-setting {
    margin-top: 1px;
  }
}

.accordion {
  .accordion-item {
    border: 0;

    .accordion-header {
      .accordion-button {
        background-color: inherit;
        box-shadow: none;

        &:not(.collapsed)::after {
          background-image: var(--bs-accordion-btn-icon);
        }
      }
    }

    .accordion-body {
      padding: 8px 16px 5px;

      .radiotitle {
        margin-bottom: 16px;
      }
    }
  }
}

.setting-info-network {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;
  background: var(--grayscale_96);
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  border-style: dashed;
  border-color: var(--greyscale_80);
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--text_color);
  font-weight: 500;
  cursor: pointer;
}

.setting-plus-icon {
  margin: 0 auto 12px;
}

.choose-day {
  font-style: normal;
  font-weight: 600;
  font-size: toRem(16px);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--brand_black);
}

.week-day {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--greyscale_40);
}

.select-day-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  .day-blocks {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 50px;
    width: 55px;
    background: var(--greyscale_88);
    border-radius: 8px;
    color: var(--greyscale_40);
    font-size: toRem(14px);

    &.selected {
      background: var(--primary_40);
      color: var(--brand_white);
    }

    @media (min-width: 1000px) and (max-width: 1200px) {
      height: 45px;
      width: 45px;
    }

    @media (min-width: 400px) and (max-width: 1000px) {
      height: 35px;
      width: 35px;
    }

    .label-value {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  }
}

.time-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;

  .timer-start-end {
    width: 50%;
    display: flex;
    justify-content: center;
    gap: 8px;
    border-radius: 12px;
    font-size: toRem(16px);
    align-items: center;

    .time-label {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: var(--text_color);
    }
  }

  .seprator {
    color: var(--greyscale_08);
    margin-left: 10px;
    margin-right: 10px;
  }
}

.hub-info-block-content {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--greyscale_08);

  &.time-zone-select {
    margin-bottom: 10px;
  }

  &.device {
    pointer-events: none;
  }
}

.streamingSetting .choose-day {
  margin-bottom: 12px;
}

.streamingSetting .select-day-container {
  padding: 20px;
  background-color: var(--grayscale_96);
  border-radius: 12px;
}

.streamingSetting .select-day-container {
  .day-blocks {
    height: 44px;
    width: 60px;
    font-size: toRem(12px);
  }
}

.settings-block-range {
  margin-top: 25px;
}

.sm-lable {
  font-size: toRem(14px);
  line-height: 20px;
  font-weight: 500;
  color: var(--body_color);
}

.selectd-day {
  margin-top: 8px;
  margin-bottom: 25px;
}

.range-fiel-label {
  font-size: toRem(12px);
  line-height: 16px;
  font-weight: 500;
  color: var(--greyscale_40);
  margin-top: 6px;
}

.label-timezone {
  margin-top: -8px;
  margin-left: 18px;
  color: var(--greyscale_56);
  font-size: toRem(12px);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  &.device-setting {
    margin-top: 1px;
  }
}

.streamingSetting .time-container {
  padding: 20px;
  background-color: var(--grayscale_96);
  border-radius: 12px;
}

.streamingSetting .time-container .MuiFormControl-root .MuiInputBase-root {
  flex-direction: row-reverse;
  padding-left: 0;
  padding-right: 0;
  background-color: var(--brand_white);
  border-radius: 12px;
}

.streamingSetting
  .time-container
  .MuiFormControl-root
  .MuiInputBase-root
  input {
  box-sizing: border-box;
  height: 44px;
  padding-left: 0;
}

.streamingSetting
  .time-container
  .MuiFormControl-root
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--greyscale_88);
}

.streamingSetting .time-container .MuiFormControl-root .MuiInputAdornment-root {
  margin-left: 0;
}

.streamingSetting
  .time-container
  .MuiFormControl-root
  .MuiInputAdornment-root
  .MuiButtonBase-root {
  margin-right: 0;
}

.streamingSetting
  .time-container
  .MuiFormControl-root
  .MuiInputAdornment-root
  .MuiButtonBase-root
  svg {
  width: 16px;
  height: 16px;
}

.contentSubTitle {
  margin-top: 19px;
}

.channel-info.radio-wrapper {
  margin-bottom: 0px;

  .radiotitle {
    padding: 6px 0;
    align-items: center;
    margin-top: 0.5rem;

    &:first-child {
      margin-top: 0;
    }

    .makingBig {
      display: flex;
      align-items: center;
    }
  }
}

.tab-page-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--greyscale_64);
}

.channel-content {
  margin-top: 20px !important ;
  .firmware-container {
    &.fm-container {
      margin-top: 0;
    }
    .time-container {
      .MuiInputBase-root.MuiOutlinedInput-root {
        border-radius: 12px;
      }
    }

    .week-header,
    .select-day-container,
    .time-container,
    .hour-diffrence {
      padding: 0;
    }

    .toggle-field-wrap {
      padding: 6px 0;
      .toggle-switch {
        margin-top: 0px;
      }

      .toggle-field-label.firmware-label {
        font-size: toRem(16px);
        color: var(--greyscale_08);
      }
    }

    .hour-diffrence {
      text-align: left;
    }
  }

  .device-firmware-update-button {
    width: 295px;
  }
}

.device-settings-download-modal {
  .site-spinner-download {
    display: inline-block;
    top: 30%;
    left: 40%;
    position: absolute;
    width: 85px;
    height: 85px;
    padding: 5px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--brand_white);
    --_m: conic-gradient(#0000 2%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: s3 1s infinite linear;
    vertical-align: middle;
  }

  @keyframes s3 {
    to {
      transform: rotate(1turn);
    }
  }
  .modal-header {
    padding-bottom: 4px;
  }
  .inner-content-download-logs {
    margin-top: 10px;

    &.disable-action {
      pointer-events: none;
    }

    .description {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: toRem(16px);
      color: var(--greyscale_08);
      font-weight: 500;
    }
    .encription-box {
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: 50px;
      background: var(--brand_white);
      border: 2px solid var(--greyscale_88);
      border-radius: 15px;
      align-items: center;

      .textbox-key {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(16px);
        line-height: 20px;
        color: var(--greyscale_40);
        margin-left: 10px;
        text-align: left;
      }
    }

    .footer-layout {
      margin-top: 30px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;

      .cancel-btn {
        margin-right: 10px;
        width: 30%;
      }
      .download-btn {
        margin-right: 10px;
        width: 30%;
      }
    }
  }
}

.local-storage-message {
  color: var(--greyscale_56);
  /* Subheading/14px */
  font-family: 'Noto Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 16px;
}
.storage-header-container {
  display: flex;
  padding: 6px 0px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  justify-content: space-between;
}
.storage-info-container {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: var(--greyscale_96);
}
.storage-header {
  color: var(--greyscale_08);

  /* Paragraph/16px/Semibold */
  font-family: 'Noto Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}
.storage-subheader {
  color: var(--greyscale_08);

  /* Paragraph/16px/Medium */
  font-family: 'Noto Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  align-items: center;
  display: flex;
}
.storage-text {
  color: var(--greyscale_56);

  /* Subheading/14px */
  font-family: 'Noto Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.storage-space {
  color: var(--greyscale_08);
  font-family: 'Noto Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
}
.format-card-disabled {
  color: var(--greyscale_40);

  /* Paragraph/16px/Medium */
  font-family: 'Noto Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.format-card-eabled {
  color: var(--primary_40);

  /* Paragraph/16px/Medium */
  font-family: 'Noto Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  cursor: pointer;
}
.localstorage-setup {
  .modal-dialog.modal-md.modal-dialog-centered {
    .modal-header {
      padding: 0px 0px 4px 0px !important;
    }
  }
}
.restortion-maodal {
  .centred-loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .status-indicator {
    margin-right: 0.375rem;
    height: 0.625rem !important;
    width: 0.625rem !important;

    &.status {
      &-online {
        color: var(--success_48);
      }

      &-claiming {
        color: var(--primary_48);
      }

      &-entered {
        color: var(--warning_48);
      }

      &-offline {
        color: var(--error_48);
      }

      &-ready-to-claim {
        color: var(--greyscale_40);
      }
    }
  }
  .table-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: var(--brand_white);
    margin-top: 8px;
    max-height: 525px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  .table-header {
    display: flex;
    padding: 0px 16px;
    align-items: flex-start;
    gap: 24px;
    background: var(--brand_white);
    justify-content: space-between;
  }
  .header-title {
    padding: 12px 0px;
    color: var(--greyscale_40);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
  .table-row {
    display: flex;
    padding: 0px 16px;
    gap: 24px;
    align-items: flex-start;
    border-radius: 12px;
    justify-content: space-between;
    background: var(--greyscale_96);
    margin-bottom: 8px;
    height: 56px;
  }
  .row-data {
    padding: 18px 0px;
    color: var(--greyscale_08);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  .status {
    width: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .channel-date {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .loading {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    color: var(--primary_40);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    flex-direction: row;
  }
}
.margin-right-4 {
  margin-right: 4px;
}
.cursor-auto {
  cursor: auto !important;
}

.disabled-check-box {
  background-color: #8c8f90 !important;
}

.disabled-check-box::before {
  background-color: #a1a4a5 !important;
}

.cloud-storage {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cloud-storage-section {
  background: var(--grayscale_96);
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cloud-storage-info-label {
  font-weight: 400;
  font-size: toRem(14px);
  line-height: toRem(20px);
  color: var(--greyscale_56);
}

.cloud-info-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cloud-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cloud-info-label {
  font-style: normal;
  font-weight: 500;
  font-size: toRem(16px);
  line-height: toRem(20px);
  display: flex;
  align-items: center;
  gap: 6px;
}

.cloud-storage-type-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cloud-option-holder-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cloud-option-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cloud-options-label-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.cloud-option-main-label {
  font-size: toRem(14px);
  line-height: toRem(20px);
  font-weight: 500;
}

.cloud-option-secondary-label {
  font-size: toRem(12px);
  line-height: toRem(16px);
  font-weight: 500;
  color: var(--custom_color_19);
}

.codec-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.selection-disable {
    pointer-events: none;
  }

  .label-codec {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(16px);
    line-height: toRem(20px);
    display: flex;
    align-items: center;
    color: var(--greyscale_40);
  }
}
.codec-selection > input {
  height: toRem(20px);
  width: toRem(20px);
  margin-left: 10px;
}

.codec-selection input[type='radio'] {
  -webkit-appearance: none;
  border-radius: 50%;
  outline: none;
  border: 2px solid var(--greyscale_80);
  background: var(--brand_white);
}

.codec-selection input[type='radio']:before {
  content: '';
  display: block;
  width: 64%;
  height: 64%;
  margin: 18% auto;
  border-radius: 50%;
}

.codec-selection input[type='radio']:checked:before {
  background: var(--primary_40);
}

.codec-selection input[type='radio']:checked {
  border-color: var(--primary_40);
}

.codec-selection-offline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.selection-disable {
    pointer-events: none;
    //background-color: var(--greyscale_64);
  }

  .label-codec {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(16px);
    line-height: toRem(20px);
    display: flex;
    align-items: center;
    color: var(--greyscale_40);
  }
}
.codec-selection-offline > input {
  height: toRem(20px);
  width: toRem(20px);
  margin-left: 10px;
}

.codec-selection-offline input[type='radio'] {
  -webkit-appearance: none;
  border-radius: 50%;
  outline: none;
  border: 2px solid var(--greyscale_80);
  background: #797a7b;
}

.codec-selection-offline input[type='radio']:before {
  content: '';
  display: block;
  width: 64%;
  height: 64%;
  margin: 18% auto;
  border-radius: 50%;
}

.codec-selection-offline input[type='radio']:checked:before {
  background: var(--custom_color_disabled);
}

.codec-selection-offline input[type='radio']:checked {
  border: 1.5px solid var(--custom_color_19);
}

.cloud-storage-tip {
  font-size: toRem(14px);
  line-height: toRem(20px);
  font-weight: 400;
  color: var(--custom_color_19);
}

.content-backup-window {
  height: 104px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
  border: 1.5px dashed var(--custom_color_disabled);
  border-radius: 12px;
  cursor: pointer;
}

.content-backup-window-text {
  font-weight: 500;
  line-height: toRem(20px);
  font-size: toRem(14px);
}

.backup-status-dot {
  height: toRem(10px);
  width: toRem(10px);
  border-radius: 999px;
}

.green-dot {
  height: toRem(10px);
  width: toRem(10px);
  background-color: var(--success_48);
  border-radius: 999px;
}

.red-dot {
  height: toRem(10px);
  width: toRem(10px);
  background-color: var(--error_48);
  border-radius: 999px;
}

.yellow-dot {
  height: toRem(10px);
  width: toRem(10px);
  background-color: var(--warning_48);
  border-radius: 999px;
}

.gray-dot {
  height: toRem(10px);
  width: toRem(10px);
  background-color: var(--greyscale_48);
  border-radius: 999px;
}

.backup-now-button-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.warning-alert {
  display: flex;
  align-items: center;
  gap: toRem(8px);
  font-size: toRem(14px);
  font-weight: 400;
  margin: 8px 0 0 0 !important;
  height: 44px !important;
}

.schedule-list-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.list-item-container {
  display: flex;
  justify-content: space-between;
}

.list-item-day-container {
  font-size: toRem(16px);
  font-weight: 500;
  line-height: toRem(20px);
}

.list-item-time-container {
  font-size: toRem(14px);
  font-weight: 500;
  line-height: toRem(20px);
  color: var(--custom_color_19);
}

.list-item-data-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cloud-backup-window-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.schedule-other-device-main-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 18px;
}

.schedule-data-container {
  font-size: toRem(14px);
  line-height: toRem(20px);
  font-weight: 500;
}

.schedule-other-device-sub-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.location-selection-main-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid var(--hGreyscale_88);
}

.search-location-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
}

.location-input-box {
  height: 44px;
  width: 100%;
  border-radius: 12px;
  border: 1.5px solid var(--hGreyscale_88);
  font-size: toRem(14px);
  font-weight: 500;
  padding: 12px 16px 12px 32px;
  background-color: var(--greyscale_97);
}

.search-location-input {
  height: 44px;
  width: 100%;
  border-radius: 12px;
  border: 1.5px solid var(--hGreyscale_88);
  font-size: toRem(14px);
  font-weight: 500;
  padding: 12px 16px 12px 32px;
}

.input-icon {
  position: absolute;
  transform: translate(65%, 88%);
}

.location-accordion-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 12px;
  max-height: fit-content;
}

.checkbox-style {
  margin-right: 10px;
  margin-top: 1px;
  height: 16px;
  width: 16px;
  cursor: pointer;
  border-radius: 4px;
  border: 1.5px solid var(--greyscale_80);
  background: var(--brand_white);
}

.location-option-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.location-checkbox-label {
  font-size: toRem(14px);
  line-height: toRem(20px);
  font-weight: 500;
}

.location-option-label-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.location-option-label-container-disabled {
  color: #797a7b;
}

.location-accordion {
  background-color: transparent !important;
  min-height: 36px !important;
  font-size: toRem(14px);
  font-weight: 500;
  line-height: toRem(20px);
}

.location-accordion-header {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-lable-arrow-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.location-accordio-main-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 100vh;
  position: inherit;
}

.accordion-main-checkbox {
  margin-right: 0 !important;
}

.selected-devices-count-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px;
  background-color: var(--hGreyscale_88);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.selected-devices-count {
  font-size: toRem(14px);
  font-weight: 600;
  line-height: toRem(20px);
}

.person-event-unsupported-text {
  font-size: toRem(12px);
  line-height: toRem(16px);
  font-weight: 500;
  color: #797a7b;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 27px;
}

.label-and-checkbox-holder-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backup-calendar-button-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sd-card-unavailable-slot-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 600;
  line-height: 1.25rem;

  .sd-card-unavailable {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

.sd-card-status-icons {
  margin: 5px;
}
