@import '../../../assets/css/base.scss';

.node-type {
  &-field {
    display: inline-flex;
    align-items: center;
    color: var(--greyscale_08);
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
  }

  &-icon {
    color: var(--greyscale_08);
    margin-right: 0.375rem;
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
}
