@import '../../../assets/css/base.scss';

.view-log-detail-modal {
  .modal {
    &-header {
      padding-top: 0;
      padding-bottom: 0;
    }

    &-body {
      .description {
        margin-bottom: 16px;
      }

      .col {
        padding: 0;

        .view-log-detail {
          &-wrapper {
            display: flex;
            padding: 22px 20px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 12px;
            background-color: var(--grayscale_96);
          }

          &-content {
            display: flex;
            justify-content: flex-start;
          }

          &-avatar {
            margin-right: 8px;
          }

          &-creator {
            text-align: left;

            &-name {
              color: var(--greyscale_08);
              font-size: toRem(16px);
              font-weight: 500;
              line-height: toRem(20px);
            }

            &-date {
              color: var(--greyscale_40);
              font-size: toRem(12px);
              font-weight: 500;
              line-height: toRem(16px);
            }
          }
        }
      }

      .view-log-detail {
        color: var(--greyscale_08);
        font-size: toRem(12px);
        font-weight: 500;
        line-height: toRem(16px);
      }
    }

    &-footer {
      padding-left: 0;
      padding-right: 0;
      border: 0;

      .status-log {
        &-button-group {
          width: 100%;
        }
      }
    }
  }
}
