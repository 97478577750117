@import '../../assets/css/base';

.location-selector {
  &.disabled {
    pointer-events: none;
  }

  &-toggle {
    &.dropdown-toggle {
      color: var(--greyscale_40);
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      min-width: 177px;
      padding: 8px 16px;
      border: 1.5px solid var(--greyscale_88);
      font-size: toRem(14px);
      font-weight: 500;
      line-height: 20px;

      &.btn-outline-secondary {
        &.show {
          color: var(--greyscale_40);
          background-color: var(--brand_white);
          border-color: var(--primary_48);
        }
      }

      &::after {
        display: none !important;
      }

      &:hover {
        background-color: var(--brand_white) !important;
        color: var(--greyscale_40) !important;
        border-color: var(--primary_48);
      }
    }

    &.dark {
      color: var(--brand_white);
      background-color: var(--grayscale_24);
      border: 0;
      outline: 0;

      &.btn-outline-secondary {
        &.show {
          color: var(--brand_white);
          background-color: var(--grayscale_24);
        }
      }

      &:hover {
        background-color: var(--grayscale_24) !important;
        color: var(--brand_white) !important;
        outline: 1px solid var(--brand_white);
      }
    }
  }

  &-menu {
    &.dropdown-menu {
      min-width: 226px;
      &.show {
        box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
          0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
        border-radius: 12px;
      }
    }

    &-item {
      border-radius: 12px;
      &.dropdown-item {
        color: var(--greyscale_08);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: 20px;

        &.active {
          color: var(--greyscale_08);
          background-color: var(--brand_white);
          font-weight: 700;
        }

        &:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }

    &.dark {
      &.dropdown-menu {
        min-width: 226px;
        &.show {
          background: var(--grayscale_24);
          box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
            0px 10px 15px -3px rgba(0, 0, 0, 0.1);
          border-radius: 12px;
        }
      }

      &-item {
        border-radius: 12px;
        &.dropdown-item {
          background: var(--grayscale_24);
          color: var(--brand_white);
          font-size: toRem(14px);
          font-weight: 500;
          line-height: 20px;

          &.active {
            color: var(--brand_white);
            font-weight: 700;
          }

          &:first-child {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          }

          &:last-child {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }

  .location-selected {
    &-wrapper {
      display: inline-flex;
      align-items: center;
    }

    &-name {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
