@import '../../../assets/css/base.scss';

.view-status-logs-modal {
  .modal {
    &-header {
      padding-top: 0;
      padding-bottom: 0;
    }

    &-body {
      .description {
        margin-bottom: 16px;
      }

      .col {
        padding: 0;

        .log-detail {
          &-wrapper {
            display: flex;
            padding: 22px 20px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 12px;
            background-color: var(--grayscale_96);

            &.centered {
              display: flex;
              justify-content: space-around;
              flex-direction: row;
            }
          }

          &-status {
            color: var(--greyscale_08);
            font-size: toRem(14px);
            font-weight: 500;
            line-height: toRem(20px);
            margin-bottom: 8px;

            .lowercase {
              text-transform: lowercase;
            }

            .capitalize {
              text-transform: capitalize;
            }
          }

          &-date-time {
            color: var(--greyscale_40);
            font-size: toRem(12px);
            font-weight: 500;
            line-height: toRem(16px);
            margin-top: 4px;
            margin-bottom: 4px;
          }

          &-creator {
            color: var(--greyscale_40);
            font-size: toRem(12px);
            font-weight: 500;
            line-height: toRem(16px);
            margin-top: 4px;
            margin-bottom: 8px;
          }

          &-note {
            color: var(--greyscale_08);
            font-size: toRem(12px);
            font-weight: 500;
            line-height: toRem(16px);
            margin-top: 4px;
            margin-bottom: 4px;
          }
        }
      }

      .log-detail {
        color: var(--greyscale_08);
        font-size: toRem(12px);
        font-weight: 500;
        line-height: toRem(16px);
      }
    }

    &-footer {
      padding-left: 0;
      padding-right: 0;
      border: 0;

      .status-log {
        &-button-group {
          width: 100%;
        }
      }
    }
  }
}
