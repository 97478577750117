@import '../../assets/css/base.scss';

.link-expired,
.no-access {
  background-color: #f3f5f7;

  &-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 72px);
  }

  &-card {
    border-radius: 16px;
    background: var(--brand_white);
    padding: 16px 12px;
    max-width: 560px;

    &-title {
      color: var(--primary_08);
      text-align: center;
      font-size: toRem(32px);
      font-weight: 600;
      line-height: toRem(40px);
    }

    &-message {
      color: var(--greyscal_40);
      text-align: center;
      font-size: toRem(14px);
      font-weight: 500;
      line-height: toRem(20px);
      margin-top: 8px;
    }
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiCard-root.link-expired-card {
    display: flex;
    max-width: 629px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: var(--brand_white);
    box-shadow: none;
  }
}
