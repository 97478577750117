@import '../../assets/css/base.scss';

.left-align {
  text-align: left;
}

.filter-incident {
  &-modal {
    &-wrapper {
    }

    .modal {
      &-header {
        padding-top: 0;
      }

      &-body {
        .filters-content-item {
          display: flex;
          padding: 8px 10px 8px 20px;
          align-items: flex-start;
          gap: 12px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: 12px;
          background-color: var(--grayscale_96);
          margin-bottom: 16px;

          &-details {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: var(--greyscale_08);
            font-size: toRem(14px);
            font-weight: 500;
            line-height: toRem(20px);
          }

          &-checkbox {
          }
        }
      }

      &-footer {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        border: 0;

        .filter-incident {
          &-button-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-left: 0;
            margin-right: 0;
            width: 100%;

            .filter-incident-save-btn {
            }
          }
        }
      }
    }
  }
}

.new-incident-modal {
  .modal-header {
    padding-top: 0;
  }

  .modal-footer {
    .manage-tags-button-group {
      width: 100%;
    }
  }
  &-wrapper {
    width: 478px;
  }
}

.manage-incidents {
  .page {
    &-header {
      .incidents-filters {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;

        .manage-tags {
          color: var(--primary_40);
          border-color: var(--primary_40);
          border-radius: 10px;
          gap: 8px;
          padding: 10px 16px;
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);

          &:focus,
          &:active {
            border-color: var(--primary_24);
            background-color: rgba(var(--greyscale-custom1), 0);
          }

          &:hover {
            border-color: var(--primary_24);
            background-color: var(--primary_96);
          }
        }

        .new-incidents {
          border-radius: 10px;
          padding: 10px 16px;
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);
          height: 44px;

          &:focus {
            border-color: var(--primary_24);
          }

          &:hover {
            background-color: var(--primary_24);
          }

          &-plus {
            margin-right: 5px;
            margin-bottom: 2px;
          }

          .new-incidents-button-label {
            text-align: right;
            font-size: toRem(14px);
            font-weight: 600;
            line-height: toRem(22px);
          }
        }
      }

      .page-title {
        padding-left: 0;

        .incidents-total {
          color: var(--greyscale_64);
          font-size: toRem(14px);
          font-weight: 500;
          line-height: toRem(20px);
          margin-left: 5px;
        }
      }
    }
  }

  .no-incidents-container {
    background-color: var(--grayscale_96);
    margin: 54px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 192px;
    padding: 44px 0;
    gap: 8px;

    .folder-icon {
      color: var(--greyscale_08);
      font-weight: 400;
      flex-shrink: 0;
      margin-top: 14px;
    }

    .no-incident-alert {
      color: var(--greyscale_56);
      margin-top: 10px;
      font-size: toRem(16px);
      font-weight: 500;
    }
  }
}
