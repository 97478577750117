@import '../../assets/css/base.scss';

.cus-table{
    .date-field{
        .calendar-icon{
            margin-right: 10px;
        }
        overflow: hidden;
        color: var(--greyscale_08);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        float: left;
    }
.p-l-0{
    padding-left: 0px !important;
}
.f-left{
    float: left;
}
.custom-form-check-input{
    height: 16px;
    width: 16px;
    border-color: var(--custom_color_10);
    // margin-top: 5px;
    flex-shrink: 0;
    // &:checked::before {
    //     content: none;
    // }
    // &:checked::after{
    //     content: none;
    // }
    &:checked {
        background-color: var(--brand_primary);
        border-color: var(--brand_primary);
    }
    &:focus {
        box-shadow: none;
    }
  }

  .m-l-24{
    margin-left: 24px;
  }
  .col-1{
    display: flex;
    align-items: center;
  }


}

.picker-act-lic{
    z-index: 99999;
    position: fixed;
    margin-top: -20px;

    .rmdp-container {
        position: absolute !important;
    }
    .rmdp-input {
        color: transparent !important;
        border-style: none !important;
        background-color: transparent !important;
        width: 110px;
}
.rmdp-input:focus {
    color: transparent !important;
    border-style: none !important;
    background-color: transparent !important;
    box-shadow:none !important
}
}
