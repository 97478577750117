@import '../../assets/css/base.scss';

.clip-details {
  background-color: var(--greyscale_08);
  height: 100%;

  &-container {
    background-color: var(--greyscale_08);
    margin-top: 0;
  }

  .page-header {
    .back-link {
      cursor: pointer;
      color: var(--primary_64);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 10px;
      padding-left: 0;

      &-label {
        color: var(--primary-64);
        font-size: toRem(14px);
        font-weight: 600;
        line-height: toRem(20px);
        margin-left: 4px;
      }
    }

    .page-title {
      color: var(--brand_white);
      font-size: toRem(24px);
      font-weight: 700;
      line-height: toRem(32px);
      padding-left: 0;
      margin-top: 6px;
    }
  }

  .clip {
    &-camera-time {
      color: var(--brand_white);
      font-size: toRem(24px);
      font-weight: 700;
      line-height: toRem(32px);
    }

    &-location {
      color: var(--hGreyscale_80);
      font-size: toRem(12px);
      font-weight: 400;
      line-height: toRem(16px);
    }

    &-details {
      &-top-cta-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;

        .share-btn {
          display: flex;
          padding: 0px 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 10px;
          border: 1.5px solid var(--primary_40);
          color: var(--primary_40);
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);
          width: 107px;
          height: 44px;

          &:active,
          &:hover {
            color: var(--brand_white);
            border: 1.5px solid var(--primary_40);
            background-color: var(--primary_40);

            & > .share-btn-icon {
              color: var(--brand_white);
            }
          }

          &:focus,
          &:focus-visible,
          &:focus-within {
            border: 2px solid var(--primary_24);
            background-color: var(--primary_40);
            outline: 0;

            & > .share-btn-icon {
              color: var(--brand_white) !important;
            }
          }

          .share-button-label {
            color: var(--brand_white);
          }

          .share-btn-icon {
            color: var(--primary_40);
          }
        }

        .contextual-btn {
          display: flex;
          padding: 0px 8px;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          border: 1.5px solid var(--primary_40);
          color: var(--primary_40);
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);
          height: 44px;
          width: 60px;

          &:active {
            border: 0 !important;
            background-color: var(--primary_24) !important;
            outline: 0;
          }

          &:hover {
            color: var(--brand_white);
            border: 1.5px solid var(--primary_24);
            background-color: var(--primary_40);

            & > div > button > .clip-details-menu-icon {
              color: var(--brand_white);
            }
          }

          &:focus,
          &:focus-visible,
          &:focus-within {
            border: 1.5px solid var(--primary_24);
            background-color: var(--primary_40);
            outline: 0;

            & > div > button > .clip-details-menu-icon {
              color: var(--brand_white);
            }
          }

          & > div > button > .clip-details-menu-icon {
            color: var(--primary_40);
          }
        }
      }

      &-shared-list {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        width: 180px;

        .shared-label {
          color: var(--greyscale-40);
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);
          width: 100%;
        }

        .avatars {
          position: relative;

          &-wrapper {
            display: block;
            width: 100%;
            height: 28px;
          }
        }
      }

      &-video-container {
        width: 100%;
        max-height: 499px;
        flex-shrink: 0;

        .clip-video-player video {
          border-radius: 6px;
          max-height: 480px;

          @media screen and (min-width: 576px) {
            max-height: 230px;
          }

          @media screen and (min-width: 768px) {
            max-height: 258px;
          }

          @media screen and (min-width: 992px) {
            max-height: 346px;
          }

          @media screen and (min-width: 1200px) {
            max-height: 415px;
          }

          @media screen and (min-width: 1400px) {
            max-height: 480px;
          }
        }
      }

      &-comments-section {
        display: flex;
        max-width: 423px;
        max-height: 482px;
        padding: 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex-shrink: 0;
        border-radius: 8px;
        background: var(--hGrayscale_24);

        @media screen and (min-width: 520px) {
          max-width: 100%;
        }

        .comment-creator-wrapper {
          text-align: left;
          width: 100%;

          .comment-initiator-text {
            color: var(--hGreyscale_80);
            font-size: toRem(12px);
            font-weight: 400;
            line-height: 1.25rem;

            .comment-creator-name {
              font-weight: 600;
            }

            .comment-create-date {
              margin-right: 6px;
            }

            .comment-edit-icon {
              margin-bottom: 4px;
            }
          }

          .comment-item {
            display: flex;
            justify-content: flex-start;
            align-items: start;
            padding: 16px 6px 16px 20px;
            align-self: stretch;
            border-bottom: 1px solid var(--hGrayscale_24);
            background-color: var(--greyscale_08);
            gap: 10px;
            // min-height: 80px;
            height: max-content;

            &:first-child {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }

            &:last-child {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }

            &-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              margin-top: 16px;
              overflow-y: scroll;

              @media screen and (min-width: 576px) {
                height: 88px;
                max-height: 88px;
              }

              @media screen and (min-width: 768px) {
                height: 100px;
                max-height: 100px;
              }

              @media screen and (min-width: 992px) {
                height: 205px;
                max-height: 205px;
              }

              @media screen and (min-width: 1200px) {
                height: 273px;
                max-height: 273px;
              }

              @media screen and (min-width: 1400px) {
                height: 340px;
                max-height: 340px;
              }

              .comment-item {
                &-avatar-wrapper {
                  margin-top: 2px;
                  height: 100%;
                  width: 30px;
                  position: relative;
                }

                &-author-wrapper {
                  margin-left: 8px;
                  width: 80%;
                  height: 100%;
                  overflow-y: auto;

                  .comment-author {
                    color: var(--brand_white);
                    font-size: toRem(14px);
                    font-style: normal;
                    font-weight: 600;
                    line-height: toRem(20px);

                    .comment-create-datetime {
                      color: var(--hGreyscale_72);
                      font-size: toRem(12px);
                      font-style: normal;
                      font-weight: 500;
                      line-height: toRem(16px);
                      margin-left: 4px;
                    }
                  }

                  .comment-message {
                    color: var(--hGreyscale_64);
                    font-size: toRem(14px);
                    font-style: normal;
                    font-weight: 400;
                    line-height: toRem(20px);
                    align-self: stretch;
                    overflow-wrap: anywhere;
                  }

                  .comment-edit-form {
                    &-content {
                      margin-top: 8px;
                      margin-bottom: 8px;
                    }

                    &-input-field {
                      border-radius: 8px;
                      border: 1.5px solid var(-greyscale_88);
                      background-color: var((--primary_04));
                      outline: 0;

                      &:hover,
                      &:focus,
                      &:focus-visible,
                      &:focus-within {
                        outline: 1px solid var(--brand_white);
                        border: 0;
                      }

                      .MuiOutlinedInput-input {
                        color: var(--brand_white);
                        font-size: toRem(14px);
                        font-weight: 400;
                        line-height: toRem(20px);
                        outline: 0;

                        &:focus,
                        &:focus-visible {
                          border: 1px solid var(-greyscale_88);
                        }
                      }

                      .MuiOutlinedInput-notchedOutline {
                        outline: 0;
                        border: 0;
                      }

                      fieldset {
                        &:focus,
                        &:focus-visible,
                        &:focus-within {
                          border: 1.5px solid var(--brand_white);
                          outline: 0;
                        }
                      }
                    }

                    &-button-group {
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      gap: 8px;

                      .comment-edit-form-save-button {
                        min-width: 60px;
                        padding: 0px 8px;
                        border-radius: 8px;
                        height: 32px;
                        color: var(--brand_white);
                        font-size: toRem(12px);
                        font-weight: 600;
                        line-height: toRem(16px);

                        &:hover {
                          background: var(--primary_24);
                          box-shadow: 0px 4px 12px 0px rgba(36, 59, 83, 0.2);
                        }

                        &:focus {
                          border: 2px solid var(--primary_24);
                          background: var(--primary_40);
                        }
                      }

                      .comment-edit-form-cancel-button {
                        min-width: 70px;
                        padding: 0px 8px;
                        border-radius: 8px;
                        border: 1.5px solid var(--primary_40);
                        height: 32px;
                        color: var(--primary_40);
                        font-size: toRem(12px);
                        font-weight: 600;
                        line-height: toRem(16px);

                        &:hover {
                          color: var(--primary_40);
                          border: 1.5px solid var(--primary_40);
                          background: var(--primary_96);
                        }

                        &:focus {
                          border: 2px solid var(--primary_24);
                        }
                      }
                    }
                  }
                }

                &-option-wrapper {
                  padding-left: 5px;

                  .comment-option {
                    color: var(--primary_64);

                    &:hover {
                      color: var(--brand_white);
                    }
                  }
                }
              }
            }
          }
        }

        .comment-input {
          &-wrapper {
            width: 100%;
            padding-top: 6px;

            .MuiFormControl-root {
              width: 100%;

              fieldset.MuiOutlinedInput-notchedOutline {
                border-radius: 12px;
              }
            }
          }

          &-field {
            border-radius: 8px;
            background-color: var(--greyscale_08);
            width: 100%;

            &:active {
            }

            &:hover,
            &:focus,
            &:focus-visible,
            &:focus-within {
              outline: 1px solid var(--brand_white);
              border: 0;
            }

            &::placeholder {
              color: var(--brand_white);
              font-size: toRem(16px);
              font-weight: 500;
              line-height: toRem(20px);
            }

            .MuiInputBase-input.MuiOutlinedInput-input {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              flex: 1 0 0;
              align-self: stretch;
              color: var(--brand_white);
              border: 1.5px solid var(--greyscale-08);
              background-color: var(--greyscale_08);
              height: 44px;
              outline: 0;

              &::placeholder {
                color: var(--brand_white);
              }
            }

            .MuiOutlinedInput-notchedOutline {
              border: 1.5px solid var(--greyscale-08);
              outline: 0;
            }

            &-submit-icon {
              color: var(--primary_64) !important;
              cursor: pointer;

              &:hover {
                color: var(--brand_white) !important;
              }
            }

            textarea:first-child {
              &::placeholder {
                color: var(--brand_white) !important;
                font-size: toRem(16px);
                font-weight: 500;
                line-height: toRem(20px);
              }

              &:focus,
              &:focus-visible,
              &:focus-within {
                border: 1.5px solid var(--greyscale-08);
                outline: 0;
              }
            }

            fieldset {
              &:focus,
              &:focus-visible,
              &:focus-within {
                border: 1.5px solid var(--greyscale-08);
                outline: 0;
              }
            }
          }
        }
      }

      &-tags-title,
      &-logs-title {
        &-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        color: var(--brand_white);
        font-size: toRem(20px);
        font-weight: 600;
        line-height: toRem(28px); /* 140% */
      }

      &-button-column {
        padding-right: 4px;
      }

      &-button-group {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;

        .download-button {
          color: var(--brand_white) !important;
          display: flex;
          padding: 0px 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          outline: 1.5px solid var(--primary_64);
          border: 0;
          padding: 12px;
          height: 44px;
          min-width: 110px;
          color: var(--primary_40);
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);

          &.wider {
            margin-right: 16px;
          }

          &:active {
            border: 1.5px solid var(--primary_24);
            background-color: var(--primary_24);
          }

          &:hover {
            color: var(--brand_white);
            border: 1.5px solid var(--primary_40);
            background-color: var(--primary_40);
          }

          &:focus,
          &:focus-visible,
          &:focus-within {
            border: 2px solid var(--primary_24);
            background-color: var(--primary_40);
            outline: 0;
          }

          .site-spinner {
            height: 20px;
            width: 18px;
          }
        }

        .add-to-incidents-wrapper {
          display: flex;
          padding: 0px 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 180px;
          height: 44px;
          padding: 12px;
          margin-right: 5px;

          .incidents-list-form-control {
            width: 174px;

            &.wider {
              width: 195px;
              margin-right: 10px;
            }

            .incident-select-label {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              top: -16px;
              gap: 8px;
              height: 44px;
              color: var(--brand_white);
            }

            .incident-select {
              color: var(--brand_white);
              background-color: var(--grayscale_32);
              border-radius: 12px;
              height: 44px;

              &:active {
                border: 0 !important;
                outline: 1px solid var(--brand_white);
              }

              &:hover {
                color: var(--brand_white);
                outline: 1px solid var(--brand_white);
              }

              &:focus,
              &:focus-visible,
              &:focus-within {
                outline: 1px solid var(--brand_white);
              }

              &-icon {
                color: var (--primary_64);
                height: 30px;
                width: 30px;
                margin-right: 15px;
                pointer-events: none;
              }

              &.added-to-incident {
                background-color: var(--brand_black);
                outline: 1.5px solid var(--primary_64);
                border: 0;

                &:active,
                &:hover {
                  color: var(--brand_white);
                  background-color: var(--primary_40);
                }

                // &:focus,
                // &:focus-visible,
                // &:focus-within {
                //   background-color: var(--primary_40);
                // }

                .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
                  padding-right: 10px !important;
                }
              }

              &-add-to-incident-value {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: var(--brand_white);
                font-size: toRem(14px);
                font-weight: 600;
                line-height: toRem(22px);
                outline: 0;
                border: 0;
                margin-left: 5px;
              }

              &-added-to-incident-value {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: var(--brand_white);
                font-size: toRem(14px);
                font-weight: 600;
                line-height: toRem(22px);

                &::before {
                  content: url('../../assets/images/icons/CheckmarkWhite.svg');
                  height: 20px;
                  width: 20px;
                  margin-top: -5px;
                  margin-left: 2px;
                  margin-right: 12px;
                  color: var(--brand_white);
                  background-color: transparent;
                }
              }

              .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-right: 10px;
              }

              .incident-item-wrapper {
                color: var(--brand_white);
                height: 44px;

                &.Mui-selected {
                  background-color: var(--hGrayscale_32) !important;
                }

                .incident-item-name {
                  width: 210px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              input {
                height: 44px;
              }

              fieldset.MuiOutlinedInput-notchedOutline {
                border: 0;
              }
            }
          }
        }
      }
    }
  }

  .unknown-clip {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 72px);
    }

    &-content {
    }

    &-alert {
      color: var(--brand_white);
      text-align: center;
      font-size: toRem(32px);
      font-weight: 600;
      line-height: toRem(30px);
    }

    &-reason {
      color: var(--greyscale_56);
      text-align: center;
      font-size: toRem(14px);
      font-weight: 500;
      line-height: toRem(20px);
    }

    &-btn-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-back-btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 12px 20px;
      margin-top: 16px;

      &-icon {
        height: 24px;
        width: 24px;
      }

      &-label {
        margin-left: 8px;
        font-size: toRem(14px);
        font-weight: 600;
        line-height: toRem(20px);
      }
    }
  }
}

.delete-clip {
  &-modal {
    .modal-header {
      margin-top: 0;
      padding-top: 0;
      justify-content: space-between;
    }
  }

  &-confirmation {
    &-clip-identifier {
      color: var(--brand_black);
      font-weight: 600;
    }

    &-message-emphasis {
      color: var(--brand_black);
      font-weight: 600;
    }

    &-container {
    }

    &-message {
      color: var(--greyscale_56);
      font-size: toRem(16px);
      font-weight: 400;
      line-height: toRem(24px);
      margin-top: 12px;
      margin-bottom: 24px;
    }

    &-button-group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      height: 100%;
      margin-top: 12px;
    }

    &-delete-btn {
      display: flex;
      color: var(--brand_White);
      background-color: var(--error_48) !important;
      border-color: var(--error_48) !important;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      width: 100%;
      height: 56px;
      font-size: toRem(18px);
      font-weight: 600;
      line-height: toRem(24px);

      &:hover {
        border: 1.5px solid var(--error_24) !important;
        background-color: var(--error_24) !important;
      }
    }

    &-cancel-btn {
      display: flex;
      background-color: var(--brand_white);
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      border: 1.5px solid var(--primary_40);
      width: 100%;
      height: 56px;

      &:hover {
        color: var(--primary_40) !important;
        border: 1.5px solid var(--primary_40) !important;
        background-color: var(--primary_96) !important;
      }

      &:focus {
        border: 2px solid var(--primary_24) !important;
      }
    }
  }

  &-confirmed-container {
  }
}

.comment-item-text {
  span {
    margin-left: 2px;
  }
}

#menu-clip-details-menu {
  .MuiPaper-root.MuiPaper-elevation.MuiMenu-paper.MuiPopover-paper:has(
      .context-menu-list
    ):has(.MuiButtonBase-root.MuiMenuItem-root) {
    &:has(.clip-details-menu-item-option) {
      margin-top: 5px;
      margin-left: -6px;
      background-color: var(--hGrayscale_32) !important;
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      width: 197px;
    }

    &:has(.comment-item-text) {
      background-color: var(--hGrayscale_32) !important;
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      margin-top: -36px;
      margin-left: -100px;
      width: 110px;
    }
  }

  .context-menu-list {
    .MuiButtonBase-root.MuiMenuItem-root {
      &:hover {
        background-color: var(--hGreyscale_40);
      }

      .clip-details-menu-item-option,
      .comment-item-text {
        span.MuiListItemText-primary {
          color: var(--brand_white);
        }
      }
    }
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiMenu-paper.MuiPopover-paper {
  &:has(.MuiList-root.MuiMenu-list) {
    &:has(.incident-item-wrapper) {
      background-color: var(--hGrayscale_32) !important;
      margin-top: 46px;
      width: 255px;
      height: 152px;
    }
  }

  &:has(.context-menu-list) {
    background-color: var(--brand_white) !important;
  }
}
