@import '../../assets/css/base.scss';

.shared-list-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 110px;

  .shared-label {
    color: var(--greyscale_64);
    font-size: toRem(14px);
    font-weight: 600;
    line-height: toRem(20px);
    white-space: nowrap;
  }

  .avatars {
    position: relative;
    height: 100%;
    width: 100%;

    &-wrapper {
      height: 28px;
      position: relative;

      // Fixed widths are needed for the parent due to the
      // absolutely positioned child elements and number of
      // child elements inside the wrapper.
      &.one-subscriber {
        width: 35px;
      }

      &.two-subscribers {
        width: 52px;
      }

      &.three-subscribers {
        width: 70px;
      }

      &.large-group-subscribers {
        width: 88px;
      }
    }

    .subscriber-tooltip {
      display: flex;
      flex-direction: column;
      justify-content: left;
      text-align: left;

      &-text {
        width: 100%;
      }
    }
  }
}

.avatar {
  position: absolute;
  top: 0;
  display: flex;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 204px;
  border: 1px solid var(--grayscale_24);
  color: var(--White, #fff);
  font-size: toRem(12px);
  font-weight: 500;
  line-height: toRem(16px);

  &-wrapper {
    position: relative;
    width: 30px;
    margin-right: 12px;
  }

  &.avatar-1 {
    left: 8px;
    border-radius: 204px;
    background: var(--avatar_dark_brand);
  }

  &.avatar-2 {
    left: 26px;
    background: var(--avatar_dark_lime);
  }

  &.avatar-3 {
    left: 44px;
    background: var(--avatar_dark_purple);
  }

  &.avatar-4 {
    left: 62px;
    background: var(--avatar_dark_pink);
  }

  &.avatar-rest {
    left: 62px;
    background: var(--dark_general, #484c60);
  }

  &.avatar-no-offset {
    left: 0;
  }
}

.tooltip-inner {
  text-align: left !important;
}
