@import '../../assets/css/base.scss';


.term-condition-policy{
    .modal-dialog.modal-md.modal-dialog-centered{
          .modal-content{
        padding: 32px !important;
    }
    }
  
    .m-t-8{
        margin-top: 8px;
    }
    .update-terms-services{
        .title-holder{
            position: relative;
            .title{
                color: var(--greyscale-08);
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
            }
          
            display: flex;
        }
        .sub-title{
            color: var(--greyscale_56);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            .link{
                color: var(--primary_56);
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                text-decoration-line: underline;
            }
        }
        .close {
            position: absolute;
            right: 10px;
            cursor: pointer;
            height: 24px;
            width: 24px;
          }
          .check-term{
            margin-top: 24px;
            margin-bottom: 24px;
            color: var(--greyscale_08);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            display: flex;
            align-items: center;
            .m-l-8{
                margin-left: 8px;
            }
            .link{
                color: var(--primary_56);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                text-decoration-line: underline;
            }
          }
    }

    .update-privacy-policy{
    
        .modal-dialog.modal-md.modal-dialog-centered{
            .modal-content{
          padding: 32px !important;
      }
      }
    
      .m-t-8{
          margin-top: 8px;
      }
      .update-terms-services{
          .title-holder{
              position: relative;
              .title{
                  color: var(--greyscale-08);
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 32px;
              }
            
              display: flex;
          }
          .sub-title{
              color: var(--greyscale_56);
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              .link{
                  color: var(--primary_56);
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                  text-decoration-line: underline;
              }
          }
          .close {
              position: absolute;
              right: 10px;
              cursor: pointer;
              height: 24px;
              width: 24px;
            }
            .check-term{
              margin-top: 24px;
              margin-bottom: 24px;
              color: var(--greyscale_08);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              display: flex;
              align-items: center;
              .m-l-8{
                margin-left: 8px;
            }
    
            .m-t-15-neg{
                margin-top: -15px;
            }
              .link{
                  color: var(--primary_56);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                  text-decoration-line: underline;
              }
            }
      }
    }
    .custom-form-check-input{
        height: 16px;
        width: 16px;
        border-color: var(--custom_color_10);
        // margin-top: 5px;
        flex-shrink: 0;
        &:checked::before {
            content: none;
        }
        &:checked::after{
            content: none;
        }
        &:checked {
            background-color: var(--brand_primary);
            border-color: var(--brand_primary);
        }
        &:focus {
            box-shadow: none;
        }
      }
}

.m-t-15-neg{
    margin-top: -15px;
}