.card-container-notification {
  .snap-shot-container {
    position: relative;

    .cam-1x {
      width: 478px;
    }

    .cam-snapshot {
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      aspect-ratio: 16/9;
      object-fit: fill;
      border-radius: 12px;
      width: 478px;

      .cam-snapshot-wrapper {
        height: 100%;
      }
    }

    & .live-snapshot {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      background: var(--greyscale_08);

      & .live-snapshot-image {
        z-index: 10;
        max-width: 100%;
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: contain;
        aspect-ratio: 16 / 9;

        &.image-aspect {
          aspect-ratio: 1;
        }
      }
    }
  }
}