@import '../../assets/css/base';
// @import url('https://fonts.googleapis.com/css?family=Noto+Sans:regular,bold,italic&subset=latin,latin-ext');


.marketing-pages{
  body{
    margin: 0;
    color: var(--brand_white);
    font-family: 'Noto Sans';
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sign_up {
    text-align: center;
    color: var(--brand_white);
    min-height: 100%;
    background-size: cover;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    font-family: 'MuseoModerno';
  }

  .sign_up form{
    text-align: left;
  }

  h1{
    font-family: 'Graphik';
    text-transform: capitalize;
    font-size: 45px;
    font-weight: bold !important;
    font-style: normal;
    margin-bottom: 20px;
  }

  h2{
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  p, a{
    font-family: 'MuseoModerno';
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    line-height: 1.8;
  }

  small{
    font-size: 12px;
  }

  .sign_up .card{
    background: transparent;
  }

  .green-btn{
    background-color: var(--custom_color_12) !important;
    color: var(--brand_white)  !important;
    border-color:var(--custom_color_12) !important;
    border-radius: 300px !important;
  }

  .green-btn_fullWidth{
    background-color: var(--custom_color_12) !important;
    color: var(--brand_white)  !important;
    border-color: var(--custom_color_12) !important;
    border-radius: unset !important;
    width: 100%;
    box-shadow: unset !important;
  }

  .green-btn:hover,
  .green-btn:active {
      opacity: .8;
  }

  .btn:active,
  .btn:active:focus,
  .btn:hover,
  .btn:focus,
  .btn-primary:active,
  .btn-primary:active:focus,
  .btn-primary:hover,
  .btn-primary:focus{
    box-shadow: unset;
    outline: 0;
  }

  .error {
    color: var(--custom_color_3);
    font-size: .875em;
    margin-top: 0.25rem;
    text-align: start;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }

  .error-message {
      color: #FF6565;
      padding: .5em .2em;
      height: 1em;
      position: absolute;
      font-size: .8em;
  }

  .remember-me{
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--text_color);
  }

  .form-check .custom-form-check-input{
    height: 16px;
    width: 16px;
    border-color: var(--custom_color_10);
    margin-top: 5px;
    flex-shrink: 0;
    &:checked::before {
        content: none;
    }
    &:checked::after{
        content: none;
    }
    &:checked {
        background-color: var(--brand_primary);
        border-color: var(--brand_primary);
    }
    &:focus {
        box-shadow: none;
    }
  }
  .remember-me a{
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--primary_40);
  }

  .invalid-feedback{
    display: block;
  }

  .site-top-bar {
    background-color: var(--primary_96);
    height: 72px;
    padding: 16px 32px;
    border-bottom: 1px solid var(--greyscale_88);
  }

  .main-page{
    min-height: 100vh;
    background-color: var(--primary_96);
  }

  .site-text-block{
    font-family: 'Noto Sans';
  }

  /* Input css */

  .form-control{
    padding: 13px 20px;
    height: 52px;
    border: 1.5px solid var(--greyscale_88);
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }
  .autoaddress {
    padding-right: 30px !important;
  }
  select.timezone-placeholder {
    color: var(--greyscale_40);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .form-control:focus,
  .form-control:active{
    border: 2px solid var(--primary_48);
    color: var(--primary_08);
  }

  .form-control.is-invalid, .was-validated .form-control:invalid, .form-control.gm-err-autocomplete {
    background: var(--error_96);
    border: 1.5px solid var(--custom_color_3);
    margin-bottom: 0;
  }

  .was-validated .form-control:invalid::placeholder, .form-control.is-invalid::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--error_64);
  }

  .form-control::placeholder{
    display: none;
  }

  .input-with-icons{
    position: relative;
  }

  .input-with-icons .form-control{
    text-indent: 30px;
  }

  .input-with-icons .form-control.is-invalid, .input-with-icons .was-validated .form-control:invalid{
    background-image: none;
  }

  .input-with-icons .icon-before-input{
    position: absolute;
    opacity: 1;
    left: 20px;
    height: 52px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-with-icons .icon-before-timezone{
    position: absolute;
    opacity: 1;
    left: 25px;
    height: 52px;
    display: flex;
    align-items: center;
  }

  .input-with-icons .icon-after-input{
    position: absolute;
    right: 20px;
    opacity: 1;
    height: 52px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
  }

  .input-with-icons.is-invalid .icon-before-input svg path,
  .input-with-icons.is-invalid .icon-after-input svg path{
    stroke: var(--error_64);
  }

  .card{
    background: var(--brand_white);
    border-radius: 16px;
    border: 0;
    padding: 24px;
    text-align: center;
  }

  .site-spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 5px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--brand_white);
    --_m:
      conic-gradient(#0000 2%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: s3 1s infinite linear;
  }
  @keyframes s3 {to{transform: rotate(1turn)}}

  .placeholder-wrapper{
    color: var(--greyscale_40);
    top: -2px;
    left: 20px;
    height: 52px;
    padding: 16px 20px;
    position: absolute;
    pointer-events: none;
  }

  .placeholder-wrapper.required:after{
    content: '*';
    margin-left: 6px;
    color: red;
  }

  .placeholder-wrapper.is-invalid{
    color: var(--error_64);
  }

  .form-control:focus + .placeholder-wrapper,
  .form-control:active + .placeholder-wrapper,
  .form-control.has-value + .placeholder-wrapper{
    display: none;
  }

  .input-with-icons .placeholder-wrapper {
    left: 50px;
  }

  .error:last-child{
    margin-bottom: 16px;
  }

  .main-page-content{
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .call-icon{
    transform: rotate(280deg);
  }

  .form-terms-link{
    margin-left: 5px;
    margin-right: 5px;
  }

  .form-privacy-link {
    margin-left: 5px;
  }


  .customer-success-page  .profile-heading{
    margin-bottom: 16px;
  }

  .success-block-text{
    margin-bottom: 12px;
  }

  .success-block-text:last-child {
    margin-bottom: 0;
  }

  .customer-success-container{
    background-color: #F3F5F7;
    color: #000000;
  }

  @media (min-width: 992px){
    .navbar-expand-lg .navbar-nav {
      align-items: end;
      justify-content: end;
    }
  }


  @media  screen and (max-width: 768px) {
    body{
      background-color: var(--primary_96);
    }
    .site-top-bar{
      border-bottom: 0;
    }
    .main-page-content{
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .manage-responsive{
      padding: 0 !important;
      margin: 0 !important;
    }
    .main-card{
      border-radius: 32px 32px 0px 0px;
    }
    .remove-padding-responsive{
      padding-left: 0;
      padding-right: 0;
    }
  }

  .became-partner-app-detail{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .org-text-hint{
    color: var(--greyscale_40);
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    padding-right: 20px;
    display: flex;
    font-size: 12px;
  }
  
  .m-t-15-m{
    margin-top: -15px;
  }
 
  .p-l-5{
    padding-left: 5px;
  }
  .max-width-text{
    width: 102%;
  }

  .display-f{
    display: flex;
  }
  
  .exclamationMark{
    margin-right:5px;
  }

  .error-parent{
    margin-top: 0px;
    margin-left: 0px;
  }
  .m-t-15-m{
    margin-top: -15px;
  }
  .m-l-15{
    margin-left: 15px;
  }
  .m-l-30{
    margin-left: 30px;
  }
  .w-93{
    width: 93%;
  }
  .short-text-error{
    margin-top: -2px;
    margin-left: 3px;
  }
  .m-t-3{
    margin-top: 3px;
  }
}
