.btn-primary-outline-NewRole {
  background-color: var(--transparent_color) !important;
  border-color: var(--primary_40) !important;
  color: var(--primary_40) !important;
  &:disabled {
    background-color: var(--transparent_color) !important;
    border-color:  var(--greyscale_72) !important;
    color: var(--greyscale_72) !important;
  }
  .site-spinner{
    background: var(--primary_40);
  }
}