@import '../../../assets/css/_utilities.scss';

.avatar-name-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  &.smaller {
    gap: 4px;
  }

  & .site-avatars {
    & img,
    & .site-avatar-number {
      display: block;
    }
  }

  & .user-icon {
    height: 24px !important;
    width: 24px !important;

    &.smaller {
      height: 18px !important;
      width: 18px !important;
    }

    & circle {
      fill: var(--avatar_dark_independence);
    }

    & g {
      & path {
        fill: var(--custom_color_disabled);
      }
    }
  }

  & .initials-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: toRem(14px);

    &.smaller {
      color: var(--text_color);
      font-size: toRem(12px);
      font-style: normal;
      font-weight: 500;
      line-height: toRem(16px);
    }
  }
}
