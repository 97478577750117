.cam {
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: var(--brand_black);
  border-radius: 4px;

  &-device-wall{
    &-1x {
      height: 32px;
      width: 56px;
    }
  
    &-2x {
      height: 162px;
      width: 288px;
      z-index: 99999;
      // position: absolute;
    }
  }

  &-content {
    width: 100%;
    position: relative;
  }

  &-device-wall-snapshot {
    // border: 2px solid transparent;
    border-radius: 4px;
    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
    display: flex;
    align-items: center;
    background-color: var(--brand_black);
    &-1x {
      height: 32px;
      width: 56px;
    }
  
    &-2x {
      height: 162px;
      width: 288px;
      // position: fixed;
      background-color: var(--brand_black);
      border-radius: 8px;
    }
    &-image {
      border-radius: 4px;
      aspect-ratio: 16 / 9;
      object-fit: scale-down;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }

    &-wrapper {
      width: 100%;
      position: absolute;
      z-index: 5;
      top: 0;
    }
  }

  &-offline {
    background-color: var(--grayscale_32);
    border-radius: 4px;
    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);

    &-device-wall-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 32px;
      width: 56px;
      overflow: hidden;
      background-color: var(--grayscale_16);
      color: var(--greyscale_56);
      box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
      border-radius: 4px;

      .camera-offline {
        &-icon {
          margin-top: 11%;
        }

        &-label {
          font-size: toRem(12px);
          color: var(--greyscale_56);
          margin-top: 4px;
        }
      }
    }
  }
  &-overlay {
    background: rgba(var(--greyscale-custom2), 0.6);
    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
    border-radius: 4px;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
    
    &:hover,
    &:active,
    &:focus {
      opacity: 1;
      animation: fadeIn 0.15s ease;

      + .item-location {
        display: none;
      }
    }

    &-content {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      .zoom-options {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 15;

        &.hidden {
          display: none;
        }
      }

      .zomm-snapshot-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .zomm-button {
          color: var(--brand_white);
          height: 20px;
          width: 20px;
          display: block;
          object-position: center;

          svg {
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.new-portal-zoom-image {
  width: 288px;
  height: 162px;
  z-index: 999999;
  position: absolute;
  border-radius: 8px;
  background: var(--brand_black);
}

input[type='checkbox']:checked::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: var(--primary_64);
  text-align: center;
  position: absolute;
  border-radius: 2px;
}
.camera-wall-devices .main-page-wrapper .table .selected-row{
  // border-bottom:0px;
}
.camera-wall-devices .main-page-wrapper .table .selected-row td div.viewCheckbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    // border: 2px solid var(--primary_64);
    border-radius: 8px;
}

.camera-wall-devices .main-page-wrapper .table .selected-row td div.viewCheckbox:first-child {
    padding-left: 20px;
}

.camera-wall-devices .main-page-wrapper .table .selected-row td:first-child {
    border-left-style: solid;
    // border-radius: 8px 0 0 8px;
}
.camera-wall-devices .main-page-wrapper .table .selected-row td:last-child {
    border-left-style: solid;
    border-radius:0 8px 8px 0;
}
.camera-wall-devices .main-page-wrapper .table .non-Selected-row-hover:hover{
  background:var(--primary_04);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  border-left-style: solid;
  border-radius: 8px;

}
 
.camera-wall-devices .main-page-wrapper .table .non-Selected-row-hover td:first-child {
  border-left-style: solid;
  border-radius: 8px 0 0 8px;
}
.camera-wall-devices .main-page-wrapper .table .non-Selected-row-hover td:last-child {
  border-left-style: solid;
  border-radius:0 8px 8px 0;
}

  