@import '../../../assets//css/base.scss';

.add-tags-modal,
.edit-tags-modal {
  .modal-header {
    padding-top: 0;
    padding-bottom: 0;
  }

  &-description {
    color: var(--greyscale_56);
    font-size: toRem(16px);
    font-weight: 400;
    line-height: toRem(24px); /* 150% */
  }

  .manage-tags-pill {
    display: flex;
    padding: 8px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    color: var(--brand_white);

    /* Paragraph/14px/Medium */
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px); /* 142.857% */
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;

    &.assigned {
      background-color: var(--primary_40);
    }

    &.unassigned {
      background-color: var(--grayscale_32);
    }

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      flex-wrap: wrap;
    }
  }
}
