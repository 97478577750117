.date-time-picker-modal {
  .modal-dialog .modal-content {
    min-width: 414px;
    width: 414px;
  }
  .modal-header {
    padding: 0px;
    justify-content: space-between;
  }
}
.date-time-picker-modal-range {
  .modal-dialog .modal-content {
    min-width: 414px;
    width: 414px;
  }
  .modal-header {
    padding: 0px;
    margin-left: 5px;
    margin-right: 6px;
    justify-content: space-between;
  }
}
.date-time-picker-bw {
  &.dark {
    .custom-calendar.rmdp-wrapper {
      .rmdp-calendar {
        .rmdp-header {
          margin-bottom: 6px !important;

          &.disabled {
            &:hover {
              background-color: var(--brand_primary) !important;
            }
          }
        }
      }
    }
  }

  .date-time-picker-main-range {
    padding: 24px;

    .timeline-calendar.rmdp-wrapper {
      .rmdp-calendar .rmdp-header {
        .rmdp-header-values {
          > span:first-child,
          span:last-child {
            background-color: var(--primary_96) !important;
            color: var(--person_notification_bg) !important;
            padding: 2px 8px !important;
          }
        }
      }
    }
  }
  .rmdp-calendar-range .rmdp-header {
    .rmdp-header-values {
      > span:first-child,
      span:last-child {
        background-color: var(--brand_white) !important;
        color: var(--brand_black) !important;
        padding: 2px 10px 3px !important;
      }
    }
  }

  .date-time-picker-main {
    .timeline-calendar.rmdp-wrapper {
      .rmdp-calendar .rmdp-header {
        .rmdp-header-values {
          > span:first-child,
          span:last-child {
            background-color: var(--primary_96) !important;
            color: var(--primary_32) !important;
            padding: 2px 10px 3px !important;
            border-radius: 4px !important;
          }
        }
      }
    }
  }

  .select-time-label {
    color: var(--greyscale_08);
    font-size: toRem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: toRem(20px);
    margin-top: 24px;
    margin-bottom: 8px;
  }
  .custom-calendar.rmdp-wrapper {
    width: 100%;
    border: none;
    .rmdp-calendar {
      width: 100%;
      padding: 0px;
      .rmdp-day-picker,
      .rmdp-month-picker,
      .rmdp-year-picker {
        width: 100%;
        padding: 0px;
        > div {
          width: 100%;
        }
        .rmdp-week,
        .rmdp-ym {
          width: 100%;
          .rmdp-week-day {
            color: var(--greyscale_40);
            text-align: center;
            font-size: toRem(12px);
            font-style: normal;
            font-weight: 600;
            line-height: toRem(16px);
            text-transform: uppercase;
            display: flex;
            width: 42px;
            height: 18px;
            padding: 0px 5px 2px 6px;
            justify-content: center;
            align-items: center;
          }
          .rmdp-day {
            width: 32px;
            height: 32px;
            padding: 5px 0px 7px 0px;
            &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
              background-color: var(--primary_48);
            }
          }
          .rmdp-selected {
            > span {
              &:not(.highlight) {
                background-color: var(--primary_40);
              }
            }
          }
        }
      }
      .rmdp-header {
        width: 100%;
        color: var(--brand_black);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: toRem(20px); /* 142.857% */
        .rmdp-header-values {
          margin-left: 0px;
          > span {
            padding: 0px;
          }
          > span:first-child,
          span:last-child {
            background-color: var(--primary_96);
            color: var(--primary_32);
            padding: 2px 10px 3px;
            border-radius: 4px;
          }
          > span:last-child {
            margin-left: 5px;
          }
        }
        .rmdp-arrow-container {
          .rmdp-arrow {
            width: 9.971px;
            height: 11.324px;
            flex-shrink: 0;
            border-color: var(--brand_primary);
          }
          &:hover {
            box-shadow: none;
            background-color: var(--brand_primary);
            .rmdp-arrow {
              border-color: var(--brand_white);
            }
          }
        }
        .disabled {
          .rmdp-arrow {
            border-color: var(--primary_80);
          }
          &:hover {
            border-color: var(--brand_white) !important;
            background-color: var(--brand_white) !important;
          }
        }
      }
    }
  }

  .custom-calendar-range.rmdp-wrapper {
    width: 100%;
    border: none;
    .rmdp-calendar {
      width: 100%;
      padding: 0px;
      .rmdp-day-picker,
      .rmdp-month-picker,
      .rmdp-year-picker {
        width: 100%;
        padding: 0px;
        > div {
          width: 100%;
        }
        .rmdp-week {
          margin-top: 3px;
        }
        .rmdp-week,
        .rmdp-ym {
          width: 100%;
          .rmdp-week-day {
            color: var(--primary_03);
            text-align: center;
            font-size: toRem(12px);
            font-style: normal;
            font-weight: 600;
            line-height: toRem(16px); /* 133.333% */
            text-transform: uppercase;
            display: flex;
            width: 42px;
            height: 18px;
            padding: 0px 5px 2px 6px;
            justify-content: center;
            align-items: center;
          }
          .rmdp-day {
            width: 42px;
            height: 42px;
            color: var(--text_color);
            padding: 5px 0px 7px 0px;
            &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
              background-color: var(--primary_48);
            }
            &.rmdp-disabled {
              color: var(--primary_03);
            }
            &.rmdp-today:not(.rmdp-range) {
              background: var(--brand_white);
              border-radius: 999px;
              border: 2px solid #fac7a6;

              & span {
                background-color: inherit;
                color: inherit;
              }

              &.rmdp-range.end .sd {
                background-color: var(--primary_40);
                color: var(--brand_white);
              }
            }
          }

          .rmdp-day span {
            text-align: center;
            font-family: 'Noto Sans';
            font-size: toRem(16px);
            font-style: normal;
            font-weight: 600;
            line-height: toRem(20px);
          }
          .rmdp-selected {
            > span {
              &:not(.highlight) {
                background-color: var(--primary_40);
              }
            }
          }
        }
      }
      .rmdp-header {
        width: 100%;
        color: var(--brand_black);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: toRem(20px);
        margin-top: 0;
        padding: 0 0 24px 0px;

        .rmdp-header-values {
          margin-left: 3px;
          display: flex;
          > span {
            padding: 0px;
          }
          > span:first-child,
          span:last-child {
            background-color: var(--primary_96);
            color: var(--primary_32);
            padding: 2px 10px 3px;
            border-radius: 4px;
          }
          > span:last-child {
            margin-left: 5px;
          }
        }
        .rmdp-arrow-container {
          .rmdp-arrow {
            width: 10px;
            height: 10px;
            flex-shrink: 0;
            border-color: var(--brand_primary);
          }
          &:hover {
            box-shadow: none;
            background-color: var(--brand_primary);
            .rmdp-arrow {
              border-color: var(--brand_white);
            }
          }
        }
        .disabled {
          .rmdp-arrow {
            border-color: var(--primary_80);
          }
          &:hover {
            border-color: var(--brand_white) !important;
            background-color: var(--brand_white) !important;
          }
        }
      }
      .rmdp-range {
        background-color: var(--primary_88);
        color: var(--brand_black);
        box-shadow: none;

        span {
          background-color: inherit;
          color: inherit;
        }
      }
      .rmdp-range.start .sd {
        background-color: var(--primary_40);
        color: var(--brand_white);
      }
      .rmdp-range.end .sd {
        background-color: var(--primary_40);
        color: var(--brand_white);
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    padding: 4px 16px;
  }

  .line-separator {
    background-color: var(--hGreyscale_88);
    height: 1px;
  }

  .time-container {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 12px 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    background: var(--brand_white);
    margin-bottom: 1px;
    cursor: pointer;
  }
  .custom-timepicker.rmdp-wrapper {
    margin-left: -16px;
    margin-top: -18px;
    width: 169.5%;
  }
  .custom-timepicker.rmdp-shadow {
    box-shadow: none;
  }
  .custom-timepicker.ep-arrow {
    visibility: hidden !important;
  }
  .rmdp-container {
    width: 100%;
    align-items: center;
  }
  .custom-timepicker.rmdp-wrapper,
  .rmdp-input {
    border: none;
    box-shadow: none;
    color: var(--greyscale_08);
    font-size: toRem(14px);
    font-style: normal;
    font-weight: 500;
    line-height: toRem(20px); /* 142.857% */
    // width: 169.5%;width
    height: 98%;
    caret-color: transparent;
    cursor: pointer;
  }
  .rmdp-time-picker.bottom {
    div {
      input {
        caret-color: auto;
      }
    }
  }
  // .custom-timepicker.rmdp-wrapper {
  //   border: 0.5px solid var(--greyscale_88);border
  //   border-radius: 12px;border-radius
  // }
}
