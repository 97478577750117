.cam {
  display: flex;
  flex-direction: column;
  z-index: 1;

  &-1x {
    width: 165px;
  }

  &-content {
    width: 100%;
    position: relative;
  }

  &-snapshot {
    // border: 2px solid transparent;
    border-radius: 8px;
    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
    // height: 96px;
    height: 93px;
    width: 165px;
    display: flex;
    align-items: center;
    background-color: var(--brand_black);

    &-image {
      border-radius: 8px;
      aspect-ratio: 16 / 9;
      object-fit: scale-down;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
    }

    &-wrapper {
      width: 100%;
      position: absolute;
      z-index: 5;
      top: 0;
    }
  }

  &-offline {
    background-color: var(--grayscale_32);
    border-radius: 8px;
    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 93px;
      background-color: var(--grayscale_16);
      color: var(--greyscale_56);
      box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
      border-radius: 8px;

      .camera-offline {
        &-icon {
          margin-top: 15%;
        }

        &-label {
          font-size: toRem(12px);
          color: var(--greyscale_56);
          margin-top: 4px;
        }
      }
    }
  }
}
