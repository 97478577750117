@import '../../../../assets/css/base.scss';

.detach-license-button-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;

  .detach-license-button {
    border-radius: 10px;
    margin-right: 8px;
    font-weight: 500;
    &:hover {
      background-color: var(--error_96);
      color: var(--error_48);
    }
  }

  .cancel-button {
    border-radius: 10px;
  }
}
