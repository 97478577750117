@import '../../assets/css/base';

.system-notification {
  width: 100%;

  .header-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .system-notification-label {
      font-style: normal;
      font-weight: 600;
      font-size: toRem(20px);
      line-height: 28px;
      display: flex;
      align-items: center;
      color: var(--text_color);
    }
  }

  .email-push-layout {
    flex-direction: row;
    justify-content: flex-end;
    display: flex;
    margin-right: 40px;

    .push {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--text_color);
    }

    .email {
      margin-right: 50px;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--text_color);
    }
  }

  .marketing-material {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    background: var(--grayscale_96);
    border-radius: 12px;
    height: 60px;

    .label-title {
      margin-left: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--text_color);

      @media screen and (max-width: 767px) {
        font-size: toRem(10px);
      }
    }

    .toggle-checkbox {
      margin-right: 25px;
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .toggle-email {
        margin-right: 30px;
      }
    }
  }

  .new-added-devices {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    background: var(--grayscale_96);
    border-radius: 12px;
    height: 60px;

    .label-title {
      margin-left: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--text_color);
      @media screen and (max-width: 767px) {
        font-size: toRem(10px);
      }
    }
    .toggle-checkbox {
      margin-right: 25px;
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .toggle-email {
        margin-right: 30px;
      }
    }
  }

  .new-available-features {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    background: var(--grayscale_96);
    border-radius: 12px;
    height: 60px;

    .label-title {
      margin-left: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--text_color);
      @media screen and (max-width: 767px) {
        font-size: toRem(10px);
      }
    }
    .toggle-checkbox {
      margin-right: 25px;
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .toggle-email {
        margin-right: 30px;
      }
    }
  }
}
