@import '../../assets/css/base';

.device-wall {
  .main-wrapper {
    background-color: var(--hGreyscale_08);
    height: calc(100vh - 72px);
    font-family: 'Noto Sans';
    overflow: hidden;

    .MuiBox-root {
      height: calc(100vh - 100px);
    }

    .cam-pointer {
      cursor: pointer;
    }

    .no-cameras {
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 232px);
      }
      &-icon-container {
        display: flex;
        width: 60px;
        height: 60px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--grayscale_16);
        margin-bottom: 24px;
      }
      &-notice {
        color: var(--primary_05);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 24px;
      }

      &-cta {
        .no-cameras-add-device-btn {
          padding: 8px 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 8px;
          color: var(--brand_white);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 133.333% */
          height: 32px;
          width: 128px;
          border: none;
          background-color: var(--primary_40);
          border-radius: 8px;
          white-space: nowrap;
          font-family: 'Noto Sans';
          text-transform: inherit;
        }
      }
    }
    .device-drawer-link {
      cursor: pointer;
      text-decoration: underline;
    }
    .device-wall-drawer {
      margin: 0 24px 0 0;

      .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorDockedLeft {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: var(--brand_white);
        background-color: var(--grayscale_24);
        border-radius: 8px;
        margin: 100px 24px 24px 24px;
        height: calc(100vh - 128px) !important;
        padding: 16px 8px;
        gap: 8px;
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--grayscale_24);
        min-height: 44px;

        &-title {
          color: var(--brand_white);
          font-size: toRem(20px);
          font-weight: 600;
          line-height: toRem(28px);

          &.hidden:has(
              .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium
            ) {
            padding-left: 4px;
          }
          &.disabled {
            color: var(--primary_05);
          }
        }

        &-toggle-icon {
          padding-left: 0;
        }

        .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
          padding-right: 0;
        }
      }

      &-toggle-icon {
        color: var(--brand_white);

        &.toggle-close {
          transform: rotate(90deg);
        }

        &.toggle-open {
          transform: rotate(270deg);
        }
      }

      &-list {
        padding: 0;

        &.view-list {
          overflow-y: auto;
          overflow-x: hidden;
          height: 100%;

          &::-webkit-scrollbar {
            width: 5px;
            margin-right: -4px;
          }
        }

        li {
          display: flex;
          justify-content: space-around;
          margin-top: 8px;
          &:first-child {
            margin-top: 0px;
          }

          .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-root {
            padding: 8px 8px 8px 16px;

            .MuiListItemIcon-root {
              margin-right: 0;
            }

            .list-item-span {
              width: 80%;
              .MuiTypography-root {
                font-size: toRem(16px);
                line-height: toRem(20px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 150px;
              }
            }

            .list-item-default-view-indicator {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: 12px;

              .default-view-flag {
                display: none;
                &.on,
                &:hover {
                  display: block;
                }
              }
            }
          }
        }

        &-item {
          &-button {
            padding-left: 11px !important;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;

            &:focus,
            &:focus-within,
            &:active {
              background: var(--primary_03);
            }
            &.active {
              background: var(--primary_03);
            }

            &:hover {
              background: var(--primary_01);
            }

            &.drag-over {
              background: var(--primary_08);
            }

            &.disabled {
              color: var(--primary_05);
              pointer-events: none;
            }
          }

          &-not-active-button {
            &:focus,
            &:focus-within,
            &:active {
              background: transparent;
            }
            &.active {
              background: transparent;
            }
          }

          &-btn {
            border-radius: 8px;
            background: var(--primary_08);
            margin-top: 4px;
            margin-bottom: 4px;
            padding: 8px 16px !important;
            height: 38px;

            .MuiListItemIcon-root {
            }
          }

          &.active-list-item {
            border-left: 4px solid var(--primary_64);
            margin-left: -8px;
            padding-left: 4px;
          }

          &-notifications {
            display: flex;
            height: 16px;
            min-width: 16px;
            padding: 0px 2px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            background: var(--primary_64);

            color: var(--brand_white);
            text-align: center;
            font-size: toRem(12px);
            font-weight: 400;
            line-height: toRem(16px);
          }
        }
      }

      &-divider {
        border: 1px solid var(--primary_01);
        margin-top: 12px;
        margin-bottom: 12px;
      }

      &-add-view-form {
        & .form-control:focus,
        .form-control:active,
        .form-control:hover {
          box-shadow: unset;
          border-color: var(--primary_02);
        }
        .form-control.is-invalid:focus,
        .form-control.is-invalid:active,
        .form-control.is-invalid:hover {
          border-color: var(--bs-form-invalid-border-color);
        }

        & input.form-control {
          height: 36px;
          border-radius: 0.5rem;
          background-color: var(--primary_04);
          padding: 12px 60px 14px 16px;
          color: var(--brand_white);
          text-indent: 2px;
        }

        & .placeholder-wrapper {
          height: 36px;
          padding: 8px 8px 8px 16px;
          left: 1rem;
        }

        & .icon-after-input {
          height: 36px;

          & .icon-container {
            display: flex;
            align-items: center;
          }

          & .cross-icon {
            cursor: pointer;

            & path {
              stroke: var(--brand_white);
            }
          }

          & .checked-icon {
            margin-left: 0.25rem;
            cursor: pointer;

            & rect {
              fill: var(--primary_40);
            }
            & path {
              fill: var(--primary_40) !important;
              stroke: var(--brand_white);
            }
          }
        }
      }

      &-new-view {
        &-btn {
          display: flex;
          //   justify-content: space-around;
          align-items: center;
          border-radius: 8px;
          background: var(--primary_40);
          height: 38px;
          padding: 8px;
          width: 100%;

          &.hidden {
            display: none !important;
          }

          &:hover {
            background: var(--primary_64);
          }

          &:focus,
          &:active {
            outline: 1px solid var(--brand_white);
            background: var(--primary_40);
          }

          &.drag-over {
            outline: 1px dashed var(--primary_64);
            background: var(--primary_08);
          }

          &.disabled {
            background: var(--primary_01);
          }

          &-label {
            overflow: hidden;
            color: var(--brand_white);
            text-overflow: ellipsis;
            font-size: toRem(14px);
            font-weight: 400;
            line-height: toRem(20px);
            text-transform: initial;
            font-family: 'Noto Sans';

            &-plus {
              color: var(--brand_white);
              font-size: 1.25rem;
              line-height: toRem(22px);
              margin-top: -2px;
              margin-right: 12px;
              &.disabled {
                color: var(--primary_05);
              }
            }
            &.disabled {
              color: var(--primary_05);
            }
          }
        }

        &-field {
          .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
            &:hover {
              outline: 0;
              border-radius: 8px;
              border-color: var(--greyscale_64);
              border-width: 0;
            }

            input {
              display: flex;
              padding: 8px 8px 8px 16px;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              align-self: stretch;
              border-radius: 8px;
              border: 1px solid var(--greyscale_64);
              color: var(--brand_white);
              background: var(--primary_04);
              outline: 0;

              &:active,
              &:hover {
                border: 1px solid var(--greyscale_64);
                outline: 0;
              }
            }
          }
        }

        &-clear-icon {
          color: var(--primary_01);
        }

        &-check-icon {
          color: var(--primary_40);
        }
      }

      &-section {
        &-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 8px;
          padding: 8px 12px;

          &.active-list-item {
            border-left: 4px solid var(--primary_64);
            margin-left: -8px;
            padding-left: 16px;
          }
        }

        &-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 8px;
          margin-right: 8px;

          &.hidden {
            display: none;
          }
          &.disabled {
            color: var(--primary_05);
          }
        }
      }

      &-help {
        &-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-self: flex-end;
          width: 100%;

          &.icon-only {
            height: 100%;
          }
        }

        &-section {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
        }

        &-icon {
          margin-right: 8px;
          color: var(--greyscale_80);
        }

        &-text {
          color: var(--greyscale_80);
          font-size: toRem(16px);
          font-weight: 400;
          line-height: toRem(20px);
        }
      }
    }
    .popup-opacity {
      opacity: 0.7;
    }
    .device-wall-main-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 28px 24px 0px 24px;
      color: var(--brand_white);
      // height: calc(100vh - 76px);
      height: 100%;

      &.fullscreen-mode {
        height: 100vh;
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page-title {
          display: block;
          text-align: left;
          color: var(--brand_white);
          font-size: toRem(24px);
          font-style: normal;
          font-weight: 700;
          line-height: toRem(32px);
        }

        .default-view-title {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }

        .device-wall-drawer-edit-view-btn {
          padding: 8px 16px;
          border-radius: 8px !important;
          white-space: nowrap;
        }

        .edit-view-loader {
          width: 20px;
          height: 20px;
        }

        .flush-right {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .page-monitor-container {
            margin-right: 15px;
          }
        }

        .page-monitor-container {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        .page-monitor {
          border-radius: 8px;
          border: 1.5px solid var(--primary_64);
          color: var(--brand_white);
          font-size: toRem(12px);
          font-weight: 600;
          line-height: toRem(16px);
          padding: 8px;
          display: flex;
          gap: 8px;
        }
      }

      &-content {
        &-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 12px 0;
        }

        &-search {
          // This border must be removed when the search component is implemented
          border: 1px dashed var(--primary_04);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 50%;
        }

        &-filters {
          // This border must be removed when the search component is implemented
          border: 1px dashed var(--primary_04);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 50%;
        }
      }
      .no-cameras {
        &-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 232px);
        }
        &-icon-container {
          display: flex;
          width: 60px;
          height: 60px;
          padding: 4px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          background: var(--grayscale_16);
          margin-bottom: 24px;
        }
        &-notice {
          color: var(--primary_05);
          font-size: toRem(14px);
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 24px;
        }

        &-cta {
          .no-cameras-edit-view-btn {
            padding: 8px 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: var(--brand_white);
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px; /* 133.333% */
            height: 32px;
            width: 128px;
            border: none;
            background-color: var(--primary_40);
            border-radius: 8px;
            white-space: nowrap;
            text-transform: inherit;
          }
        }
      }
      .edit-view-dropdown {
        &:focus-visible {
          outline: unset;
        }

        .dropdown-item {
          color: var(--hGrayscale_96);
          font-size: toRem(14px);
          font-weight: 400;
          line-height: toRem(20px);
          padding: 8px 0px 8px 16px;
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          width: 172px;

          .active,
          &:active,
          &:focus,
          &:hover {
            background: var(--primary_01);
          }
          &:first-child {
            border-radius: 8px 8px 0px 0px;
            padding-top: 16px;
          }
          &:last-child {
            border-radius: 0px 0px 8px 8px;
            padding-bottom: 16px;
          }
          &.disabled {
            color: var(--primary_05);
          }
          &-icon {
            margin-right: 5px;
          }
          &-label {
            &.red {
              color: var(--error_64);
            }
          }
        }

        .dropdown-menu {
          background: var(--grayscale_24);
          box-shadow:
            0px 4px 6px -2px rgba(var(--greyscale-custme7)),
            0px 10px 15px -3px rgba(var(--greyscale-custme8));
          border: 0;
          z-index: 2000;
          width: 172px;
        }

        .dropdown-toggle::after {
          display: none;
        }
      }
      .spinner-container {
        width: 100%;
      }
    }
  }
}

.camera-wall-wrapper {
  width: -webkit-fill-available;
  // height: calc(100% - 120px);
  height: 100%;

  .stream-timeline-wrapper {
    aspect-ratio: 16 / 9;
    width: 100%;
    position: relative;
    background: var(--hGrayscale_16);
    border-radius: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 315px);
    overflow: hidden;
    &.device-setting {
      overflow: hidden;
      pointer-events: none;
    }
    &.multi-stream-wrapper {
      background: none;
      max-height: calc(100vh - 290px);
    }
    &.image-included {
      aspect-ratio: unset;
    }
    .events-icons-wrapper {
      position: absolute;
      z-index: 1;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      display: flex;
      top: 50%;
      margin-top: -24px;
      .icons-image {
        width: 48px;
        height: 48px;
        background: rgba(var(--greyscale-custom1), 0.4);
        backdrop-filter: blur(5px);
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.next-icon {
          margin-right: 16px;
        }
        &.previous-icon {
          margin-left: 16px;
        }
      }
    }
    &.timeline-controls-page {
      aspect-ratio: auto;
      margin-bottom: 0px;
      position: fixed;
      width: -webkit-fill-available;
      margin-right: 24px;
      bottom: 26px;

      &.search-page-event {
        margin-top: -110px;
        max-width: calc(100% - 16px);
      }
    }
    .video-processing {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .previous-block,
    .next-block {
      width: 49%;
      height: 65%;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      top: 15%;
    }
    .previous-block {
      left: 0;
    }
    .next-block {
      right: 0;
    }
    .block-hide-panel {
      width: 100%;
      height: 65%;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      top: 15%;
    }
    .timeline-icons {
      position: absolute;
      top: 16px;
      left: 26px;
      background-color: rgba(var(--greyscale-custom1), 0.4);
      backdrop-filter: blur(5px);
      border-radius: 12px;
      padding: 16px;
      display: flex;
      align-items: center;
      z-index: 2;
      &.hide-controls {
        visibility: hidden;
      }
      button,
      a {
        margin-right: 23px;
        cursor: pointer;
        display: flex;
        background: none;
        border: none;
        padding: 0;
        &:last-child {
          margin-right: 0;
        }
        &:active,
        &:focus,
        &:hover {
          background: none;
          border: none;
          padding: 0;
        }
      }
      .mins,
      .mins:hover,
      .mins:focus {
        background: rgba(var(--greyscale-custom3), 0.2);
        border-radius: 12px;
        padding: 4px 8px;
        color: var(--brand_white);
        font-size: toRem(12px);
        line-height: 16px;
      }
      &.live {
        bottom: 16px;
        top: auto;
        left: 50%;
        right: auto;
        margin-left: -40px;
        padding: 0;
        background: rgba(var(--greyscale-custom1), 0.4);
        .live-button {
          color: var(--brand_white);
          padding: 11px 18px;
          font-size: toRem(14px);
          line-height: 20px;
          font-weight: 600;
          display: inline-block;
          text-decoration: none;
          &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: var(--error_64);
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
      &.golive {
        right: 88px;
        top: 24px;
        left: auto;
        background: rgba(var(--error80-custom1), 0.2);
        padding: 0;
        display: none;
        &.live_btn_visible {
          display: block;
          right: auto;
          left: 50%;
          margin-left: -50px;
          top: auto;
          bottom: 20px;
        }
        .golive-button {
          color: var(--brand_white);
          padding: 11px 18px;
          font-size: toRem(14px);
          line-height: 20px;
          font-weight: 600;
          text-decoration: none;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: var(--error_64);
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
      &.cross {
        right: 16px;
        left: auto;
      }
      &.live-stream {
        a {
          padding: 16px;
          &:hover {
            padding: 16px;
          }
        }
      }
    }
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
    }
    .scrubber-image-view {
      position: absolute;
      width: 100%;
      text-align: center;
      color: var(--brand_white);
      top: 0;
      display: flex;
      justify-content: center;
      height: 100%;
      .active-image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 100%;
        overflow: hidden;

        .add-to-incident-wrapper {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          position: absolute;
          z-index: 100;
          top: 14px;
          left: 18px;

          .back-to-live {
            display: flex;
            padding: 16px;
            align-items: center;
            gap: 239px;
            border-radius: 12px;
            background: rgba(var(--greyscale-custom1), 0.4);
            backdrop-filter: blur(4px);
          }

          .add-to-incident {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            padding: 16px;
            border-radius: 12px;
            background: rgba(var(--greyscale-custom1), 0.4);
            /* Background Overlay */
            backdrop-filter: blur(4px);

            &-left-arrow {
              height: 24px;
              width: 24px;
            }

            &-folder {
              height: 24px;
              width: 24px;
            }

            &-label {
              margin-left: -8px;
              color: var(--brand_white);
              font-size: toRem(16px);
              font-weight: 600;
              line-height: toRem(20px);
            }
          }
        }

        .image-wrapper {
          height: 100%;
          width: 100%;
        }

        &.novideo {
          img {
            width: auto;
          }
        }

        img {
          max-width: 100%;
          height: 100%;
          max-height: 100%;
          object-fit: contain;
          aspect-ratio: 16/9;
          &.live-snapshot {
            object-fit: fill;

            &.square-image {
              aspect-ratio: 1/1;
            }
          }
        }
      }
      h2 {
        display: inline-block;
        background: rgba(var(--greyscale-custom1), 0.4);
        margin: 0;
        position: absolute;
        top: 80px;
      }
    }
    &.liveview {
      + .timeline-controls-page {
        .scrubber {
          .vis-timeline:after {
            background-color: var(--error_64);
          }
        }
      }
    }
  }

  .scrubber-wrapper {
    padding: 0;
    bottom: 16px;
    left: 16px;
    width: calc(100%);
    backdrop-filter: blur(2px);
    min-height: 100px;
    border-radius: 4px;
    color: var(--brand_white);

    .clipper-controls-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 4px;
      .clipper {
        &-active-time {
          margin: 15px 32px 5px 15px;
        }
        &-bubble {
          display: inline-flex;
          height: 25px;
          padding: 3px 12px 2px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex-shrink: 0;
          border-radius: 12px;
          background: rgba(var(--greyscale-custom3), 0.2);
          backdrop-filter: blur(2px);
          color: var(--brand_white);
          text-align: center;
          font-size: toRem(12px);
          font-weight: 600;
          line-height: toRem(16px);
          margin: 11px 15px 2px;
        }
        &-duration {
        }
        &-action {
        }
      }
    }

    .timelines-controls-wrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 14px;
      height: 42px;
      > div {
        width: 33.3%;
        &.live {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
        &:first-child {
          text-align: left;
          padding-left: 10px;
        }
        &:last-child {
          text-align: right;
          padding-right: 12px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      .timeline-controls-icons {
        display: flex;
        flex-direction: row;
        .icons-image {
          display: inline-block;
          padding: 0 7px;
          cursor: pointer;

          &-folder-playing,
          &-folder-paused {
            height: 16px;
            width: 16px;
          }
        }
        .mins,
        .mins:hover,
        .mins:focus {
          background: rgba(var(--greyscale-custom3), 0.2);
          border-radius: 12px;
          padding: 4px 8px;
          color: var(--brand_white);
          font-weight: 600;
          font-size: toRem(12px);
          line-height: 16px;
          border: 0;
          margin-left: 7px;

          &.no-pointer {
            cursor: none;
            pointer-events: none;
          }
        }

        .sync-command {
          cursor: pointer;
          background: rgba(var(--greyscale-custom3), 0.2);
          border-radius: 12px;
          padding: 4px 8px;
          color: var(--brand_white);
          font-size: toRem(12px);
          line-height: 16px;
          margin-left: 12px;

          & .toggle-field-container {
            align-items: center;
            gap: 0.25rem;

            & .toggle-switch {
              margin-top: 0px !important;
            }
          }
        }
      }
      .golive-view button,
      .golive-view button:hover,
      .golive-view button:focus {
        background: rgba(var(--error64-custom1), 0.2);
        backdrop-filter: blur(5px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 8px;
        padding: 4px 8px;
        color: var(--brand_white);
        font-size: toRem(14px);
        line-height: 20px;
        border: 0;
        margin-right: 8px;
        &::before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: var(--error_64);
          border-radius: 50%;
          margin-right: 8px;
        }
      }
      .golive-width {
        width: 33.33%;
      }
      .screen-size {
        padding: 7px;
      }
      .speed,
      .speed:hover,
      .speed:focus {
        padding: 4px 8px;
        color: var(--brand_white);
        font-size: toRem(12px);
        line-height: 16px;
        margin-left: 8px;
        border-radius: 8px;
        border: 1px solid rgba(var(--greyscale-custom3), 0.1);
        background: inherit;
      }
      .speed-disabled,
      .speed-disabled:hover,
      .speed-disabled:focus {
        color: var(--greyscale_48);
      }
      .settings-icons-image {
        padding: 7px;
        margin: 0px 8px;
      }
      .live {
        &::before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: var(--error_64);
          border-radius: 50%;
          margin-right: 8px;
        }
        .filter-date-image {
          margin-left: 8px;
          cursor: pointer;
        }
      }
      .date {
        color: var(--brand_white);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        display: flex;
        align-items: center;
        white-space: nowrap;
        .filter-date-image {
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
    &.filter-date-image {
      margin-left: 8px;
    }
    &.live_btn_visible {
      .timeline-icons.golive {
        display: inline-block;
      }
    }
    &.visiblity-hidden {
      visibility: hidden;
    }
    .active_time {
      line-height: 16px;
      font-size: toRem(14px);
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      &.visiblity-hidden {
        visibility: hidden !important;
      }
      .icons-image {
        padding: 7px;
        margin-left: 8px;
      }
      .speed,
      .speed:hover,
      .speed:focus {
        padding: 4px 8px;
        color: var(--brand_white);
        font-size: toRem(12px);
        line-height: 16px;
        margin-left: 8px;
        border-radius: 8px;
        border: 1px solid rgba(var(--greyscale-custom3), 0.1);
        background: inherit;
      }
      .speed-disabled,
      .speed-disabled:hover,
      .speed-disabled:focus {
        color: var(--greyscale_48);
      }
      .time {
        padding-right: 12px;
      }
    }
    .scrubber {
      position: relative;

      &.visiblity-hidden {
        .vis-timeline {
          visibility: hidden !important;
        }
      }

      &.midpoint-hidden {
        .vis-timeline:after {
          display: none;
        }
      }

      .vis-timeline:after {
        content: '';
        width: 2px;
        background-color: var(--warning_80);
        position: absolute;
        left: 50%;
        height: 34px;
        bottom: 0;
        margin-left: -1px;
      }
      .vis-foreground {
        .vis-group {
          height: 32px !important;
          cursor: move;
        }
      }
      .vis-panel {
        &.vis-background {
          .vis-axis {
            .vis-group {
              display: none;
            }
          }
        }
        .vis-content {
          .vis-background {
            .vis-group {
              display: none;
            }
          }
          .vis-item {
            &.vis-range {
              box-sizing: border-box;
              top: 0 !important;
              height: 32px;
              border: 0;
              border-radius: 0;
              background-color: rgba(var(--greyscale-custom3), 0.1);
            }
          }
        }
      }
      padding: 0 16px 4px;
      .vis-item {
        .vis-item-content {
          padding: 0;
        }
      }
      .vis-item.vis-box {
        border-radius: 50%;
        border-color: var(--brand_white);
        background-color: var(--brand_white);
        top: 15px !important;
        margin-top: -3px;
      }
      .vis-itemset {
        height: 32px !important;
        .vis-item {
          &.vis-point {
            top: 8px !important;
            margin-left: 0;
            .vis-item {
              border-color: var(--brand_white);
              background-color: var(--brand_white);
              &.vis-dot {
                border-width: 4px;
                border-radius: 4px;
                top: 2.5px !important;
              }
            }
          }
        }
      }
      .vis-timeline {
        border: 0;
      }
      .vis-time-axis {
        .vis-text {
          color: rgba(var(--greyscale-custom3), 0.4);
          &.vis-major {
            margin-top: 0;
            margin-left: -18px;
            font-size: toRem(11px);
            line-height: 13px;
          }
        }
        .vis-grid.vis-minor {
          border-image-source: linear-gradient(
            180deg,
            rgba(var(--greyscale-custom3), 0) 0%,
            #ffffff 49.81%,
            rgba(var(--greyscale-custom3), 0) 100%
          );
          border-image-slice: 1;
          border-width: 1px;
          opacity: 0.6;
          height: 24px !important;
          // TODO: Watching for some time
          // top: 23px !important;
        }
        .vis-grid.vis-major {
          border-image-source: linear-gradient(
            180deg,
            rgba(var(--greyscale-custom3), 0) 0%,
            #ffffff 49.81%,
            rgba(var(--greyscale-custom3), 0) 100%
          );
          border-image-slice: 1;
          opacity: 0.6;
          border-color: var(--axis_border_timeline);
          max-height: 32px;
          top: auto !important;
          bottom: 0;
        }
      }
      .vis-panel.vis-bottom,
      .vis-panel.vis-center,
      .vis-panel.vis-left,
      .vis-panel.vis-right,
      .vis-panel.vis-top {
        border: 0;
      }
    }
    .clipper {
      position: relative;
      // width: calc(100% - 34px);
      width: calc(100% - 24px);
      height: 30px;
      margin: -35px auto auto;

      .clipper-resizable-container {
        position: absolute;
        z-index: 900;

        .clipper-resizable-marker {
          border-top-width: 2px;
          border-bottom-width: 2px;
          border-left-width: 10px;
          border-right-width: 10px;
          border-radius: 2px 0px 0px 2px;
          border-color: var(--brand_white);
          border-style: solid;
          height: 30px;
          position: relative;
          filter: drop-shadow(0px 2px 4px rgba(var(--greyscale-custom1), 0.25));
          &.highlight {
            border-color: var(--primary_48);
            &.engage-left::before {
              content: '';
              position: absolute;
              top: -2px;
              left: -12px;
              text-align: left;
              border-color: var(--primary_48);
              border-top-width: 2px;
              border-bottom-width: 2px;
              border-left-width: 10px;
              border-style: solid;
              height: 30px;
            }
            &.engage-right::after {
              content: '';
              position: absolute;
              top: -2px;
              right: -12px;
              text-align: right;
              border-color: var(--primary_48);
              border-top-width: 4px;
              border-bottom-width: 4px;
              border-right-width: 10px;
              border-style: solid;
              height: 30px;
            }
          }
        }

        .clipper-handle {
          div:first-child {
            margin-left: -4px;
          }

          .three-dots {
            color: var(--greyscale_88);
            margin-top: 4px;

            &.left-handle {
              margin-left: 2px;
            }

            &.right-handle {
              margin-left: -8.5px;
            }
          }
        }
      }
    }
  }
}

.heading-h5-bold {
  font-family: 'Noto Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.subheading-16px {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding-top: 10px;
}

.device-license-expire-style {
  .modal-content {
    min-width: 424px;
    min-height: 468px;
    .modal-header {
      display: none;
    }
  }
}

.button-style {
  margin-top: 40px !important;
}
.button-dismiss-style {
  margin-bottom: -21px !important;
}
