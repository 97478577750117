@import '../../assets/css/base.scss';

.live-view {
  .main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--grayscale_16);
    align-items: center;
    min-height: calc(100vh - 72px);
    overflow-y: auto;

    .video-wall-background {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      max-width: 1800px;
      margin-left: 0;
      margin-right: 0;

      .toast-wrapper {
        display: inline-flex;
        justify-content: space-between;
        text-align: left;
        width: 100vw;
      }

      .page-title {
        color: var(--brand_white);
        font-size: toRem(24px);
      }

      .device-title {
        color: var(--brand_white);
        font-size: toRem(24px);
      }

      .device-sub-title {
        color: var(--greyscale_80);
        font-size: toRem(12px);
      }

      .button-nav {
        display: inline-flex;
        justify-content: flex-end;

        .button-nav-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--brand_white);
          background-color: var(--grayscale_24);
          border: 1.5px solid var(--grayscale_32);
          font-size: toRem(14px);
        }

        a.button-nav-item {
          display: flex;
          height: 44px;
          border-radius: 12px;
          align-items: center;
          padding: 6px 14px;
          text-decoration: none;

          svg {
            margin-left: 12px;
          }
        }

        .dropdown {
          &-menu {
            margin-top: 6px;
            min-width: 234px;
          }

          &-item {
            color: var(--greyscale_08);
            font-size: toRem(14px);

            &-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }

          &-item-text {
            color: var(--greyscale_40);
            font-size: toRem(12px);
          }
        }

        .edit {
          &-button {
            margin-right: 14px;
          }
          &-icon {
            margin-right: 4.2px;
          }
        }
      }
      .multilive-container {
        .device-name,
        .detail-camera {
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          position: absolute;
          border-radius: 8px;
          background: rgba(var(--greyscale-custom1), 0.4);
          backdrop-filter: blur(5px);
          padding: 8px;
          display: inline-block;
          color: #fff;
          top: 8px;
          left: 16px;
          max-width: 60%;
          z-index: 1;
          display: none;
          &.hovered {
            display: inline-block;
          }
        }
        .detail-camera {
          left: auto;
          right: 16px;
        }
        .videoprocesing-image-loader {
          max-width: 100%;

          img {
            width: 100%;
          }
        }
        .wrapper-app .stream-timeline-wrapper.multiview-timeline {
          position: fixed;
          bottom: 16px;
          left: 16px;
          width: calc(100% - 32px);
          // opacity: 0;
        }
        .fullscreen {
          &.fullscreen-enabled {
            .container {
              &.multiviewcontrols {
                width: 100%;
                max-width: 100%;
                .grid-item {
                  pointer-events: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
