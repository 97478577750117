@import '../../assets/css/base.scss';

.access-clip {
  background-color: #f3f5f7;

  &-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 72px);
  }

  &-card {
    border-radius: 16px;
    background: var(--brand_white);
    max-width: 630px;

    &-password-error-message {
      color: var(--error_48);
      margin-top: 24px;
      font-size: toRem(14px);
      font-weight: 400;
      line-height: toRem(20px);

      .icon-exclamation {
        color: var(--error_48);
        margin-right: 10px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-title {
      color: var(--primary_08);
      text-align: center;
      font-size: toRem(32px);
      font-weight: 600;
      line-height: toRem(40px);
    }

    &-message {
      color: var(--greyscal_40);
      text-align: center;
      font-size: toRem(14px);
      font-weight: 500;
      line-height: toRem(20px);
      margin-top: 8px;
    }

    &-alert-icon {
      background-color: #edabab;
      border-radius: 50%;
      height: 72px;
      width: 72px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 16px;
    }

    &-error-title {
      color: var(--greyscale_08);
      text-align: center;
      font-size: toRem(32px);
      font-weight: 600;
      line-height: toRem(40px);
      margin-bottom: 8px;
    }

    &-error-message {
      color: var(--greyscale_40);
      text-align: center;
      font-size: toRem(14px);
      font-weight: 400;
      line-height: toRem(20px);
    }

    &-input-wrapper {
      margin-top: 24px;

      .password-input-field {
        border-radius: 12px;
        border: 1.5px solid var(--greyscale_88);
        padding: 0px 20px;

        &.Mui-error {
          background-color: var(--greyscale_96);
          color: var(--error_64);

          .icon-lock {
            color: var(--error_64) !important;
          }

          .icon-eye {
            color: var(--error_64) !important;
          }
        }

        &:active,
        &:focus {
          border-width: 1.5px;
        }
      }

      .icon-lock {
        color: var (--greyscal_40) !important;
        margin-right: 8px;
      }

      .icon-eye {
        cursor: pointer;
        color: var (--greyscal_40) !important;
      }
    }

    &-button-wrapper {
      margin-top: 24px;
    }
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiCard-root.access-clip-card {
    display: flex;
    max-width: 629px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: var(--brand_white);
    box-shadow: none;
  }
}

.video-clip-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  height: 100%;
  width: 100%;
  background-color: var(--greyscale_08);

  video {
    max-height: calc(100vh - 40px);
    width: 100%;
  }
}
