@import '../../assets/css/base';

.purchase-summary {
  &-block {
    padding-top: 28px;
  }

  &-section {
    text-align: left;
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &-description {
    color: var(--greyscale_56);
    line-height: 20px;
    font-size: 0.875rem;
  }

  &-payments {
    margin-top: 10px;
  }

  &-item {
    padding-top: 28px;

    &-product-name {
      text-align: left;
      padding-left: 28px !important;
      width: 70%;
    }

    &-quantity {
      margin-left: 50px;

      &-price {
        width: 30%;
      }
    }

    &-amount {
      display: flex;
      justify-content: flex-end;
      padding-right: 28px !important;
    }

    &-label {
      color: var(--greyscale_56);
      font-size: toRem(14px);
      font-weight: 500;
      margin-bottom: 4px;
    }
  }

  &-card {
    background-color: var(--grayscale_96) !important;
    border: 0 !important;
    border-radius: 12px !important;
    min-height: 140px;
    text-align: left;
    margin-bottom: 0.5rem;

    &-body {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      position: relative;
      padding: 30px;

      &-wrapper {
        display: flex;
        min-height: 140px;
        width: 100%;
      }

      &-product {
        width: 60%;
        padding-right: 20px;

        &-title {
          color: var(--greyscale_08);
          font-size: toRem(14px) !important;
          font-weight: 500 !important;
        }

        &-description {
          &-features {
            text-align: left;
            display: flex-start;

            &-list {
              text-align: left;
              list-style: none;
              margin-bottom: 0;
              margin-left: 0;
              padding-left: 0;
            }
          }

          &-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 100%;
          }
        }
      }

      &-quantity-price {
        display: flex;
        width: 40%;
        justify-content: space-between;
        margin-left: 10px;

        .link {
          font-weight: 500;
        }
      }

      &-quantity-modifier {
        margin-right: 10px;
      }

      &-quantity-price {
        font-weight: 600;
        margin-top: 5px;

        &-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          text-align: right;
        }
      }

      &-sku,
      &-feature {
        color: var(--greyscale_56);
        font-size: toRem(12px);
      }
    }

    &-wrapper {
      padding-bottom: 20px;
      text-align: center;
      padding: 4px;
    }
  }

  &-subtotal-total {
    &-card {
      background-color: var(--grayscale_96) !important;
      border: 0 !important;
      border-radius: 12px !important;
      text-align: left !important;

      &-body {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        position: relative;
        padding: 1.15rem;

        &-section {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.525rem;
        }

        &-tax-section,
        &-total-section {
          display: flex;
          justify-content: space-between;
        }

        &-subtotal,
        &-subtotal-amount,
        &-tax,
        &-invoice {
          font-size: toRem(14px);
          font-weight: 500;
          color: var(--greyscale_08);
        }

        &-total,
        &-total-amount {
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
    }

    &-wrapper {
      margin-top: 6px;
    }
  }

  .link {
    color: var(--primary_40);
    cursor: pointer;
    font-size: 0.875rem;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }

  .feature-check-circle {
    color: var(--success_48);
    background-color: var(--brand_white);
    font-size: toRem(18px);
    margin-right: 10px;
  }

  .invoice-icon,
  .credit-card-icon {
    margin-right: 0px;
    margin-top: -3px;
    font-size: 1.2rem;
  }

  .term-button {
    font-size: toRem(14px);
    width: 110px;
    color: var(--greyscale_56);
    background-color: var(--grayscale_96);
    border: 0;

    &.active {
      color: var(--brand_white);
      background-color: var(--primary_40);
    }
  }

  .form-inline {
    width: 50%;
  }

  .form-group {
    width: 50%;
  }

  .input-group {
    width: 50% !important;
    height: 32px;
    flex-wrap: nowrap;
    text-align: center;
  }

  .form-control {
    border: 1.5px solid var(--primary_40);
    width: 50%;
    height: 32px;
    font-size: toRem(14px);
    text-align: center;
  }

  span.input-group-addon {
    width: 50px !important;
  }

  .invoice-btn,
  .credit-card-btn {
    padding: 10px 16px;

    &.disabled {
      color: var(--grayscale_32);
      background-color: var(--greyscale_88);
      outline: 0;
      border: 0;
    }

    .purchase-button {
      margin-left: 13px;
    }
  }

  .btn-outline-primary.disabled {
    color: var(--greyscale_72);
    border: 2px solid var(--greyscale_88);
    background-color: transparent;
  }

  .invoice-btn {
    margin-right: 1rem;
  }

  .purchase-summary {
    .quantity-form-control[readonly] {
      &.form-control {
        background-color: var(--brand_white);
        height: 32px;
        min-width: 62px;
        padding: 4px;
      }
    }

    .decrement-btn,
    .increment-btn {
      line-height: 0.5rem;
      height: 32px;
      padding: 0.5rem;
    }

    .decrement-btn {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .increment-btn {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
