@import '../../assets/css/base';

.analytics {
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;

  .main-wrapper {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
  
      .main-wrapper::-webkit-scrollbar {
        display: none;
      }
    .analytics-header {
      display: flex;
      background-color: var(--brand_white);
      align-items: center;
      height: 80px;

      .toast-wrapper,
      .header-wrapper {
        display: inline-flex;
        justify-content: space-between;
        text-align: left;
        width: 100vw;
      }

      .header-wrapper {
        padding-left: 0;
        padding-right: 0;

        .page-title {
          color: var(--brand_black);
          font-size: toRem(24px);
          font-weight: 700;
          padding-left: 0;
        }
      }
    }

    .analytics-background {
      display: flex;
      flex-direction: column;
      background-color: var(--grayscale_96);
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 30px;

      .analytics-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: var(--grayscale_96);
        margin-top: 24px;
        &.details{
          margin-top: 0px; 
        }
        // padding: 16px 30px;

        .analytics-row {
          //   padding: auto 30px;
          margin-bottom: 30px;
          width: 100%;

          &:last-child {
            margin-bottom: 0;
          }
          &.user-login {
            padding-bottom: 50px;
          }
          &.view-details{
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.right-aligned-dropdown-menu {
  right: 15px !important;
}

.dropdown-cat-options {
  &.dropdown-menu.show {
    width: 226px;
    padding: 16px;
    box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
      0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
    border-radius: 12px;
    border: none;

    & .option-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      margin-left: 35px;
      &:first-child {
        margin-left: 0px;
      }

      & .left-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: var(--text_color);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;

        .option-title {
          margin-left: 10px;
        }
      }

      & .right-section {
        background-color: var(--brand_white);
        border: 1px solid var(--text_disable_color);
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 4px;
        display: flex;
        cursor: pointer;

        & .icon-image {
          height: 14px;
          width: 14px;
        }

        &.selected-tick {
          background-color: var(--primary_40);
          border: none;
        }
      }
    }
  }
}

.analytics-button-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  & .filter-button {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 8px 16px;
    border: 1.5px solid var(--greyscale_88);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;

    & .filter-down-arrow {
      margin-left: 10px;
    }
  }
}

.camera-reports {
  .modal-dialog.modal-md.modal-dialog-centered.modal-fullscreen {
    .modal-content {
      background-color: var(--grayscale_96);
      border-radius: 0px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
      height: 100% !important;
      padding: 0px !important;

      .modal-body {
        max-height: 100% !important;
      }
      .modal-header{
        .site-modal-close{
          margin-right: 5px;

        }
      }
    }
  }
}
