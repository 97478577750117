@import '../../assets/css/base';

html:has(.camera-wall-devices) {
  background-color: var(--grayscale_16);
}

.camera-wall-devices {
  margin-bottom: 0 !important;
  display: flex;

  .main-page-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .header-section{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    
    .page-title {
      color: var(--brand_white);
      font-size: toRem(24px);
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
    .filter-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .search-all-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 10px;
      height: 100%;
      outline: none;
      min-width: 40%;
      outline: none;
      position: relative;

      & .search-camera-icon {
        z-index: 9;
        width: 20px;
        height: 20px;
        position: absolute;
        margin-left: 16px;
      }
      & .search-camera-input {
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border: none;
        color: var(--brand_white);
        width: 100%;
        height: 44px;
        padding: 16px 44px 16px 44px;
        position: relative;
        border-radius: 10px;
        background: var(--primary_04);
        outline: none;

        &:hover {
          border: none;
          background: var(--primary_04);
          color: var(--brand_white);
        }
        &:focus {
          border: none;
          background: var(--primary_04);
          color: var(--brand_white);
          border: 1px solid var(--brand_white);
        }
      }
      & .search-close {
        width: 16px;
        height: 16px;
        position: absolute;
        cursor: pointer;
        right: 16px;
      }
      input::placeholder {
        color: var(--brand_white);
        opacity: 1; /* Firefox */
      }
      input::-ms-input-placeholder { /* Edge 12 -18 */
        color: var(--brand_white);
      }
      input:focus::placeholder {
        color: transparent;
      }
    }

    .selection-wrapper{
      display: flex;
      align-items: flex-end;
      gap: 12px;
      flex-shrink: 0;
      min-width: 50%;
    }
    .area-selector {   
      width: 100%; 
      &-toggle {
        &.dropdown-toggle {
          display: flex;
          padding: 12px 16px;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          gap: 8px;
          align-self: stretch;
          width: 100%;
          font-size: toRem(14px);
          font-weight: 500;
          line-height: 20px;
          border: none;
          background: var(--primary_04);
          color: var(--brand_white);
          outline: none !important;
    
          &.btn-outline-secondary {
            &.show {
              border: none;
              background: var(--primary_04) !important;
              color: var(--brand_white) !important;
              border-radius: 10px;
              border: 1px solid var(--brand_white);
            }
          }
    
          &::after {
            display: none !important;
          }
    
          &:hover {
            border: none;
            background: var(--primary_04) !important;
            color: var(--brand_white) !important;
          }
        }
      }
    
      &-menu {
        &.dropdown-menu {
          width: 100%;
          padding: 8px 0px;
          background: var(--primary_04);
          &.show {
            box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
              0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
            border-radius: 12px;
          }
        }
    
        &-item {
          &.dropdown-item {
            width: 100%;
            font-size: toRem(14px);
            font-weight: 500;
            line-height: 20px;
            border: none;
            background: var(--primary_04);
            color: var(--brand_white);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
    
            &.active {
              background: var(--primary_04);
              color: var(--brand_white);
              font-weight: 700;
            }
    
            &:first-child {
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
            }
    
            &:last-child {
              border-bottom-left-radius: 12px;
              border-bottom-right-radius: 12px;
            }
          }
          &.dropdown-item:hover {
            background: var(--primary_01);
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          }
        }
      }
    
      .area-selected {
        &-wrapper {
          display: inline-flex;
          align-items: center;
        }
    
        &-name {
          margin-left: 10px;
        }
      }
    }
    .snapshot-wrapper{
      width: 56px;
      height: 32px;
      display: flex;
    }
    .cameraRowDisabled {
      pointer-events: none;
      opacity: 0.5;
    }
    .device-name{
      white-space: nowrap;
      overflow: hidden;
      text-overflow:ellipsis;
      display: inline;
    }
    .loading-container {
      position: fixed;
      top: 50%;
      left: 55%;
      color: var(--brand_white);
    }
    /* Table styles */
    .table-scroll{
      // overflow-y: auto; 
      height: calc(100vh - 232px); 
    }
    .relative{
      position: relative;
    }
    .table {
      border-collapse: collapse;
      table-layout: fixed;
    }

    .table thead,
    .table tbody,
    .table tfoot,
    .table tr,
    .table td {
      text-align: start;
      height: 48px;
      vertical-align: middle;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
    }

    .table thead,
    .table tbody,
    .table td {
      padding: 7px 16px;
    }

    .table thead th {
      text-align: start;
      // padding: 0px 16px;
      height: 32px;
      vertical-align: middle;
      font-style: normal;
      font-weight: 400;
      font-size: toRem(12px);
      line-height: 16px;
      background-color: inherit;
      display: flex;
      align-items: center;
    }

    .table {
      thead {
        height: 32px;
        .table-header-row {
            display: flex;
            flex-direction: row;
            padding: 0px;
          }
        tr,
        th {
          height: 32px;
        }
      }
    }

    thead,
    .table > :not(:first-child) {
      border-top: 0;
      display: table-row;
      // width: 100%;
    }

    .table thead td,
    .table thead th {
      color: var(--primary_02) !important;
      height: 32px;
    }

    .table tbody td {
      border-style: solid;
      color: var(--primary_02);
      background-color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow:ellipsis;
      .viewCheckbox{
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        .drag-icon{
          margin-left: -20px;
          width: 22px;
          height: 22px;
          cursor: move;
          color: var(--brand_white);
        }
      }

      .viewCheckbox input[type='checkbox']{
        height: 16px;
        width: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1.5px solid var(--primary_02);
        background: var(--greyscale_08);
        appearance: none;
        cursor: pointer;
      }
      .viewCheckbox input[type='checkbox']:checked::after {
        position: absolute;
        width: 16px;
        height: 16px;
        color: var(--brand_white);
        content: '\2713';
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border: 1px;
        background: var(--primary_64);
        cursor: pointer;
        border-radius: 2px;
        padding: 9px;
      }
    }

    .table tbody tr {
      background-color: inherit;
      height: 48px;
      cursor: initial;
      border-bottom: 1px solid var(--primary_04);
      display: flex;
      flex-direction: row;
      padding: 0;
      justify-content: space-between;
    }

    .table tbody td svg {
      width: 18px;
      height: 18px;
    } 
    .table tbody {
      .react-window-list {
        will-change: auto !important;
      }
    }
    .table tbody {
      .react-window-list > div {
        background: var(--greyscale_08);
      }
    }

    .table tr td:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-left: 20px;
    }
    .table tr td:nth-child(2) {
      color: var(--greyscale_88);
      position: relative;
      border-radius: 8px 0 0 8px;
    }

    .table tr td:last-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      padding-right: 20px;
      overflow: visible;
    }


    .table tr:first-child td {
      border-top-style: solid;
      font-size: 14px;
      line-height: 20px;
      font-family: 'Noto Sans';
      font-weight: normal;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    .table tr td:first-child {
      border-left-style: solid;
    }

    .table .columnheader-asc span,
    .table .columnheader-desc span {
      margin-left: 16px;
    }

    .table .columnheader-desc {
      color: var(--primary_02);
    }

    .table .columnheader-desc svg path {
      stroke: var(--primary_02);
    }

    .table .columnheader-asc {
      color: var(--primary_02) !important;
    }

    .table .columnheader-asc svg path {
      stroke: var(--primary_02);
    }
    .table .selected-row{
      background: rgba(var(--greyscale-custom9));
      cursor: move;
      // position: relative;
      // display: table-row;

      // & .full-line-border{
        border: 2px solid var(--primary_64);
        // position: absolute;
        display: block;
        width: 100%;
        border-radius: 8px;
        // background-color: transparent;
      // }
    }  
    .table .active-row {
      background-color: var(--greyscale_08);
    }
    .table .dragging-row {
      border-bottom: 0px !important;
    }
    .table .non-Selected-row-hover:hover{
      background: var(--primary_04);
      
    }  
    .table .selected-cell-left{
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
    .table .selected-cell-right{
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
    table .viewChecked{
      width: 55px;
    }
    table .snapshot{
      width: 70px;
      padding-right: 0px;
      padding-left: 0px;
    }
    table .deviceNameClick{
      cursor: pointer;
      font-weight: 500;
    }
    table .deviceName{
      width: 40%;
      color: var(--greyscale_88) !important;
    }
    table .deviceNameDisabled {
      color: var(--primary_02) !important;
    }
    table .locationName{
      width: 15%;
    }
    table .areaName{
      width: 15%;
    }
    table .displayDeviceStatus{
      width: 23%;
    }
    table .cameraOptions{
      width: 8%;
    }
    table .sort-icon{
      margin-left: 16px;
    }
    table .drag-count {
      position: absolute;
      height: 30px;
      width: 30px;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--primary_40);
      color: var(--brand_white);
      bottom: 30px;
      left: -20px;
      z-index: 9999;
    }
    table .drag-details {
      position: absolute;
      width: 50%;
      align-items: center;
      display: flex;
      justify-content: center;
      background: rgba(var(--greyscale-custom9));
      color: var(--primary_02);
      top: 0px;
      left: 0px;
      z-index: 9999;
      border-radius: 8px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .drag-opacity-0{
        opacity: 100%;
      }
      .drag-opacity-1{
        opacity: 80%;
      }
      .drag-opacity-2{
        opacity: 60%;
      }
      .drag-opacity-3{
        opacity: 40%;
      }
      .drag-opacity-4{
        opacity: 20%;
      }
      .drag-opacity-5{
        opacity: 5%;
      }
    }
    table .drag-details-wrapper {
      width: 100%;
      align-items: center;
      display: flex;
      color: var(--primary_02);
      border-radius: 8px;
      border: 2px solid var(--primary_64);
      height: 48px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: transparent;

      .viewChecked{
        width: 24px;
        padding-left: 20px;
        background: var(--primary_40);
        display: flex;
        align-items: center;
        height: 100%;
      }
      .snapshot{
        width: 72px;
        padding-right: 0px;
        padding-left: 16px;
      }
      .deviceName{
        width: 40%;
        color: var(--greyscale_88) !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .locationName{
        width: 25%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .areaName{
        width: 25%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .drag-clmn-padding{
        padding: 0px 16px;
      }
      .drag-icon{
        margin-left: -22px;
        width: 22px;
        height: 22px;
        cursor: move;
      }
    }

    .status-indicator {
      margin-right: 0.375rem;
      height: 0.625rem !important;
      width: 0.625rem !important;

      &.status {
        &-online {
          color: var(--success_48);
        }

        &-claiming {
          color: var(--primary_48);
        }

        &-entered {
          color: var(--warning_64);
        }

        &-offline {

          color: var(--error_112);
        }

        &-ready-to-claim {
          color: var(--greyscale_40);
        }
      }
    }
    .no-cameras {
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 232px);
      }
      &-icon-container{
        display: flex;
        width: 60px;
        height: 60px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        background: var(--grayscale_16);
        margin-bottom: 24px;
      }
      &-notice {
        color: var(--primary_05);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 24px;
      }
    
      &-cta {
        .no-cameras-add-device-btn {
          padding: 8px 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 8px;
          color: var(--brand_white);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 133.333% */
          height: 32px;
          width: 128px;
          border: none;
          background-color: var(--primary_40);
          border-radius: 8px;
          white-space: nowrap;   
          font-family: 'Noto Sans';   
          text-transform: inherit;  
        }
      }
    }
    .table-action-dropdown {
      &:focus-visible {
        outline: unset;
      }
    
      .dropdown-item {
        color: var(--brand_white);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        background: var(--hGrayscale_24);
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        width: 180px;
        .active,
        &:active,
        &:focus,
        &:hover  {
          background: var(--hGrayscale_32);
        }
        &:first-child {
          border-radius: 8px 8px 0px 0px;
          padding: 16px 16px 8px 16px;
        }
        &:last-child {
          border-radius: 0px 0px 8px 8px;
          padding: 8px 16px 16px 16px;
        }
      }
    
      .dropdown-menu {
        box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custme8)), 0px 4px 6px -2px rgba(var(--greyscale-custme7));
        border: 0;
      }
    
      .dropdown-toggle::after {
        display: none;
      }
    }
  }
.show-data-loader {
  position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 999999999; */
    top: 0;
}
  .loading-container {
    &.redirecting-loader {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(50%, 50%);
      z-index: 1;
      color: var(--brand_white);
    }
  }
}
