@import '../../../assets/css/base.scss';

.evidence {
  &-comments-uploader {
    margin-top: 28px;

    &-wrapper {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 12px;
      background: var(--grayscale_96);

      .evidence {
        &-comments {
          &-input {
            width: 100%;
            border-radius: 12px;
            border: 1.5px solid var(--greyscale_88);
            position: relative;

            .evidence-comment-field {
              z-index: 10;

              .MuiInputBase-multiline
                .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline::placeholder {
                color: var(--greyscale_56);
                opacity: 1;
                font-size: toRem(16px);
                font-weight: 500;
                line-height: toRem(20px);
              }

              .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline {
                &.Mui-focused {
                  .MuiOutlinedInput-notchedOutline {
                    border-color: var(--primary_40);
                  }
                }
              }
            }

            .custom-placeholder {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              position: absolute;
              top: 10px;
              line-height: 1rem !important;
              z-index: 9;
              overflow: hidden;
              white-space: nowrap;
              padding: 10px;
              margin-left: 6px;
              background-color: var(--brand_white);

              color: var(--greyscale_56);
              opacity: 1;
              font-size: toRem(16px);
              font-weight: 500;
              line-height: toRem(20px);

              .required {
                color: var(--error_64);
                margin-left: 4px;
                margin-top: -8px;
              }
            }
          }
        }

        &-upload {
          &-input {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background: var(--grayscale_96);

            .hidden {
              display: none;
            }
          }

          &-file-listing {
            display: flex;
            align-items: center;
            margin-top: 4px;

            .close-btn {
              cursor: pointer;
            }
          }

          &-file-label {
            color: var(--greyscale_08);
            font-size: toRem(12px);
            font-weight: 600;
            line-height: toRem(16px);
            margin-right: 18px;
          }

          &-file-name {
            color: var(--primary_40);
            font-size: toRem(12px);
            font-weight: 600;
            line-height: toRem(16px);
            margin-right: 6px;
          }

          &-action-buttons {
            display: flex;
            justify-content: flex-end;
            min-width: 400px;
            margin-top: 4px;
          }

          &-attach-btn,
          &-upload-btn {
            display: flex;
            width: 140px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 16px !important;
            border-radius: 10px;
            text-transform: capitalize;

            .site-spinner {
              height: 15px;
              width: 15px;
            }
          }

          &-attach-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 16px !important;
            font-size: toRem(12px);
            line-height: 20px;
            font-weight: 600;
            height: 32px;
            width: 140px;
            color: var(--primary_40);
            border-color: var(--primary_40);
            margin-right: 18px;
            padding: 8px 16px !important;

            &:focus,
            &:focus-visible,
            &:focus-within {
              border-color: var(--primary_40);
              background-color: var(--brand_white);
              outline: 0;
            }

            &:hover {
              color: var(--primary_40);
              border-color: var(--primary_40);
              background-color: var(--primary_96);
              outline: 0;
            }
          }

          &-upload-btn {
            display: flex;
            height: 16px;
            width: 140px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: toRem(12px);
            padding: 8px 16px !important;

            &:focus {
              border-color: var(--primary_24);
            }

            &:hover {
              background-color: var(--primary_24);
            }

            &.disabled {
              border-color: var(--greyscale_88);
              background-color: var(--greyscale_88);
              color: var(--grayscale_32);
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  &-section {
    text-align: left;

    &-title {
      color: var(--brand_black);
      font-size: toRem(16px);
      font-weight: 600;
      line-height: toRem(20px);
    }

    &-subtitle {
      color: var(--greyscale_40);
      font-size: toRem(14px);
      font-weight: 500;
      line-height: toRem(20px);
    }

    &-content {
      &-wrapper {
        width: 100%;
        min-height: 138px;
        border-radius: 12px;
        border: 1.5px dashed var(--greyscale_88);
        background-color: var(--grayscale_96);

        .evidence-no-content {
          display: flex;
          height: 185px;
          padding: 24px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          flex-shrink: 0;
          align-self: stretch;
          background-color: transparent;

          &-title {
            color: var(--greyscale_08);
            text-align: center;
            font-size: toRem(14px);
            font-weight: 500;
            line-height: toRem(20px);
            margin-left: 2px;
          }

          &-subtitle {
            color: var(--greyscale_56);
            text-align: center;
            font-size: toRem(12px);
            font-weight: 400;
            line-height: toRem(16px);
            margin-left: 2px;
          }
        }
      }
    }
  }
}

.notes-table {
  .avatar-field {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .site-avatars {
      margin-right: 12px;
    }
  }
}

.evidence-type-filter-modal {
  .modal {
    &-header {
      padding-top: 0;
    }
    &-body {
      .filters-content-item {
        display: flex;
        padding: 8px 10px 8px 20px;
        align-items: flex-start;
        gap: 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 12px;
        background-color: var(--grayscale_96);
        margin-bottom: 16px;
        &-details {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: var(--greyscale_08);
          font-size: toRem(14px);
          font-weight: 500;
          line-height: toRem(20px);
          svg,
          .filter-icon {
            color: var(--greyscale_08);
            height: 20px;
            width: 20px;
            margin-right: 8px;
          }
        }
        &-checkbox {
        }
      }
    }
    &-footer {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      border: 0;
      .evidence-type-filter {
        &-button-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: 0;
          margin-right: 0;
          width: 100%;
          .evidence-type-filter {
            &-save-btn {
              font-size: toRem(14px);
              line-height: 20px;
              font-weight: 600;
              height: 44px;
              &:hover {
                background-color: var(--primary_24);
              }
              &.disabled {
                color: var(--grayscale_32);
              }
            }
            &-cancel-btn.btn.btn-outline-secondary {
              font-size: toRem(14px);
              line-height: 20px;
              font-weight: 600;
              width: 100%;
              height: 44px;
              color: var(--primary_40);
              border-color: var(--primary_40);
              &:focus {
                border-color: var(--primary_40);
                background-color: var(--brand_white);
              }
              &:hover {
                background-color: var(--primary_96);
              }
            }
          }
        }
      }
    }
  }
}
