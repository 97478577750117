@import '../../assets/css/base.scss';

.live-selection-view {
  .main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--grayscale_16);
    min-height: calc(100vh - 72px);
    overflow-y: auto;

    .video-wall-background {
      display: flex;
      flow-direction: column;

      .toast-wrapper {
        display: inline-flex;
        justify-content: space-between;
        text-align: left;
        width: 100vw;
      }

      .page-title {
        color: var(--brand_white);
        font-size: toRem(24px);
      }

      .device-title {
        color: var(--brand_white);
        font-size: toRem(24px);
      }

      .device-sub-title {
        color: var(--greyscale_80);
        font-size: toRem(12px);
      }
    }

    .action-buttons {
      display: flex;
      position: sticky;
      z-index: 10;
      bottom: 32px;
      align-items: center;
      justify-content: center;
      width: 100%;

      .action-button {
        color: var(--brand_white);
        font-family: 'Noto Sans', Arial, Helvetica, sans-serif;
        font-size: toRem(14px);
        font-weight: 600;
        height: 40px;
        padding: 8px 20px 10px;

        &:hover {
          background-color: var(--primary_24);
          border-color: var(--primary_24);
        }

        &.disabled {
          background-color: var(--primary_24);
          border-color: var(--primary_24);
          color: var(--primary_80);
          font-size: toRem(14px);
        }
      }
    }
  }
}
