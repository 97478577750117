@import '../../../../assets/css/_utilities.scss';


.device-settings-block {
  &.editable-off-offline {
    pointer-events: none;
  }

    .tab-title-analytics {
      color: var(--text_color);
      font-family: "Noto Sans";
      font-size: toRem(16px);
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 20px;
      margin-left: 5px;
      margin-bottom: 10px;
    }

  .settings-block {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .select-field-wrap {
      margin-top: 0;
      margin-bottom: 0;

      .form-select {
        margin-bottom: 0;
      }
    }
  }
}

.alert.alert-danger {
  background-color: var(--error_96);
  border-color: transparent;
  border-radius: 12px;
  padding: 12px;
  gap: 8px;

  .alert-heading {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(16px);
    line-height: 20px;
    color: var(--error_24);
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--error_24);
    margin-bottom: 0;
  }

  .btn-close {
    height: 10px;
    width: 10px;
  }
}

.device-settings-restart-required {
  .modal-header {
    padding-bottom: 4px;
  }
}

.recording-video-codec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 12px;
  background: var(--grayscale_96);
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 16px;

  .label-recording {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(16px);
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--brand_black);
  }

  .codec-selection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.selection-disable {
      pointer-events: none;
      //background-color: var(--greyscale_64);
    }

    .label-codec {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);
    }
  }
    .codec-selection>input {
      height: 20px;
      width: 20px;
      margin-left: 10px;
    }
  
    .codec-selection input[type='radio'] {
      -webkit-appearance: none;
      border-radius: 50%;
      outline: none;
      border: 2px solid var(--greyscale_80);
      background: var(--brand_white);
    }
  
    .codec-selection input[type='radio']:before {
      content: '';
      display: block;
      width: 64%;
      height: 64%;
      margin: 18% auto;
      border-radius: 50%;
    }
  
    .codec-selection input[type="radio"]:checked:before {
      background: var(--primary_40);
    }
  
    .codec-selection input[type="radio"]:checked {
      border-color: var(--primary_40);
    }
  
    .codec-selection-offline{
      width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
        &.selection-disable {
          pointer-events: none;
          //background-color: var(--greyscale_64);
        }
    
        .label-codec {
          font-style: normal;
          font-weight: 500;
          font-size: toRem(16px);
          line-height: 20px;
          display: flex;
          align-items: center;
          color: var(--greyscale_40);
        }
    }
    .codec-selection-offline>input {
      height: 20px;
      width: 20px;
      margin-left: 10px;
    }
    
    .codec-selection-offline input[type='radio'] {
      -webkit-appearance: none;
      border-radius: 50%;
      outline: none;
      border: 2px solid var(--greyscale_80);
      background: #797A7B;
    }
    
    .codec-selection-offline input[type='radio']:before {
      content: '';
      display: block;
      width: 64%;
      height: 64%;
      margin: 18% auto;
      border-radius: 50%;
    }
    
    .codec-selection-offline input[type="radio"]:checked:before {
      background: var(--custom_color_disabled);
    }
    
    .codec-selection-offline input[type="radio"]:checked {
      border: 1.5px solid #444648;
    }
}

.page-offline-message {
  background-color: var(--error_96);
  border-radius: 12px;
  padding: 12px;
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .left-section {
    .icon {
      color: var(--error_24);
    }
  }

  .right-section {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 3px;

    .title-heading {
      color: var(--error_24);
      font-family: "Noto Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    .sub-title-heading {
      margin-top: 5px;
      color: var(--error_24);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.video-qty-parent{
    border-radius: 12px;
    background: var(--greyscale_96);
    padding : 20px;
    margin-bottom: 16px;

    .video-qty-top{
    display: flex;
    margin-bottom: 10px;
      justify-content: space-between;
      .video-quality-text{
        color: var(--greyscale_08);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
     
    }
    .w-90{
      width: 90%;
    }
    .p0{
      padding: 0px;
    }
    .p-r-0{padding-right: 0px;}
    .display-f{
      display: flex;
    }
    .display-g{
      display: grid;
    }
    .drop-holder{
       width: 100%;
      .drop-text{
        color: var(--greyscale_40);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
    .select-field-wrap{
      margin-top: 8px;
      margin-bottom: 0px;
      color: var(--greyscale_08);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      width: 100%;
      .form-control{
        padding: 10px;
      }
    }
}

.bitrate-hint{
  font-size: 14px;
  line-height: 20px;
  color: var(--custom_color_19);
}
.view-reports-text{
  color: var(--person_notification_bg);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
}
.vdo-text-algn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drag-title{
font-size: 14px;
font-weight: 500;
line-height: 20px;
}
.title-vdo-2{
  font-size: 12px;
  margin-top: 20px;
}
.mt30{
  margin-top: 30px;
}