.grid-str-container {
  display: grid;
  gap: 10px; /* Adjust as needed */
  max-height: calc(100vh - 310px); /* Adjust as needed */
  margin: auto;
  max-width: -webkit-fill-available;
}

.camera-tile-container {
  // height: 100%;
  height: calc(100vh - 65%);
  max-height: calc(100vh - 65%);
  margin: auto;
  position: relative;

  .loading-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.visibility-hidden {
    visibility: hidden;
  }

  &.image-mode {
    .devices-stack {
      .device-stack-item {
        z-index: 1003;
      }
    }
  }

  .devices-stack {
    height: 100%;

    .device-stack-item {
      height: auto;
      background-color: var(--brand_black);

      .grid-stack-item-content {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        &:has(> div.not-active-camera-details) {
          justify-content: center;
          align-items: center;
        }
      }

      .react-resizable-handle {
        z-index: 1002;
        &::after {
          &:hover {
            border-color: var(--hGrayscale_32);
          }
        }
      }

      &-content,
      .streaming-container {
        width: 100%;
        height: 100%;
        outline: 1px solid var(--hGrayscale_32);

        &.device-overlay {
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 1001;
          // display: none;
          inset: 0px;

          &:hover {
            display: block;
          }
        }
      }
    }
  }
}

.device-wall-main-container {
  .main-content-container {
    /* Adjust as needed */
    max-height: calc(100vh - 310px);
    /* Adjust as needed */
    height: calc(100vh - 310px) !important;
    position: relative;
  }
  .muuri {
    height: 100% !important;
  }
}

.item-grid {
  overflow: hidden;
  font-size: 12px;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 16/9;
  padding-top: 0px !important;
  border: 0 !important;
  outline: 1px solid var(--grayscale_32) !important;

  ump-player {
    width: max-content !important;
    height: 100% !important;
  }

  &.dragged-item {
    z-index: 2;
  }

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: max-content !important;
  }

  canvas {
    position: absolute;
  }

  &.add-hover {
    outline: 2px solid var(--hGreyscale_72) !important;

    .hovered {
      display: block !important;
    }

    .device-overlay {
      display: block !important;
    }

    .grid-stack-item-content {
      .device-overlay {
        display: block !important;
      }
    }
  }

  .device-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(var(--greyscale-custom2), 0.8);
    z-index: 1001;
    display: none !important;
    top: 0;
    left: 0;
    cursor: grab;

    .device-title-container {
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: left;
      font-size: 0.75rem;
      line-height: 1rem;
      padding: 0.5rem;

      .device-name {
        font-weight: 600;
      }

      .device-location {
        font-weight: 400;
        color: var(--greyscale_72);
      }
    }

    &.name-option-container {
      z-index: 1001;
      display: block;
      // background: var(--greyscale_08);background

      .device-title-container {
        & .device-name {
          color: var(--hGrayscale_56);
        }
        & .device-location {
          color: var(--custom_color_disabled);
        }
      }
    }

    .date-time-wrapper {
      position: absolute;
      right: 32px;
      top: 8px;

      .date-time {
        display: flex;
        padding: 2px 8px;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: rgba(var(--greyscale-custom10));
        backdrop-filter: blur(5px);
        color: var(--brand_white);
        font-size: toRem(12px);
        font-style: normal;
        font-weight: 600;
        line-height: toRem(16px);
      }

      .off-date-time {
        display: flex;
        padding: 2px 8px;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background-color: rgba(var(--primary_black), 0.4);
        backdrop-filter: blur(5px);
        color: var(--hGrayscale_56);
        font-size: toRem(12px);
        font-style: normal;
        font-weight: 600;
        line-height: toRem(16px);
      }
    }

    .menu-icon {
      position: absolute;
      right: 0;
      top: 0;
      padding: 8px;
      cursor: pointer;
      z-index: 1005;

      .devices-dropdown {
        .dropdown-menu {
          background: var(--hGrayscale_24);
        }
        .dropdown-toggle::after {
          display: none;
        }

        &-options {
          display: flex;

          &:active {
            background-color: transparent;
          }

          &:hover {
            background-color: var(--hGrayscale_32);

            &:first-child {
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
            }

            &:last-child {
              border-bottom-left-radius: 12px;
              border-bottom-right-radius: 12px;
            }
          }

          svg {
            stroke: var(--hGreyscale_84);
          }

          &-label {
            margin-left: 8px;
            color: var(--greyscale_97);
            font-size: toRem(14px);
            line-height: toRem(20px);
            font-weight: 500;
          }
        }
      }
    }
  }

  .primary-circle {
    position: absolute;
    left: 8px;
    top: 8px;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    border: 1px solid var(--brand_white);
    background-color: var(--primary_64);
    z-index: 2;
  }

  .not-active-camera {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transform: none !important;

    &.not-active-camera-bg {
      background: var(--hGreyscale_16);
    }

    .not-active-camera-details {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1002;

      .no-active-icon,
      .no-license-icon,
      .no-snapshot-icon {
        fill: var(--hGreyscale_72) !important;
        height: 24px;
        width: 24px;
        margin-bottom: 2px;
      }

      .not-active-camera-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: var(--hGreyscale_40);
      }

      .not-active-camera-button {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: var(--primary_02);
        margin-top: 4px;
        text-decoration: underline;
        cursor: pointer;
      }

      .try-again-button {
        border: 1.5px solid var(--greyscale_40);
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        width: fit-content;
        margin: auto;
        margin-top: 1rem;
        color: var(--greyscale_40);
        cursor: pointer;
      }

      .simple-label {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        margin-top: 0.5rem;
        color: var(--greyscale_40);
      }

      .simple-label-2 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.25rem;
        color: var(--greyscale_40);
      }
    }
  }

  .not-active-camera-details {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1002;

    .no-active-icon,
    .no-license-icon,
    .no-snapshot-icon {
      fill: var(--hGreyscale_72) !important;
      height: 24px;
      width: 24px;
      margin-bottom: 2px;
    }

    .not-active-camera-title {
      font-size: toRem(12px);
      font-weight: 500;
      line-height: toRem(16px);
      color: var(--custom_color_disabled);
    }

    .not-active-camera-button {
      font-size: toRem(12px);
      font-weight: 600;
      line-height: toRem(16px);
      color: var(--greyscale_97);
      margin-top: 4px;
      text-decoration: underline;
      cursor: pointer;
    }

    .try-again-button {
      border: 1.5px solid var(--greyscale_40);
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      width: fit-content;
      margin: auto;
      margin-top: 1rem;
      color: var(--greyscale_40);
      cursor: pointer;
    }

    .simple-label {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin-top: 0.5rem;
      color: var(--greyscale_40);
    }

    .simple-label-2 {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: var(--greyscale_40);
    }
  }

  .streaming-container,
  .main-snapshot {
    position: absolute;
    z-index: 1;
    height: -webkit-fill-available;
  }

  .streaming-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-video-content {
    position: absolute;
  }

  .hide-image {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
  }
}

.live-grid-snapshot-div {
  display: flex;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16 / 9;

  .live-img-snapshot {
    position: absolute !important;
  }
}

.playback-grid-snapshot-div {
  display: flex;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: -webkit-fill-available;
  // aspect-ratio: 16 / 9;aspect-ratioaspect-ratio

  .playback-grid-image {
    position: absolute;
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    height: -webkit-fill-available;

    .playback-snapshot-img {
      position: absolute !important;
      display: flex;
    }
  }
}

.item-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px;
  text-align: center;
}

.devices-dropdown {
  .devices-dropdown-options {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    .dropdown-item {
      &:hover {
        background-color: var(--hGrayscale_32);
      }
    }
  }
}

.item-grid .device-overlay .menu-icon .devices-dropdown-options-label {
  @media screen and (max-width: 1280px) and (max-height: 720px) {
    margin-left: 2x;
    color: var(--brand_white);
    font-weight: 400;
    font-size: 8px;
  }
}

.dropdown .dropdown-menu .dropdown-item {
  @media screen and (max-width: 1280px) and (max-height: 720px) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
