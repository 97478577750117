@import '../../assets/css/utilities';
@import '../../assets/css/variables.scss';

.partner-customers-list-wrap {
  .partner-customers-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: var(--grayscale_96);
    border-radius: 12px;
    margin-bottom: 8px;
    &:hover {
      background-color: var(--greyscale_88);
    }
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }

    .partner-customers-list-item-label {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 20px;
      text-align: left;
    }

    .partner-customers-list-item-input {
      height: 20px;
      width: 20px;
    }
  }
}

.list-block {
  .role-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    .default-label {
      color: var(--primary_32);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
      border-radius: 0.625rem;
      background: var(--primary_96);
      padding: 0.375rem 0.625rem;
      margin-left: 1rem;
    }
  }
}
.custom-role-text {
  margin-top: 2.25rem;
}

.role-input-group-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.form-input {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  height: 3.25rem;
  border-radius: 0.75rem;
  padding: 0.75rem 1rem;
}
.placeholder-text {
  height: 3.25rem;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.75rem 1rem;
  position: absolute;
  pointer-events: none;
  gap: 0.25rem;
}

.role-checkbox{
  height: 1rem;
  width: 1rem;
}
