@import '../../assets/css/utilities';

.purchase-confirmation {
  background-color: var(--primary_96);
  &-container {
    height: 100vh;
    background-color: var(--primary_96);
  }
}

.page-body-content-row {
  padding-top: 20px;

  .page-body-title-wrapper {
    text-align: 'left';

    .page-body-title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  .page-description {
    color: #8f8f8f;
    line-height: 20px;
    font-size: 0.875rem;
  }

  &:last-child {
    padding-bottom: 28px;
  }

  .confirmation-card {
    background-color: var(--brand_white);
    border: 0;
    border-radius: 12px;
    width: 630px;
    text-align: center;
    margin: 0 auto;

    &-body {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      position: relative;
      padding: 40px 40px 0;

      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: '100%';

        &-left {
          text-align: left;
          display: flex-start;
        }

        h3 {
          font-weight: 700;
          font-size: 1.5rem;
        }

        p {
          color: var(--greyscale_56);
          font-size: 0.875rem;
        }
      }
    }

    &-title {
      color: var(--greyscale_56);
      font-size: toRem(20px);
    }

    &-footer {
      background-color: var(--brand_white);
      border: 0;
      padding-bottom: 40px;
      padding-left: 40px;
      padding-right: 40px;

      &.card-footer {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      .btn-primary,
      .btn-outline-primary {
        height: 44px;
        line-height: 22px;
        padding: 10px 16px;

        &:first-child {
          margin-bottom: 6px;
        }

        div.confirmation-btn-label {
          font-size: 0.875rem;
          line-height: normal;
        }
      }
    }
  }
}
