@import '../../../assets/css/base';

.device-filters-notification {
  padding-top: 10px;
  padding-bottom: 10px;

  .parent-layout {
    margin-bottom: 10px;
    padding-top: 10px;
    border-radius: 12px;
    background: var(--grayscale_96);
    height: auto;
    padding-bottom: 10px;

    .list-devices {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .left-conatiner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .parent-title {
          margin-left: 10px;
          color: var(--text_color);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .right-container {
        background-color: var(--brand_white);
        border: 1px solid var(--text_disable_color);
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 4px;
        display: flex;
        cursor: pointer;

        &.selected-tick {
          background-color: var(--primary_40);
          border: none;
        }

        &.event-not-supported {
          background-color: var(--greyscale_48);
          border: 1px solid var(--greyscale_40);
          pointer-events: none;
        }

        .icon-image {
          height: 14px;
          width: 14px;
        }
      }
    }

    .area-layout {
      margin-left: 20px;

      .area-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

        .left-conatiner {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .parent-title {
            margin-left: 10px;
            color: var(--text_color);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }

        .right-container {
          background-color: var(--brand_white);
          border: 1px solid var(--text_disable_color);
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          border-radius: 4px;
          display: flex;

          &.selected-tick {
            background-color: var(--primary_40);
            border: none;
          }

          &.event-not-supported {
            background-color: var(--greyscale_48);
            border: 1px solid var(--greyscale_40);
            pointer-events: none;
          }

          .icon-image {
            height: 14px;
            width: 14px;
          }
        }
      }

      .device-container {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

        .left-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;

          .title-capbility-reason-layout {
            margin-left: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .parent-title {
              margin-left: 10px;
              color: var(--text_color);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;

              &.unselected {
                color: var(--greyscale_56);
              }
            }

            .reason-title-layout {
              margin-top: 2px;
              margin-left: 10px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-start;

              .reason-title {
                margin-top: -1px;
                width: 250px;
                margin-left: 2px;
                color: var(--greyscale_56);
                font-size: toRem(12px);
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                @media screen and (max-width: 767px) {
                  width: 200px;
                }
              }
            }
          }
        }

        .right-container {
          background-color: var(--brand_white);
          border: 1px solid var(--text_disable_color);
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          border-radius: 4px;
          display: flex;

          &.selected-tick {
            background-color: var(--primary_40);
            border: none;
          }

          &.event-not-supported {
            background-color: var(--greyscale_48);
            border: 1px solid var(--greyscale_40);
            pointer-events: none;
          }

          .icon-image {
            height: 14px;
            width: 14px;
          }
        }
      }
    }
  }

  .no-device-layout {
    margin-top: 1px;
    border-radius: 12px;
    background: var(--grayscale_96);
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .text-no-device {
      color: var(--body_color);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
