#root{
  height: 100%;
}
.wrapper-outer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 1080px) {
      align-items: start;
    }
  }
  .login-wrapper{
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    background: #F3F5F7;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1080px) {
      background: #FFF;
      width: auto;
      height: 100%;
    }
  }
  .fisrt-half{
    width: 40%;
    height: 100%;
    flex-shrink: 0;
    background: var(--brand_primary);
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
  .brand-logo{
    padding: 24px;
    position:fixed;
  }
  .text-outer{
    display: flex;
    justify-content: center;
  }
  .left-text{
    width: 417px;
    color: #FFF;
    font-family: Noto Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px; /* 116.667% */
    margin-top: 28%;
    margin-left: 24px;
  }
  .landing-animation{
    width: 100%;
    flex-shrink: 0;
    position: absolute;
    max-width: 40%;
    bottom: 0;
  }
  .right-container-landing-page {
    display: flex;
    width: 629px;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 16px;
    background: #FFF;
    @media screen and (max-width: 1080px) {
      display: flex;
      width: 327px;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 16px;
      gap: 32px;
      margin-top: 180px;   
    }
  }
  .text-1{
    color: #7D8BA1;
    text-align: center;
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
  }
  .text-2{
    color: #111418;
    text-align: center;
    font-family: Noto Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    @media screen and (max-width: 1080px) {
      font-size: 20px;
    }
  }
  .text-3{
    color: #7D8BA1;
    text-align: center;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  .partner-button{
    display: flex;
    width: 100%;
    height: 56px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: var(--brand_primary);
    color: #FFF;
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
  }
  .partner-button:hover{
    background: #00317A;
  }
  .upper-gap{
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  .small-logo{
    @media screen and (min-width: 1081px) {
      display: none;
    }
  }