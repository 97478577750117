@import '../../assets/css/base';

.video-detail-wrapper {
  + .justify-content-start.row {
    .item {
      margin: 5px 12px 32px 0;
    }
  }
}

.live-item {
  cursor: auto;
  display: flex;
  flex-direction: column;
  margin: 5px 24px 32px 0;
  z-index: 1;
  &.detail-item {
    --bs-gutter-x: 0.5rem;
    position: relative;
  }

  &-1x {
    height: 138px;
    width: 165px;
  }

  &-2x {
    height: 270px;
    width: 354px;
  }

  &-3x {
    height: 443px;
    width: 543px;
  }

  &-content {
    width: 100%;
    position: relative;
  }

  &-name {
    width: 135px;
    color: var(--brand_white);
    font-size: toRem(14px);
    text-align: left;
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-overflow: clip;
      white-space: normal;
      word-break: break-all;
    }
  }

  &-location {
    color: var(--greyscale_56);
    font-size: toRem(12px);
    text-align: left;
  }

  &-snapshot {
    border-radius: 8px;
    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
    height: 93px;
    width: 165px;
    display: flex;
    align-items: center;
    background-color: #000;

    &-1x {
    }

    &-2x {
      height: 270px;
      width: 350px;
    }

    &-3x {
      height: 444px;
      width: 540px;
    }

    &-image {
      border-radius: 8px;
      outline: 1px solid var(--greyscale_72);
      aspect-ratio: 16 / 9;
      object-fit: scale-down;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;

      &-1x {
      }

      &-2x {
        height: 264px;
        width: 350px;
      }

      &-3x {
        height: 440px;
        width: 540px;
      }

      &-checked {
        outline: 1px solid var(--primary_48);
      }
    }

    &-wrapper {
      width: 100%;
      position: absolute;
      z-index: 5;
      top: 0;

      &-no-zoom {
        height: 96px;
        width: 165px;
      }
    }
  }

  &.cameras-item {
    --bs-gutter-y: 1rem;

    &-dragging {
      z-index: 3;
    }

    &-releasing {
      z-index: 2;
    }

    &-hidden {
      z-index: 0;
    }
  }

  &-checkbox {
    svg.MuiSvgIcon-root {
      border-color: var(--greyscale_80);
      color: var(--brand_white);
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
  }
  &-offline {
    background-color: var(--grayscale_32);
    border-radius: 8px;
    box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 93px;
      background-color: var(--grayscale_32);
      box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
      border-radius: 8px;

      .camera-offline {
        &-icon {
          margin-top: 15%;
          svg {
            cursor: pointer;
          }
        }

        &-label {
          font-size: toRem(12px);
          color: var(--greyscale_56);
          margin-top: 4px;
        }
      }
    }
  }

  &-overlay {
    background-color: var(--brand_black);
    border-radius: 8px;
    // height: 96px;
    height: 93px;
    width: 165px;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;

    &-1x {
      // height: 86px;
      // width: 165px;
    }

    &-2x {
      height: 270px;
      width: 350px;
    }

    &-3x {
      height: 444px;
      width: 540px;
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 0.6;
      animation: fadeIn 0.35s ease;
    }

    &-content {
      position: absolute;
      height: 100%;
      width: 100%;

      .zoom-options {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 15;

        &.hidden {
          display: none;
        }
      }

      .play-button {
        color: var(--brand_white);
        height: 22px;
        width: 22px;
        opacity: 0.6;
        position: absolute;
        display: block;
        object-position: center;
        bottom: 50%;
        left: calc(50% - 8px);

        &:hover,
        &:active,
        &:focus {
          opacity: 1;
        }

        svg {
          margin-top: 33%;
          margin-left: auto;
          margin-right: auto;
          cursor: pointer;
        }
      }
    }
  }
}
