@import '../../assets/css/base';

.notification-setting {
  display: flex;
  width: 100%;

  .page-title {
    width: 100%;
    margin-left: 10px;
    margin-top: -10px;
    font-style: normal;
    font-weight: 700;
    font-size: toRem(24px);
    line-height: 32px;
    display: flex;
    color: var(--greyscale_08);
  }
}

.page-body {
  margin-top: 20px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;

  .left-container {
    width: 28%;
    height: auto;

    .my-notification-item {
      cursor: pointer;
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 8px;
      border-radius: 8px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 44px;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);

      @media screen and (max-width: 767px) {
        font-size: toRem(10px);
      }

      &.selected {
        color: var(--text_color);
        background: var(--grayscale_96);
        font-weight: 600;
      }
    }

    .system-notification {
      cursor: pointer;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 8px;
      border-radius: 8px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 44px;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--greyscale_40);

      @media screen and (max-width: 767px) {
        font-size: toRem(10px);
      }

      &.selected {
        color: var(--text_color);
        background: var(--grayscale_96);
        font-weight: 600;
      }
    }
  }

  .right-container {
    width: 72%;
    margin-left: 25px;
  }
}
