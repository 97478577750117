@import '../../assets//css/base.scss';

.timezone-input-wrapper {
  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      border-radius: 12px;
    }
  }
}

.main-select {
  color: var(--greyscale_56);

  &.Mui-disabled {
    color: var(--brand_black);

    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
      color: var(--brand_black);
      -webkit-text-fill-color: var(--brand_black);
      height: 52px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 16px;
    }
  }
}
