@import '../../assets/css/base.scss';

.order-history {
  .page-header {
    .page-title {
      padding-left: 0;
      padding-right: 0;

      &-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
      }
    }
  }

  .spinner-container {
    display: 'flex';
    justify-content: 'space-around';
    align-items: 'center';
    height: 100px;
    position: relative;
  }

  .order-history-table {
    thead {
      border-spacing: 0;

      th {
        border: 0;
        color: var(--greyscale_56);
        max-width: 120px;

        .header-sort {
          cursor: pointer;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .column-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100px;
          }

          .sort-icon {
            margin-right: 20px;
            font-size: 1.5rem;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background-color: var(--greyscale_88);
        }
        td {
          max-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-left: 0;
          border-right: 0;

          .buyer {
            &-field {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 12px;
            }

            &-avatar {
              display: flex;
              width: 40px;
              height: 40px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 4px;
              border-radius: 251px;
              border: 1px solid var(--White, #fff);
              color: var(--White, #fff);
              font-size: toRem(12px);
              font-weight: 500;
              line-height: toRem(16px);
              background: var(--avatar_dark_brand);
              &-image {
              }
            }
          }

          .date-field {
            display: inline-flex;
            align-items: center;

            .calendar-icon {
              margin-right: 0.406rem;
            }

            .exclamation-icon {
              margin-right: 0.406rem;
              color: var(--warning_48);
            }

            .warning-date {
              color: var(--warning_48);
            }
          }

          .order-status,
          .payment-status,
          .fulfillment-status {
            &-field {
              display: inline-flex;
              align-items: center;
            }

            &-indicator {
              margin-right: 0.375rem;
              height: 0.625rem;
              width: 0.625rem;
            }

            &-open,
            &-paid,
            &-fulfilled {
              color: var(--success_48);
            }

            &-closed {
              color: var(--greyscale_40);
            }

            &-drafted {
              color: var(--warning_64);
            }

            &-canceled,
            &-refunded {
              color: var(--error_48);
            }
          }
        }
      }
    }
  }
}
