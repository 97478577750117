@import '../../assets/css/base';

.my-notification {
    width: 100%;



    .header-layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .my-notification-label {
            font-style: normal;
            font-weight: 600;
            font-size: toRem(20px);
            line-height: 28px;
            display: flex;
            align-items: center;
            color: var(--text_color);
        }

        .right-container-btn {
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .setup-new-noti-btn {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        .setup-new-notification {
            cursor: pointer;
            font-style: normal;
            font-weight: 500;
            font-size: toRem(14px);
            line-height: 20px;
            text-align: center;
            color: var(--primary_40);
        }
    }

    .inner-card {
        .notification-item-container {
            margin-top: 10px;
            width: 100%;
            height: auto;
            background: var(--grayscale_96);
            margin-bottom: 10px;
            border-radius: 12px;
            padding-left: 15px;
            padding-right: 15px;

            .list-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 60px;

                .left-layout {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    .shared-title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: toRem(14px);
                        color: var(--primary_32);
                        font-weight: 600;
                        font-style: normal;
                        margin-left: 10px;
                        background-color: var(--primary_96);
                        padding: 10px;
                        border-radius: 10px;

                        .text-label {
                            margin-left: 5px;
                            font-size: toRem(14px);
                            color: var(--primary_32);
                            font-weight: 600;
                            font-style: normal;
                            margin-left: 10px;
                            font-size: 12px;
                            font-style: normal;
                            line-height: 16px;
                        }
                    }

                    .paused-title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        font-size: toRem(14px);
                        color: var(--primary_32);
                        font-weight: 600;
                        font-style: normal;
                        margin-left: 10px;
                        background-color: var(--error_96);
                        padding: 10px;
                        border-radius: 10px;

                        .text-label {
                            margin-left: 5px;
                            font-size: toRem(14px);
                            color: var(--error_24);
                            font-weight: 600;
                            font-style: normal;
                            margin-left: 10px;
                            font-size: 12px;
                            font-style: normal;
                            line-height: 16px;
                        }
                    }

                    .header-title {
                        margin-left: 10px;
                        font-style: normal;
                        font-weight: 500;
                        font-size: toRem(14px);
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        color: var(--text_color);

                        &.admin {
                            margin-left: 0px;

                        }
                    }
                }



                .right-layout {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;

                    .icon-delete {
                        margin-right: 10px;
                    }

                    .icon-header {
                        cursor: pointer;
                        margin-right: 15px;
                    }
                }
            }

            .expanded-layout {
                width: 100%;
                height: auto;
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-bottom: 10px;

                .heading-item {
                    text-align: start;
                    color: var(--text_color);
                    font-size: toRem(14px);
                    font-weight: 600;
                    line-height: 20px;
                    margin-bottom: 8px;

                    &.margin-heading {
                        margin-top: 12px;
                    }
                }

                .list-item-container {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    &.duration {
                        align-items: flex-start;
                    }

                    .icon-item {
                        height: 22px;
                        width: 22px;
                    }

                    .item-title-rules {
                        margin-left: 12px;
                        color: var(--greyscale_40);
                        font-size: toRem(14px);
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                    }

                    .duration-inner {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;

                        .item-title-rules {
                            text-align: left;
                            margin-left: 12px;
                            color: var(--greyscale_40);
                            font-size: toRem(14px);
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                        }

                        .sub-title {
                            text-align: left;
                            margin-left: 12px;
                            color: var(--greyscale_40);
                            font-size: toRem(12px);
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }


    .no-data-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: var(--grayscale_96);
        padding: 32px;

        .no-result {
            margin-top: 10px;
            font-style: normal;
            font-weight: 600;
            font-size: toRem(18px);
            line-height: 24px;
            text-align: center;
            color: var(--text_color);

        }

        .message-no-result {
            cursor: pointer;
            margin-top: 5px;
            font-style: normal;
            font-weight: 500;
            font-size: toRem(14px);
            line-height: 20px;
            text-align: center;
            color: var(--primary_40);
            font-style: normal;
        }
    }
}

.delete-modal {
    .modal-header {
        padding-bottom: 4px;
    }

    .circle-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 60px;

        .circle-layout {
            height: 96px;
            width: 96px;
            border-radius: 100%;
            background-color: var(--warning_80);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .warning {
                font-style: normal;
                font-weight: 600;
                font-size: toRem(40px);
                line-height: 28px;
                display: flex;
                align-items: center;
                color: var(--warning_24);
            }

        }
    }
}