@import '../../src/assets/css/base';

.term-donction-text {
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.login-error-container {
  width: 100%;
  display: flex;
  padding: 10px;
  background: var(--error_96);
  border-radius: 12px;
  align-items: flex-start;
  text-align: left;
  justify-content: center;

  .error-text {
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14px);
    color: var(--error_24);
    line-height: 15px;
    margin-left: 5px;
  }
}

.mobile-browser {
  width: 100%;
  height: auto;
  background-color: var(--primary_96);

  .img-logo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 80px;
  }

  .bottom-container {
    width: 100%;
    height: 70vh;
    background-color: var(--brand_white);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .duclo-connect {
      margin-top: 65px;
      color: var(--brand_primary);
      text-align: center;
      font-size: toRem(16px);
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .download-message {
      margin-top: 8px;
      color: var(--greyscale_08);
      text-align: center;
      font-size: toRem(20px);
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      margin-left: 24px;
      margin-right: 24px;
      /* 140% */
    }

    .browser-app-message {
      margin-top: 16px;
      color: var(--greyscale_56);
      text-align: center;
      font-size: toRem(14px);
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-left: 24px;
      margin-right: 24px;
      /* 142.857% */
    }

    .store-btn {
      cursor: pointer;
      margin-top: 56px;
      &.plastore-btn {
        margin-top: 6px;
      }
    }
  }

  .need-help {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--brand_white);
    position: fixed;
    bottom: 20px;
    color: var(--primary_40);
    text-align: center;
    font-size: toRem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
  }
}

.term-privacy-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .block-terms-services{
    text-align: center;
    font-size: toRem(20px);
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  
  .block-policy{
    text-align: center;
    margin-top: -2px;
    font-size: toRem(16px);
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }


}