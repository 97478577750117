@import 'utilities';

@media screen and (max-width: 767px) {
  .list-block-item.card .card-body .row {
    margin-bottom: 0 !important;
  }

  .list-block-item.card .card-body .row:not(:first-child) > * {
    margin-bottom: 24px;
  }

  .list-block-item.card .card-body .avatars-list .row > * {
    margin-bottom: 0;
  }

  .invitationCodeOtp input {
    min-width: 49px;
    height: 64px;
  }

  .help-link {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(14px);
    line-height: 20px;
  }

  .remove-padding-responsive {
    padding-left: 0;
    padding-right: 0;
  }

  .main-page-content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .main-card {
    border-radius: 32px 32px 0px 0px;
  }

  footer,
  .account-verify .site-top-bar .navbar-toggler {
    display: none;
  }

  /* Site Modal CSS */
  .modal {
    overflow: hidden;
  }

  .modal.show .modal-dialog {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    align-items: end;
    min-height: 100%;
  }

  .modal-content {
    border-radius: 32px 32px 0px 0px;
    min-height: 88vh;
    height: 88vh;
    padding: 20px;
    overflow-y: auto;
  }

  .modal-form .form-control {
    margin-bottom: 16px;
  }
}