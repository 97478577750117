@import '../../../assets/css/base.scss';

.MuiPickersPopper-root {
  width: calc(542px - 62px);

  .MuiPaper-root {
    border-radius: 12px;

    box-shadow: 0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05),
      0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1);

    .MuiPickersLayout-root {
      padding-right: 30px;
      padding-top: 30px;

      .MuiPickersLayout-contentWrapper {
        display: flex;
        justify-content: space-around;
        .MuiMultiSectionDigitalClock-root {
          width: 50%;
          border: 0;

          .MuiMenuItem-root {
            color: var(--greyscale_80);
            font-size: toRem(24px);
            font-weight: 600;
            line-height: toRem(32px);

            &.Mui-selected {
              color: var(--brand_black);
              background-color: var(--brand_white);
            }
          }

          &[aria-label='Select hours'] {
            .MuiMenuItem-root {
              &.Mui-selected::after {
                content: ':';
                margin-left: 18px;
                position: absolute;
                left: 38px;
                top: 6px;
              }
            }
          }
        }
      }
    }
  }
}

.placeholder-wrap {
  display: inline-block;
  position: relative;
  background: var(--brand_white);
  width: 100%;

  .custom-placeholder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0px;
    line-height: 1rem !important;
    z-index: 9;
    overflow: hidden;
    white-space: nowrap;
    padding: 0px 16px;
    height: 100%;
    width: 100%;
    background-color: #fff;

    color: #757575;
    font-family: 'Noto Sans';
    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);

    .required {
      color: var(--error_64);
      margin-left: 4px;
      margin-top: -8px;
    }
  }
}

.incident {
  &-label {
    color: var(--brand_black);
    font-size: toRem(20px);
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

    &-action {
      color: var(--primary_40);
      font-size: toRem(14px);
      font-weight: 500;
      line-height: toRem(20px);
      cursor: pointer;
    }
  }

  &-input {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    background-color: transparent;
    height: 44px;
    width: 100%;
    position: relative;
    z-index: 10;

    &:focus + .custom-placeholder {
      display: none;
    }

    &:active,
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: var(--primary_40);
      outline: 0;
    }

    &.summary-input {
      &::-webkit-input-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.15em;
      }
      &::-moz-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.15em;
      }
      &:-ms-input-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.15em;
      }
    }
  }

  &-comment {
    border-radius: 12px;
    position: relative;

    .MuiInputBase-colorPrimary.MuiInputBase-multiline.MuiInputBase-root.MuiOutlinedInput-root {
      &:hover,
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: var(--primary_40) !important;
        }
        border-color: var(--primary_40) !important;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1.5px solid var(--greyscale_88);
        border-radius: 12px;
      }
    }

    &:hover {
      border: 0;
    }

    .MuiInputBase-multiline
      .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline::placeholder {
      color: var(--greyscale_40);
      opacity: 1;
      font-size: toRem(16px);
      font-weight: 500;
      line-height: toRem(20px);
    }

    &:hover {
      border-color: var(--greyscale_88);
    }

    .custom-placeholder {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      top: 10px;
      line-height: 1rem !important;
      z-index: 9;
      overflow: hidden;
      white-space: nowrap;
      padding: 10px;
      margin-left: 6px;
      background-color: var(--brand_white);

      color: var(--greyscale_56);
      opacity: 1;
      font-size: toRem(16px);
      font-weight: 500;
      line-height: toRem(20px);

      .required {
        color: var(--error_64);
        margin-left: 4px;
        margin-top: -8px;
      }
    }
  }
}

.char-count {
  font-size: toRem(12px);
  line-height: toRem(20px);
  text-align: right;
}

.gray-input {
  display: flex;
  height: 52px;
  padding: 5px 12px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--grayscale_96);
}

.new-incident {
  &-horizontal-line {
    height: 4px;
    width: 120px;
    flex: 1 0 0;
    border-radius: 40px;
    background: var(--primary_40);
  }

  &-wrapper {
    margin-top: 10px;
  }

  &-container.container {
    padding: 0;
  }

  &-date-wrapper {
    width: 100%;

    .icon-tabler-calendar-event {
      margin-right: 10px;
      color: var(--greyscale_40);
      height: 20px;
    }

    .customized-datepicker {
      .rmdp-day-picker,
      .rmdp-month-picker,
      .rmdp-year-picker {
        .rmdp-day {
          span:hover {
            background-color: var(--primary_64);
          }

          &.rmdp-today {
            span {
              background-color: var(--primary_80);
              color: var(--text_color);
            }
          }

          &.rmdp-disabled {
            span:hover {
              background-color: var(--primary_64);
              color: var(--brand_white);
            }
          }

          &.rmdp-selected {
            span:not(.highlight) {
              background-color: var(--primary_40);
            }
          }
        }
      }
    }

    .new-incident-date-container {
      width: 100%;

      & > div + div {
        width: 472px;
      }

      .icon-tabler-calendar-event {
        margin-right: 10px;
        color: var(--greyscale_40);
        height: 20px;
      }

      .new-incident-date.customized-datepicker {
        margin-left: 2px;
        width: 100%;

        & + div div {
          // width: 100%;

          .new-incident-date.customized-datepickerv {
            .rmdp-calendar .rmdp-header {
              .rmdp-arrow-container {
                width: 30px;
              }
            }

            .rmdp-calendar > div {
              .rmdp-day-picker,
              .rmdp-month-picker,
              .rmdp-year-picker {
                width: 100%;
                > div {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .customized-datepicker {
        .rmdp-day-picker,
        .rmdp-month-picker,
        .rmdp-year-picker {
          .rmdp-day {
            span:hover {
              background-color: var(--primary_64);
            }

            &.rmdp-today {
              span {
                background-color: var(--primary_80);
                color: var(--text_color);
              }
            }

            &.rmdp-disabled {
              span:hover {
                background-color: var(--primary_64);
                color: var(--brand_white);
              }
            }

            &.rmdp-selected {
              span:not(.highlight) {
                background-color: var(--primary_40);
              }
            }
          }
        }

        .rmdp-calendar .rmdp-header {
          .rmdp-arrow-container {
            width: 30px;
          }
        }
      }
    }
  }

  &-cal-input {
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    margin-right: 20px;
    height: 52px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    padding-left: 18px;

    &:active,
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: var(--primary_40);
      outline: 0;
    }
  }

  &-pill {
    display: flex;
    padding: 8px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    color: var(--brand_white);
    background-color: var(--primary_40);

    font-size: toRem(14px);
    font-weight: 500;
    line-height: toRem(20px);
    margin-right: 8px;
    margin-bottom: 8px;

    &-wrapper {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &-time-container {
    .MuiTextField-root {
      width: 100%;

      .MuiOutlinedInput-root {
        border-radius: 12px;
        border: 1.5px solid var(--greyscale_88);

        &:hover {
          border-color: var(--primary_40);
          border-width: 1.5px;
        }

        &.MuiInputBase-colorPrimary.MuiInputBase-formControl {
          &:focus {
            border-color: var(--primary_40);
          }

          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: var(--primary_40);
              border-width: 1.5px;
            }
          }
        }

        .MuiOutlinedInput-input {
          color: var(--greyscale_08);
          text-align: left;
          font-size: toRem(16px);
          font-weight: 500;
          line-height: toRem(20px);
          padding: 0 16px;
          height: 52px;

          &:hover {
            border-color: var(--primary_40) !important;
            border-width: 1.5px;
          }
        }

        .MuiSvgIcon-root {
          height: 20px;
          color: var(--greyscale_40);
        }

        .MuiOutlinedInput-notchedOutline {
          border: 0;
        }
      }
    }
  }

  &-locations {
    .MuiAccordion-root.base-accordion {
      .MuiButtonBase-root.MuiAccordionSummary-root {
        .MuiCollapse-root
          .MuiCollapse-vertical
          .MuiAccordion-region
          .MuiAccordionDetails-root
          .base-details-list
          .base-details-list-item
          .MuiFormGroup-root
          .MuiRadio-root
          .MuiTouchRipple-root {
          color: var(--primary_40) !important;
        }
      }
    }
  }

  &-modal {
    .modal-footer {
      padding-left: 0;
      padding-right: 0;
      border: 0;

      .new-incident-button-wrapper {
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
