@import '../../assets/css/base.scss';

.clip-details-log-detail {
  &-container {
    margin-left: 0 !important;
    margin-right: 0 !important;
    overflow: auto;

    .infinite-scroll-component {
      overflow: hidden !important;
    }
  }

  &-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 8px !important;
  }

  &-content {
    display: flex;
    justify-content: flex-start;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 12px;
    color: var(--brand_white);
    background-color: var(--grayscale_24);
    border-radius: 8px;
  }

  &-avatar {
    margin-top: 4px;
  }

  &-creator {
    text-align: left;

    &-name {
      color: var(--brand_white);
      font-size: toRem(14px);
      font-weight: 400;
      line-height: toRem(20px);
    }

    &-date {
      color: var(--greyscale_64);
      font-size: toRem(12px);
      font-weight: 400;
      line-height: toRem(16px);
    }
  }
}
