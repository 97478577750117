@import '../../../assets/css/base.scss';

.create-clip-incident-item-wrapper {
  .incident-item-name {
    .MuiListItemText-primary {
      width: 450px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiButtonBase-root.MuiCheckbox-root {
    color: var(--greyscale_72);
    border-radius: 4px;

    &.Mui-checked {
      stroke-width: 1px;
      stroke: var(--greyscale_72);

      &.Mui-disabled {
        .MuiSvgIcon-root,
        .checked-icon {
          stroke: var(--greyscale_72);
          stroke-width: 1px;
        }
      }
    }
  }

  input[type='checkbox'] {
    border-radius: 4px;
    border: 1.5px solid var(--greyscale_72);
  }

  input[disabled] {
    fill: var(--greyscale_56);
    stroke-width: 1px;
    stroke: var(--greyscale_56);
  }

  &.Mui-selected {
    background-color: var(--hGrayscale_40) !important;
    cursor: none;
  }

  &:has(.Mui-checked) {
    background-color: var(--hGrayscale_40) !important;
    cursor: default;
    pointer-events: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:active,
  &:hover {
    background-color: var(--primary_01);
  }
}
