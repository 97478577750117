.audio-settings-block {
    &.editable-off-offline {
        pointer-events: none;
    }

    .settings-block {
        &.audio-in-title {
            .settings-block-title {
                margin-top: 0;
            }
        }

        .input-with-icons {
            .icon-before-input {
                height: 44px;
            }
        }
    }
}

.audio-page-offline-message {
    background-color: var(--error_96);
    border-radius: 12px;
    padding: 12px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  
    .left-section {
      .icon {
        color: var(--error_24);
      }
    }
  
    .right-section {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 3px;
  
      .title-heading {
        color: var(--error_24);
        font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
  
      .sub-title-heading {
        margin-top: 5px;
        color: var(--error_24);
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
.audio-text-toggle{
    justify-content: space-between;
    display: flex;
    .toggle-field-wrap {
        padding: 0px;
    }
}

.custom-alert .btn-close {
    display: none;
  }
  .custom-alert .w-md2{
    width: 5.3%;
  }
  .alert-aac{
     .m-t-2{
    margin-top: 3px;
  }
  }
 