@import '../../../assets/css/base';

.save-view-modal,
.save-as-new-view-modal {
  .modal-header {
    padding-bottom: 0;
  }

  .modal-dialog .modal-content {
    padding: 16px 32px;

    .save-view-modal-description {
      color: var(--greyscale_56);
      font-size: toRem(14px);
      padding-bottom: 16px;
    }
    .delete-view-modal-description {
      color: var(--greyscale_56);
      font-size: toRem(16px);
      line-height: 24px;
      font-weight: 400;
      padding-bottom: 16px;
    }
    .delete-view-loader {
      background: var(--error_64);
    }

    .view-name-input-wrapper {
      display: flex;
    }

    .view-name-input {
      display: flex;
      background-color: var(--brand_white);
      border: 1.5px solid var(--greyscale_88);
      border-radius: 12px;
      padding: 12px 16px;
      align-items: flex-start;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    .save-view-button-wrapper {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.view-max-device-modal {
  .modal-dialog .modal-content {
    width: 442px;
    min-width: 442px;
    .modal-body {
      padding-bottom: 0;

      .view-max-device-modal-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
      }
      .header-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        .header-image {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
      .view-max-device-modal-title {
        color: var(--greyscale_08);
        font-size: toRem(20px);
        line-height: 28px;
        font-weight: 700;
        text-align: center;
      }
      .view-max-device-modal-description {
        color: var(--greyscale_56);
        font-size: toRem(16px);
        line-height: 24px;
        font-weight: 400;
        text-align: center;
      }
      .site-modal-close {
        cursor: pointer;
        color: var(--greyscale_40);
        padding: 1.5px;
        font-weight: 600;
        stroke-width: 1;
      }
    }
  }
}

.update-view-modal {
  .modal-dialog .modal-content {
    width: 542px;

    .modal-header {
      padding-top: 0;
    }

    .modal-body {
      padding-bottom: 0;

      .update-view-button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;

        .update-btn {
          font-family: 'Noto Sans';
          font-size: toRem(14px);
          font-weight: 600;
          height: 44px;
          margin-right: 8px;
        }
      }
    }
  }
}

.create-clip-modal {
  .modal-dialog .modal-content {
    padding-top: 25px;

    .modal-header {
      padding-top: 0;
    }

    .modal-body {
      .create-clip {
        &-toast {
          position: sticky;
          margin-top: 10px;

          .toast-body {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }

        &-snapshot-wrapper {
          aspect-ratio: 16/9;
          width: 478px;
          border-radius: 12px;

          .image-wrapper {
            img {
              width: 100%;
              aspect-ratio: 16/9;
              border-radius: 12px;
            }
          }

          .create-clip-video-processing {
            border-radius: 12px;
            height: 100%;
            width: 100%;

            .spinner {
              position: absolute;
              z-index: 20;
              top: 18%;
              left: calc(50% + 12px);
            }

            &-snapshot {
              border-radius: 12px;
              position: absolute;
              margin-top: 6px;
              width: 100%;

              img {
                width: 100%;
                aspect-ratio: 16/9;
              }
            }

            &-status {
              position: absolute;
              z-index: 10;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              min-height: 270px;
              width: 100%;
              aspect-ratio: 16 / 9;
              margin-top: 6px;
              margin-left: auto;
              margin-right: auto;
              padding-top: 10px;
              border-radius: 12px;
              background: rgba(0, 16, 41, 0.6);

              &-title {
                color: var(--brand_white);
                text-align: center;
                font-size: toRem(14px);
                font-weight: 600;
                line-height: toRem(20px);
                margin-bottom: 8px;
                text-align: center;
              }

              &-message {
                color: var(--primary_88);
                font-size: toRem(14px);
                font-weight: 400;
                line-height: normal;
                text-align: center;
                width: 292px;
              }
            }
          }
        }

        &-notification-wrapper {
          .notification {
            display: flex;
            flex-direction: column;
            padding: 12px;
            align-items: flex-start;
            gap: 6px;
            align-self: stretch;
            border-radius: 12px;

            &.success {
              background: var(--success_96);

              .success-title-wrapper {
                .success-title {
                  color: var(--success_24);
                  font-size: toRem(16px);
                  font-weight: 600;
                  line-height: toRem(20px);
                  margin-left: 8px;
                }
              }
            }

            &.error {
              border-radius: 12px;
              background: var(--error_96);

              .error-title-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .error-title {
                  color: var(--error_24);
                  font-size: toRem(16px);
                  font-weight: 600;
                  line-height: toRem(18px);
                  margin-left: 8px;
                }
              }

              .error-description {
                color: var(--error_24);
                font-size: toRem(14px);
                font-weight: 400;
                line-height: toRem(16px);
                padding-left: 28px;
              }
            }
          }
        }

        &-incidents-wrapper {
          .incident-select {
            max-height: 200px;
            border-radius: 12px;
            border: 1px solid var(--primary_40);

            &:hover {
              outline: 0;
              border-color: var(--primary_40) !important;
              background-color: var(--primary_96);
            }

            &-add-to-incident-icon {
              height: 20px;
              width: 20px;
              min-width: 20px;
            }

            &-dropdown-default {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4px;
            }

            &-dropdown-icon {
              color: var (--primary_40);
              height: 24px;
              width: 24px;
              margin-right: 15px;
              pointer-events: none;
            }

            &-add-to-incident-value {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              color: var(--greyscale_40);
              font-size: toRem(14px);
              font-weight: 500;
              line-height: toRem(20px);
              margin-left: 5px;
            }

            .incident-item-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &-sticky {
                position: sticky;
                bottom: 0;
              }
            }

            fieldset.MuiOutlinedInput-notchedOutline {
              border: 0;
            }
          }
        }

        &-shortcuts-wrapper {
          color: var(--brand_black);
          font-size: toRem(16px);
          font-weight: 600;
          line-height: toRem(20px);
        }

        &-shortcut-icon {
          display: flex;
          padding: 20px 16px;
          // flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          border-radius: 12px;
          border: 1.5px solid var(--greyscale_88);
          background: var(--brand_white);
          width: 100%;
          color: var(--greyscale_40);
          font-size: toRem(14px);
          font-weight: 500;
          line-height: toRem(20px);
          text-transform: none;

          &:hover {
            border: 1.5px solid var(--greyscale_88);
            background: #f3f5f7;
          }

          &:disabled {
            border: 1.5px solid var(--greyscale_88);
            opacity: 0.3;
            background: var(--brand_white);
          }

          span {
            margin-right: 0;
          }
        }
      }

      .clip {
        &-duration-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;
        }

        &-time {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          flex: 1 0 0;
        }

        &-duration-time {
          color: var(--brand_black);
          font-size: toRem(16px);
          font-weight: 600;
          line-height: toRem(20px);

          &-box {
            display: flex;
            padding: 16px 24px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            align-self: stretch;
            border-radius: 10px;
            background-color: var(--grayscale_96);

            .clipper-date {
              color: var(--greyscale_08);
              font-size: toRem(16px);
              font-weight: 400;
              line-height: toRem(20px);
            }

            .clipper-time-with-timezone {
              color: var(--greyscale_08);
              font-size: toRem(18px);
              font-weight: 600;
              line-height: toRem(24px);
            }
          }
        }

        &-start-time {
        }

        &-end-time {
        }

        &-comment {
          border-radius: 12px;
          position: relative;

          .MuiInputBase-colorPrimary.MuiInputBase-multiline.MuiInputBase-root.MuiOutlinedInput-root {
            &:hover,
            &.Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border-color: var(--primary_40) !important;
              }
              border-color: var(--primary_40) !important;
            }

            .MuiOutlinedInput-notchedOutline {
              border: 1.5px solid var(--greyscale_88);
              border-radius: 12px;
            }
          }

          &:hover {
            border: 0;
          }

          .MuiInputBase-multiline
            .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline::placeholder {
            color: var(--greyscale_40);
            opacity: 1;
            font-size: toRem(16px);
            font-weight: 500;
            line-height: toRem(20px);
          }

          &:hover {
            border-color: var(--greyscale_88);
          }

          .custom-placeholder {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            position: absolute;
            top: 10px;
            line-height: 1rem !important;
            z-index: 9;
            overflow: hidden;
            white-space: nowrap;
            padding: 10px;
            margin-left: 6px;
            background-color: var(--brand_white);

            color: var(--greyscale_56);
            opacity: 1;
            font-size: toRem(16px);
            font-weight: 500;
            line-height: toRem(20px);

            .required {
              color: var(--error_64);
              margin-left: 4px;
              margin-top: -8px;
            }
          }
        }

        &-tags {
          &-title {
            color: var(--greyscale_08);
            font-size: toRem(20px);
            font-weight: 600;
            line-height: toRem(28px);
          }

          &-content {
          }
        }
      }

      .char-count {
        font-size: toRem(12px);
        line-height: toRem(20px);
        text-align: right;
      }
    }
  }
}

.MuiPaper-root.MuiMenu-paper {
  &:has(.MuiMenu-list) {
    &:has(.incident-item-wrapper) {
      border-radius: 12px;
      background: var(--brand_white);
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      &::-webkit-scrollbar {
        // Uncomment line below to disable vertical scrollbar
        // display: none;
      }
    }
  }
  .MuiMenu-list {
    padding-bottom: 0;
    min-height: 44px;
    max-height: 240px;
    width: 240px;

    .create-clip-incident-wrapper,
    .incident-item-wrapper {
      color: var(--brand_white);
      background-color: var(--primary_04);
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 36px;
      padding-right: 5px;
      padding-left: 0;

      .incident-item-name {
        .MuiListItemText-primary {
          width: 450px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .MuiButtonBase-root.MuiCheckbox-root {
        color: var(--greyscale_72);
        border-radius: 4px;

        &.Mui-checked {
          stroke-width: 1px;
          stroke: var(--greyscale_72);

          &.Mui-disabled {
            .MuiSvgIcon-root,
            .checked-icon {
              stroke: var(--greyscale_72);
              stroke-width: 1px;
            }
          }
        }
      }

      input[type='checkbox'] {
        border-radius: 4px;
        border: 1.5px solid var(--greyscale_72);
      }

      input[disabled] {
        fill: var(--greyscale_56);
        stroke-width: 1px;
        stroke: var(--greyscale_56);
      }

      &.Mui-selected {
        background-color: var(--hGrayscale_40) !important;
        cursor: none;
      }

      &:has(.Mui-checked) {
        background-color: var(--hGrayscale_40) !important;
        cursor: default;
        pointer-events: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &:active,
      &:hover {
        background-color: var(--primary_01);
      }

      &-sticky {
        cursor: pointer;
        display: flex;
        padding: 4px 16px 4px 12px;
        gap: 8px;
        align-self: stretch;
        align-items: center;
        background: var(--grayscale_96);
        position: sticky;
        bottom: 0;
        height: 36px;
        width: 100%;

        &-label {
          color: var(--greyscale-08);
          font-size: toRem(14px);
          font-weight: 500;
          line-height: toRem(20px);
        }
      }

      .incident-item-name {
        position: relative;
        max-height: 30px;
        white-space: initial;
        width: 210px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 0.25rem;
      }
    }
  }
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink[data-shrink='true'] {
  display: none !important;
}

.device-setup-modal {
  .modal-dialog .modal-content {
    width: 542px;
    min-width: 542px;
    .modal-body {
      padding-bottom: 0;

      .device-setup-modal-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      .css-90a3vc-MuiButtonBase-root-MuiRadio-root {
        padding: 0px;
      }
      .css-90a3vc-MuiButtonBase-root-MuiRadio-root.Mui-checked {
        padding: 0px;
      }
      .css-mkl144 {
        padding: 0px;
      }
      .css-mkl144.Mui-checked {
        padding: 0px;
        color: var(--primary_40) !important;
      }
      .MuiFormGroup-root .MuiRadio-root {
        padding: 0px;
        &.Mui-checked {
          color: var(--primary_40);
        }
      }
      .setup-loader {
        color: var(--brand_white);
      }
      & .back-arrow-button {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--primary_40);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        margin-bottom: 4px;
        width: fit-content;
        cursor: pointer;
      }
      & .back-arrow-button-disabled {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--primary_88);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        margin-bottom: 4px;
        width: fit-content;
        cursor: auto;
      }
      .step-icon {
        width: 40px;
        height: 4px;
        border-radius: 40px;
        background: var(--primary_40);
        margin-right: 8px;
      }
      .step-icon-disabled {
        width: 40px;
        height: 4px;
        border-radius: 40px;
        background: var(--primary_88);
        margin-right: 8px;
      }
      .search-container {
        display: flex;
        text-align: center;
        align-items: center;
        width: 100%;
        & .search-icon {
          position: absolute;
          z-index: 9;
          margin: 12px 8px 12px 16px;
        }
        & .search-input {
          display: flex;
          padding: 16px 20px 16px 40px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          border-radius: 10px;
          border: 1.5px solid var(--greyscale_88);
          background: var(--brand_white);
          color: var(--greyscale-40);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          width: 100%;
          height: 52px;
          &:hover {
            background-color: var(--brand_white) !important;
            color: var(--greyscale_40) !important;
            border-color: var(--primary_48);
          }
          &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--brand_primary); // ToDo: Need to Fix
            outline: 0;
          }
        }
      }
      .selected-opt {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--primary_96);
        color: var(--primary_32);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 133.333% */
        margin-left: 8px;
      }
      .discription {
        color: var(--greyscale_56);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-top: 16px;
      }
      .header-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        .header-image {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
      .stepIcon > img {
        margin-left: 0;
        margin-top: 0;
      }
      .device-setup-modal-title {
        color: var(--greyscale_08);
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        text-align: center;
      }
      .device-setup-modal-subTitle {
        color: var(--brand_black);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
      .location-conatiner {
        display: flex;
        max-height: 273px;
        padding: 12px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 12px;
        background: var(--greyscale_96);
        color: var(--greyscale_08);
        overflow: auto;
        .location-item {
          display: flex;
          padding: 6px 0px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          color: var(--greyscale_08);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
      .css-90a3vc-MuiButtonBase-root-MuiRadio-root.Mui-checked {
        padding: 0px;
      }
      .site-modal-close {
        cursor: pointer;
        color: var(--greyscale_40);
        padding: 1.5px;
        font-weight: 600;
        stroke-width: 1;
      }
    }
  }
}
