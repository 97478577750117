@import '../../assets/css/utilities';

.hidden {
  display: none;
}

.product-listing {
  .bold {
    font-weight: bold;
  }

  .page-title {
    padding-left: 0;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .shopping-cart-icon {
    margin-left: 0px;
    margin-right: 8px;
    margin-top: -3px;
    font-size: 1.2rem;
  }

  .page-description {
    color: #8f8f8f;
    line-height: 20px;
    font-size: 0.875rem;
  }

  .term-button {
    font-size: toRem(14px);
    width: 110px;
    color: var(--greyscale_56);
    background-color: var(--grayscale_96);
    border: 0;

    &.btn-primary {
      &:active {
        background-color: var(--primary_40);
      }
    }

    &.active {
      color: var(--brand_white);
      background-color: var(--primary_40);
    }
  }

  .input-group {
    .decrement-btn,
    .increment-btn {
      height: 44px;
    }
  }

  .card-column {
    padding-bottom: 20px;
    text-align: center;
    padding: 4px;
    margin-bottom: 10px;
  }

  .product-listing .form-inline {
    width: 50%;

    span.input-group-addon {
      width: 50px;
    }

    .input-group {
      width: 50%;
      height: 44px;
      flex-wrap: nowrap;
      text-align: center;
    }

    .form-control {
      border: 1.5px solid var(--primary_40);
      width: 50%;
      height: 45px;
      font-size: toRem(14px);
      text-align: center;
    }
  }

  .product-list {
    .quantity-form-control[readonly] {
      background-color: var(--brand_white);

      &.form-control[readonly] {
        flex: inherit;
        width: 60px;
        height: 44px;
        text-align: center;
      }
    }

    &-toast {
      margin-top: 82px !important;
      margin-right: 25px !important;
      width: 540px !important;

      &-container {
        text-align: left;
      }

      .toast-header {
        .btn-close {
          margin-right: 0.25rem;
          height: 0.405rem;
          width: 0.405rem;
        }
      }
    }
  }

  .purchase-button {
    padding: 10px 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    &:focus,
    &:active {
      background-color: var(--primary_40);
      border-color: var(--primary_40);
    }

    &:hover {
      background-color: var(--primary_24);
      border-color: var(--primary_24);
    }

    &.disabled {
      color: var(--grayscale_32);
      background-color: var(--greyscale_88);
      outline: 0;
      border: 0;
    }

    .shopping-cart-icon {
      &:active {
        background-color: var(--primary_24);
        border-color: var(--primary_24);
      }
    }
  }

  .quantity-modifier {
    width: 137px;

    &.input-group {
      width: 159px;
    }
  }

  .features-card {
    background-color: var(--grayscale_96);
    border: 0;
    border-radius: 12px;
    width: 22.125rem;
    min-height: 21rem;
    text-align: left;

    &-body {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      position: relative;
      padding: 30px 30px 0;

      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: '100%';

        &-left {
          text-align: left;
          display: flex-start;

          .feature-list {
            text-align: left;
            list-style: none;
            margin-left: 0;
            padding-left: 0;

            li {
              margin-bottom: 4px;

              .feature-check-circle {
                color: var(--success_48);
                background-color: var(--transparent_color);
                font-size: toRem(18px);
                margin-right: 6px;
              }
            }

            &-item {
              color: var(--greyscale_56);
              font-size: 0.875rem;
            }
          }
        }
      }
    }

    &-title {
      color: var(--greyscale_56);
      font-size: toRem(16px);
    }

    &-footer {
      background-color: var(--grayscale_96);
      border: 0;
      padding-bottom: 34px;
      padding-left: 32px;

      &.card-footer {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      .btn-primary {
        height: 44px;
        line-height: 22px;
      }

      .add-service-btn {
        padding: 10px 16px;

        &-label {
          font-size: 0.875rem;
        }
      }
    }
  }

  .selected-plan-name {
    font-size: toRem(24px);
    font-weight: 700;
    text-align: left;
    padding-top: 28px;
  }

  &-section {
    padding-top: 28px;
  }
}
