.success-container {
  text-align: center !important;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.success-title {
  color: var(--greyscale-greyscale-08, #111418);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  width: 335px;
}

.success-subtitle {
  color: var(--greyscale-greyscale-40, #566376);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.setting-label {
  color: var(--greyscale-greyscale-56, #7d8ba1);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.head-container {
  display: flex;
  justify-content: space-between;

  .header-title {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
}

.ldapEnabledClass {
  input {
    &:checked + .toggle-slider {
      background: var(--greyscale_56) !important;
    }
  }
}

.manage-poc-text {
  color: var(--brand_black);
  margin-top: 16px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-left: 3px;
}

.pos-main-layout {
  margin-top: 8px;
  height: 64px;
  display: flex;
  padding: 16px 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--grayscale_96);

  .pos-label-text {
    color: var(--brand_black);
    text-align: center;
    font-family: 'Noto Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
  }
  .btn-manage-setup {
  }
}
.m-t-10 {
  margin-top: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}

.contactId-style {
  height: 2.875rem;
}
