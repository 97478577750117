.apexcharts-canvas {
    background: linear-gradient(180deg, #ebf3ff 0%, #ebf3ff00 100%);
    .apexcharts-grid {
      rect {
        fill: none;
      }
    }
  }
  
  .no-content {
    height: 200px;
    align-items: center;
  }
  
  .filter-group-login-user {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 350px;

    .filter-container{
        margin-right: 45px;
    }
  }
  